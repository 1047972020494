import Email from "../ui/email/Email";
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { ACCESS_CHECKOUT_URL, ACCESS_PAGE_URL } from "../../common/config";
import { RequestStatus, useAsync, useCustomRoute } from "../../common/customHooks";
import { useLocale } from "../../common/global";
import ConfirmButton from "../ui/confirmButton/ConfirmButton";
import { InformativeText, Title } from "../ui/typography";
import { issueEmailChallenge } from "../../api/idpUserServiceApi";
import styles from "../forgotPassword/ForgotPassword.module.scss";
import { GTMAccessImpressionEventsPusher } from "../../common/analytics";
import { RESPONSE_OK } from "../../common/constants";
import { getQueryParams } from "../../common/utils";
import { useLocation } from "react-router-dom";

function EmailVerification({ isCheckout = false, email, onEditEmailClick }) {
  const locale = useLocale();
  const { goToRoute } = useCustomRoute();
  const [errorMessage, setErrorMessage] = useState("");
  const queryParams = getQueryParams(useLocation());
  const returnURI = queryParams.returnURI;
  const {
    execute: executeEmailChallenge,
    error: emailChallengeError,
    value: emailChallengeResponse,
    requestStatus: emailChallengeRequestStatus
  } = useAsync(issueEmailChallenge, false);

  const returnToAccessPage = () => {
    onEditEmailClick();
    goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}`, {}, true);
  };

  const showErrorMessage = () => {
    setErrorMessage(locale.messages.GENERIC_ERROR);
  };

  useEffect(() => {
    if (email === "") {
      returnToAccessPage();
    }
    window.scrollTo(0, 0);
    GTMAccessImpressionEventsPusher({
      action: "Impression Send Email Challenge",
      label: returnURI
    });
  }, []);

  useEffect(() => {
    if (emailChallengeError) {
      showErrorMessage();
      GTMAccessImpressionEventsPusher({
        action: "Impression Send Email Challenge Error",
        label: "Email Challenge generic error"
      });
    }
  }, [emailChallengeError]);

  useEffect(() => {
    if (emailChallengeResponse) {
      if (emailChallengeResponse.status === RESPONSE_OK) {
        // This customer is assumed to be registered from WireEdit, hence channel is passed as wr as query parameter
        goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}/verifyOTP`, {
          channel: "wr"
        }, true);
      }
    }
  }, [emailChallengeResponse]);

  const onSendOTP = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    executeEmailChallenge(email);
  };

  return (<>
    <Title className={"is-title-xl"}>{locale.titles.ACCOUNT_EXISTS}</Title>
    <InformativeText className={"is-text-medium"}>{locale.messages.EMAIL_VERIFICATION_INFORMATION}</InformativeText>
    <form data-testid="email-otp-form">
      <Email readOnly onEditEmailClick={returnToAccessPage} value={email}/>
      <span className={styles.formSubmitError} data-testid="error-message">
        {errorMessage}
      </span>
      <ConfirmButton
        disabled={emailChallengeRequestStatus === RequestStatus.TRIGGERED || (emailChallengeRequestStatus === RequestStatus.SUCCESS && errorMessage === "")}
        buttonText={"Send OTP"} onClick={onSendOTP}/>
    </form>
  </>);
}

EmailVerification.propTypes = {
  isCheckout: PropTypes.bool,
  email: PropTypes.string.isRequired,
  onEditEmailClick: PropTypes.func.isRequired
};

export default EmailVerification;
import * as PropTypes from "prop-types";
import { datadogLogs } from "@datadog/browser-logs";
import styles from "./SignUpForm.module.scss";
import {
  KakaoFollowFlag,
  RESPONSE_CONFLICT,
  SocialProvider
} from "../../../common/constants";
import Email from "../../ui/email/Email";
import ConfirmButton from "../../ui/confirmButton/ConfirmButton";
import React, { useEffect, useRef, useState } from "react";
import Password from "../../ui/password/Password";
import PersonalData from "../personalData/PersonalData";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import PrivacyPolicyForKorea from "../privacyPolicyForKorea/PrivacyPolicyForKorea";
import { COUNTRIES_WITH_BOTTOM_SKIP_LOGIN, COUNTRIES_WITH_UPPER_SKIP_LOGIN } from "../../../common/config";
import {
  decode,
  deviceProbe,
  doGuestCheckout,
  encodeState,
  getGenderFromTitle,
  getQueryParams,
  hideLoader,
  isEmpty,
  isNotEmpty,
  isNotEmptyTrimmedValue,
  isSuccessResponseCode,
  loginProbe,
  showLoader
} from "../../../common/utils";
import { isMobile } from "mobile-device-detect";
import { closeSession } from "@gucciogucci/gucci-auth";
import { signInSocialUser, signInUser, signInWithoutPrompt } from "../../../api/oktaAuth";
import MarketingConsent from "../marketingConsent/MarketingConsent";
import ProfilingConsent from "../profilingConsent/ProfilingConsent";
import DataOutsideCountryConsent from "../dataOutsideCountryConsent/DataOutsideCountryConsent";
import {
  GTMAccessClickEventsPusher,
  GTMAccessImpressionEventsPusher,
  GTMCheckoutEventsPusher,
  GTMEmailSubscribeEventsPusher,
  GTMMyAccountCheckoutSkipLoginEventsPusher,
  GTMMyAccountCreateAccountErrorsEventsPusher,
  GTMMyAccountCreateAccountEventsPusher,
  GTMMyAccountSignInEventsPusher,
  GTMuaVPVPusher,
  getVPVSignInURL
} from "../../../common/analytics";
import { RequestStatus, useAsync } from "../../../common/customHooks";
import {
  registerUser,
  deleteUser
} from "../../../api/idpUserServiceApi";
import TokenManager from "../../../api/tokenManager";
import { useLocale } from "../../../common/global";
import { useLocation } from "react-router-dom";
import CountrySelection from "../countrySelection/CountrySelection";
import { getLanguageBasedOnCountry, i18n } from "../../../common/i18n";
import { useStandalone } from "../../standalone/StandaloneContext";
import { Consent, Information } from "customer-sdk";
import { ClientType } from "../../../common/constants";
import { Input } from "@gucci-private/gucci-react-components";
import { createCustomerFromUser, getSignupParameters as getSignupParametersHelper } from "../../../common/signupHelper";

function SignUpForm({
  email,
  onEditEmailClick,
  confirmButtonText,
  showEmail = true,
  isCheckout = false,
  isSocial = false,
  socialProvider,
  onCountryChange,
  existingDosCustomer = false
}) {
  const globalLocale = useLocale();
  const [currentLocale, setCurrentLocale] = useState({
    country: globalLocale.country,
    language: globalLocale.language
  });
  const baseUrl = `${window.location.origin}/${currentLocale.country}/${currentLocale.language}`;
  const locale = {
    ...i18n(currentLocale.country, currentLocale.language, baseUrl),
    country: currentLocale.country,
    language: currentLocale.language,
    baseUrl
  };
  const isSkipLoginEnabled = COUNTRIES_WITH_BOTTOM_SKIP_LOGIN.includes(locale.country);
  const isUpperSkipLoginEnabled = COUNTRIES_WITH_UPPER_SKIP_LOGIN.includes(locale.country);
  const consent = new Consent(locale.country);
  const isKorea = locale.country === "kr";
  const isMarketingConsentImplicit = consent.isMarketingConsentImplicit();
  const isMarketingConsentExplicitlyPreset = consent.isMarketingConsentExplicitlyPreset();
  const isProfilingConsentExplicit = consent.isProfilingConsentExplicitlyPreset();
  const isDataOutsideCountryExplicit = consent.isDataOutsideCountryConsentExplicit();
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(isSocial);
  const [formValidationFailed, setFormValidationFailed] = useState(false);
  const [title, setTitle] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(false);
  const [gender, setGender] = useState("");
  const [isGenderValid, setIsGenderValid] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [lastName, setLastName] = useState("");
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [birthDate, setBirthDate] = useState("");
  const [isBirthDateValid, setIsBirthDateValid] = useState(true);
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [socialId, setSocialId] = useState("");
  const [isSocialValid, setIsSocialIdValid] = useState(true);
  const [followFlag, setFollowFlag] = useState(KakaoFollowFlag.NONE);
  const [isFollowFlagValid, setIsFollowFlagValid] = useState(true);
  const [orderGUID, setOrderGUID] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPrivacyPolicyCollapsed, setPrivacyPolicyCollapsed] = useState(true);
  const marketingConsentDefaultValue = isMarketingConsentImplicit || isMarketingConsentExplicitlyPreset;
  const [isMarketingConsentFlag, setMarketingConsentFlag] = useState(marketingConsentDefaultValue);
  const [privacy1, setPrivacy1] = useState(false);
  const [privacy2, setPrivacy2] = useState(false);
  const [privacy3, setPrivacy3] = useState(false);
  const [privacy4, setPrivacy4] = useState(false);
  const [privacy5, setPrivacy5] = useState(false);
  const [privacy6, setPrivacy6] = useState(false);
  const [profiling, setProfiling] = useState(false);
  const [dataOutsideCountry, setDataOutsideCountry] = useState(false);
  const [isPrivacy3Valid, setIsPrivacy3Valid] = useState(true);
  const [isPrivacy4Valid, setIsPrivacy4Valid] = useState(true);
  const [isProfilingValid, setIsProfilingValid] = useState(true);
  const [isDataOutsideCountryValid, setIsDataOutsideCountryValid] = useState(true);
  const [getScrollPosition, setScrollPosition] = useState(0);
  const scrollTopAnchor = useRef(null);
  const {
    execute: executeRegisterUser,
    value: registerUserValue,
    error: registerError,
    requestStatus: registerRequestStatus,
    setRequestStatus: registerSetRequestStatus
  } = useAsync(registerUser, false);
  const {
    execute: executeCreateCustomerFromUser,
    value: createCustomerFromUserValue,
    error: createCustomerFromUserError
  } = useAsync(registerCustomerProfile, false);
  const {
    execute: executeSignInUser,
    error: signInError,
    requestStatus: signInRequestStatus
  } = useAsync(signInUser, false);
  const {
    execute: executeSocialSignIn,
    error: socialSignInError,
    requestStatus: socialSignInRequestStatus
  } = useAsync(signInSocialUser, false);

  const location = useLocation();
  const queryParams = getQueryParams(location);
  const {
    nonce,
    returnURI,
    stateToken
  } = queryParams;
  const pkceConfig = decode(queryParams.pkceConfig);
  const { isStandaloneLogin, client, isInternational, countryList } = useStandalone();

  useEffect(() => {
    if (typeof (onCountryChange) === typeof (Function)) {
      onCountryChange(locale.country, locale.language);

      const isRTL = locale.language === "ar" || locale.language === "he"; // he is for hebrew
      document.body.dir = isRTL ? "rtl" : "ltr";
    }
  }, [locale.country, locale.language]);

  function triggerSignupAnalytics() {
    if (isSocial) {
      GTMMyAccountCreateAccountEventsPusher({
        label: socialProvider
      });
    } else {
      GTMMyAccountCreateAccountEventsPusher({
        label: "email"
      });
    }
    if (isMarketingConsentFlag) {
      GTMEmailSubscribeEventsPusher({
        action: "subscribe",
        label: window.location.pathname
      });
    }
  }

  useEffect(() => {
    if (registerUserValue) {
      triggerSignupAnalytics();
      handleSignUpResponse(registerUserValue);
    }
  }, [registerUserValue]);

  useEffect(() => {
    if (createCustomerFromUserValue) {
      if (isSuccessResponseCode(createCustomerFromUserValue.status)) {
        handleSignUpSuccess(registerUserValue);
      } else {
        hideLoader();
        setErrorMessage(locale.messages.GENERIC_ERROR);
        registerSetRequestStatus(RequestStatus.NEW);
        GTMMyAccountCreateAccountErrorsEventsPusher({
          label: isSocial ? socialProvider : "users/me"
        });
      }
    }
  }, [createCustomerFromUserValue]);

  useEffect(() => {
    if (registerError || signInError || socialSignInError || createCustomerFromUserError) {
      hideLoader();
      let errorLabel = "users/register network error";
      if (signInError) {
        errorLabel = "signIn error";
      }
      if (createCustomerFromUserError) {
        errorLabel = "customer reference error";
      }

      setErrorMessage(locale.messages.GENERIC_ERROR);
      registerSetRequestStatus(RequestStatus.NEW);

      datadogLogs.logger.info(errorLabel, {
        category: "my account",
        action: "create account errors",
        label: errorLabel
      });

      GTMMyAccountCreateAccountErrorsEventsPusher({
        label: errorLabel
      });
    }
  }, [registerError, signInError, socialSignInError, createCustomerFromUserError]);

  useEffect(() => {
    fillDataFromQueryParams();
    const name = isSocial ? "name-" + socialProvider : "name";
    GTMuaVPVPusher("vpv.signinStep", getVPVSignInURL(locale.country, locale.language, name, isCheckout, true));
  }, []);

  function fillDataFromQueryParams() {
    setAndValidateParam(queryParams.firstName, setFirstName, setIsFirstNameValid, isNotEmptyTrimmedValue);
    setAndValidateParam(queryParams.lastName, setLastName, setIsLastNameValid, isNotEmptyTrimmedValue);
    setAndValidateParam(queryParams.title, setTitle, setIsTitleValid, isNotEmptyTrimmedValue);
    setAndValidateParam(queryParams.gender, setGender, setIsGenderValid, isNotEmptyTrimmedValue);
    setAndValidateParam(queryParams.birthdate, setBirthDate, setIsBirthDateValid, isNotEmptyTrimmedValue);
    setAndValidateParam(queryParams.phone, setPhone, setIsPhoneValid, isNotEmptyTrimmedValue);
    setAndValidateParam(queryParams.socialId, setSocialId, setIsSocialIdValid, isNotEmptyTrimmedValue);
    setAndValidateParam(queryParams.followFlag, setFollowFlag, setIsFollowFlagValid, isNotEmptyTrimmedValue);
    queryParams.orderGUID && setOrderGUID(queryParams.orderGUID);
  }

  function setAndValidateParam(param, setter, validationSetter, validator) {
    if (param) {
      setter(param);
      validationSetter && validationSetter(validator(param));
    }
  }

  function privacyPolicy() {
    const flags = isKorea
      ? <PrivacyPolicyForKorea
        privacy1={privacy1} privacy3={privacy3} privacy4={privacy4}
        setPrivacy1={setPrivacy1} setPrivacy2={setPrivacy2} setPrivacy3={setPrivacy3}
        setPrivacy4={setPrivacy4} setPrivacy5={setPrivacy5} setPrivacy6={setPrivacy6}
        isPrivacy3Valid={isPrivacy3Valid} isPrivacy4Valid={isPrivacy4Valid}
        setIsPrivacy3Valid={setIsPrivacy3Valid} setIsPrivacy4Valid={setIsPrivacy4Valid}
        country={locale.country} language={locale.language}
      />
      : (
        !isMarketingConsentImplicit && <MarketingConsent isMarketingConsentFlag={isMarketingConsentFlag}
          setMarketingConsentFlag={setMarketingConsentFlag} country={locale.country} language={locale.language} />
      );

    return (
      <>
        {
          !existingDosCustomer && isProfilingConsentExplicit && <ProfilingConsent country={locale.country}
            setProfiling={setProfiling} profiling={profiling} setIsProfilingValid={setIsProfilingValid}
            isProfilingValid={isProfilingValid}
            language={locale.language} />
        }
        {
          !existingDosCustomer && isDataOutsideCountryExplicit && <DataOutsideCountryConsent country={locale.country}
            setDataOutsideCountry={setDataOutsideCountry} dataOutsideCountry={dataOutsideCountry} setIsDataOutsideCountryValid={setIsDataOutsideCountryValid}
            isDataOutsideCountryValid={isDataOutsideCountryValid}
            language={locale.language} />
        }
        {!existingDosCustomer && flags}
        <PrivacyPolicy toggleCollapsed={togglePrivacyPolicy} isCollapsed={isPrivacyPolicyCollapsed} isStandAloneLogin={isStandaloneLogin}
          country={locale.country} language={locale.language} client={client}
          existingDosCustomer={existingDosCustomer} />
      </>
    );
  }

  function handleCountryChange(country) {
    setCurrentLocale({
      country,
      language: client?.source === ClientType.YUGA ? locale.language : getLanguageBasedOnCountry(country)
    });
    const explicitConsent = new Consent(country);
    (explicitConsent.isMarketingConsentExplicitlyPreset()) ? setMarketingConsentFlag(true) : setMarketingConsentFlag(false);
  }

  function showSignUpFields() {
    return (
      <>
        {
          !isSocial &&
          <Password
            value={password}
            onChange={value => setPassword(value)}
            isPasswordValid={isPasswordValid}
            setIsPasswordValid={setIsPasswordValid}
            showValidationMessages={true}
            label={locale.labels.CREATE_PASSWORD}
            formValidationFailed={formValidationFailed}
            country={locale.country}
            language={locale.language}
          />
        }
        {
          isInternational && <CountrySelection providedCountryList={countryList} changeCountry={handleCountryChange} currentCountry={locale.country}
            language={locale.language} />
        }
        {
          !existingDosCustomer &&
          <PersonalData
            country={locale.country}
            language={locale.language}
            formValidationFailed={formValidationFailed}
            value={{
              birthDate,
              firstName,
              lastName,
              title,
              gender
            }}
            setValue={{
              setBirthDate,
              setFirstName,
              setTitle,
              setGender,
              setLastName
            }}
            isValid={{
              isTitleValid,
              isGenderValid,
              isBirthDateValid,
              isFirstNameValid,
              isLastNameValid
            }}
            setIsValid={{
              setIsTitleValid,
              setIsGenderValid,
              setIsBirthDateValid,
              setIsFirstNameValid,
              setIsLastNameValid
            }}
          />
        }
        {
          isSocial && (SocialProvider.KAKAO === socialProvider) && isPhoneValid &&
          <Input
            type="text"
            readOnly={true}
            id="phone-input"
            label={locale.labels.PHONE}
            value={phone}
            withBorder={true}
          />
        }
        {
          isSocial && (SocialProvider.KAKAO === socialProvider) && isFollowFlagValid &&
          <Input
            id="follow-input"
            label={locale.labels.FOLLOW}
            readOnly={true}
            type="text"
            value={locale.labels[followFlag]}
            withBorder={true}
          />
        }
        {
          isSocial && (SocialProvider.KAKAO === socialProvider) && isSocialValid &&
          <Input
            type="text"
            readOnly={true}
            id="socialid-input"
            label={locale.labels.SOCIALID}
            value={socialId}
            withBorder={true}
          />
        }
        {privacyPolicy()}
      </>
    );
  }

  function areAllMandatoryKoreanAgreementsChecked() {
    if (isKorea) {
      return privacy2 && privacy3 && privacy4 && privacy6;
    }
    return true;
  }

  function validateBirthDate() {
    const [birthYear, birthMonth, birthDay] = birthDate.split("-");
    if (locale.country === "us" && [ClientType.GUCCI_STORE, ClientType.GUCCI_APP].includes(client?.source)) {
      return isNotEmpty(birthMonth) && isNotEmpty(birthDay) && isBirthDateValid;
    } else {
      return isNotEmpty(birthYear) && isNotEmpty(birthMonth) && isNotEmpty(birthDay) && isBirthDateValid;
    }
  }

  function allFieldsAreValid() {
    if (existingDosCustomer) {
      return isPasswordValid;
    }
    return isPasswordValid
      && (new Information(locale.country).hasGenderInRegistration() ? isGenderValid : isTitleValid)
      && isFirstNameValid
      && isLastNameValid
      && validateBirthDate()
      && areAllMandatoryKoreanAgreementsChecked()
      && (!isProfilingConsentExplicit || profiling)
      && (!isDataOutsideCountryExplicit || dataOutsideCountry);
  }

  function getSignupParameters() {
    return getSignupParametersHelper({
      email: email,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      queryParams: queryParams,
      locale: locale,
      marketingConsent: isMarketingConsentFlag,
      isSocialSignup: isSocial,
      socialProvider: socialProvider,
      password: password,
      title: title,
      gender: getGenderForRegistration(),
      birthDate: birthDate,
      ...isKorea && getKoreaPrivacyOptions(),
      orderGUID: orderGUID
    });
  }

  function signUpUser() {
    const signUpParameters = getSignupParameters();
    showLoader();
    datadogLogs.logger.info("IDP Data", signUpParameters);
    executeRegisterUser(signUpParameters);
  }

  function handleSignUpResponse(response) {
    if (isSuccessResponseCode(response.status) || response.status === RESPONSE_CONFLICT) {
      return executeCreateCustomerFromUser();
    } else {
      hideLoader();
      setErrorMessage(locale.messages.GENERIC_ERROR);
      registerSetRequestStatus(RequestStatus.NEW);
      GTMMyAccountCreateAccountErrorsEventsPusher({
        label: isSocial ? socialProvider : "users/register"
      });
      if (!isSocial) {
        GTMAccessImpressionEventsPusher({
          action: "Impression Email Sign Up Error",
          label: "error:" + response.status
        });
      }
    }
  }

  function trackOnNaver() {
    if (window.wcs) {
      window.wcs_add = window.wcs_add || {};
      window.wcs_add["wa"] = "s_1f4631e20d5";
      window.wcs.inflow(window.location.host);
      window.wcs_do({
        cnv: window.wcs.cnv("2", "1")
      });
    }
  }

  async function handleSignUpSuccess(response) {
    if (isKorea) {
      trackOnNaver();
    }

    if (isSocial) {
      return performSocialSignIn();
    }

    return performEmailSignIn();
  }

  function getRegistrationState() {
    return encodeState({
      checkout: isCheckout,
      rememberMe: true,
      returnURI: returnURI,
      stateToken: stateToken,
      registration: true,
      locale: locale.country + "/" + locale.language,
      ...(isStandaloneLogin && {
        isSocial: false
      })
    });
  }

  async function getAuthTokens(){
    if (!isSocial) {
      const resp = await signInWithoutPrompt({
        email,
        password
      });

      return {
        accessToken: resp.tokens.accessToken.accessToken,
        idToken: resp.tokens.idToken.idToken
      };
    } else {
      return {
        accessToken: TokenManager.getAccessToken(),
        idToken: TokenManager.getIdToken()
      };
    }
  }

  const getKoreaPrivacyOptions = () => {
    return {
      koreaPrivacyOptions: {
        "privacy1-kr": privacy1,
        "privacy2-kr": privacy2,
        "privacy3-kr-terms": privacy3,
        "privacy4-kr": privacy4,
        "privacy5-kr": privacy5,
        "privacy6-kr": privacy6
      }
    };
  };

  const getGenderForRegistration = () => {
    return gender === "" ? getGenderFromTitle(title) : gender;
  };

  async function registerCustomerProfile(){
    const { accessToken } = await getAuthTokens();
    return await createCustomerFromUser(queryParams, {
      isSocial: isSocial,
      socialProvider: socialProvider
    }, client, () => handleCustomerReferenceFailure(email, accessToken), {
      email,
      password,
      locale,
      gender: getGenderForRegistration(),
      accessToken,
      title,
      firstName,
      lastName,
      birthDate,
      ...isKorea && getKoreaPrivacyOptions(),
      orderGUID,
      marketingConsent: isMarketingConsentFlag
    });
  }

  const handleCustomerReferenceFailure = async (email, accessToken) => {
    await deleteUser(email, accessToken);
    await closeSession();
  };

  const performSocialSignIn = () => {
    const step = "typ-validated-" + socialProvider;
    GTMuaVPVPusher("vpv.signinStep", getVPVSignInURL(locale.country, locale.language, step, isCheckout, true));
    const signInParameters = {
      state: getRegistrationState(),
      nonce: nonce,
      shouldCloseSession: false,
      socialProviderName: socialProvider
    };
    if (queryParams.pkceConfig) {
      Object.assign(signInParameters, {
        pkceConfig: decode(queryParams.pkceConfig)
      });
    }
    executeSocialSignIn(signInParameters);
  };

  const performEmailSignIn = () => {
    GTMuaVPVPusher("vpv.signinStep", getVPVSignInURL(locale.country, locale.language, "typ", isCheckout, true));
    const successCallback = () => {
      GTMMyAccountSignInEventsPusher({
        action: "sign in",
        label: "email"
      });
    };
    return executeSignInUser({
      email,
      password,
      state: getRegistrationState(),
      nonce,
      successCallback,
      pkceConfig
    });
  };

  const sendErrorEventsToGTM = () => {
    const errors = [];

    if (!isSocial) {
      if (isEmpty(password)) {
        errors.push("empty password");
      } else if (!isPasswordValid) {
        errors.push("invalid password");
      }
    }

    if (!isTitleValid) {
      errors.push("empty title");
    }
    if (!isFirstNameValid) {
      errors.push("empty first name");
    }
    if (!isLastNameValid) {
      errors.push("empty last name");
    }

    if (isMobile) {
      if (isEmpty(birthDate)) {
        errors.push("empty birth date");
      } else if (!isBirthDateValid) {
        errors.push("invalid birth date");
      }
    } else {
      const dayInput = document.getElementById("day-input");
      if (isEmpty(dayInput?.value)) {
        errors.push("empty day");
      } else if (!dayInput?.validity.valid) {
        errors.push("invalid day");
      }
      const monthInput = document.getElementById("month-input");
      if (isEmpty(monthInput?.value)) {
        errors.push("empty month");
      } else if (!monthInput?.validity.valid) {
        errors.push("invalid month");
      }
      const yearInput = document.getElementById("year-input");
      if (isEmpty(yearInput?.value)) {
        errors.push("empty year");
      } else if (!yearInput?.validity.valid) {
        errors.push("invalid year");
      }
    }

    GTMMyAccountCreateAccountErrorsEventsPusher({
      label: errors.join(", ")
    });
    GTMAccessClickEventsPusher({
      action: "Click Email Sign up Error",
      label: errors.join(", ")
    });
  };

  const highlightErrorFields = () => {
    setFormValidationFailed(true);
    setIsBirthDateValid(validateBirthDate());
    setIsPrivacy3Valid(privacy3);
    setIsPrivacy4Valid(privacy4);
    setIsProfilingValid(profiling);
    setIsDataOutsideCountryValid(dataOutsideCountry);
  };

  const scrollToTopOfTheForm = () => {
    scrollTopAnchor.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  const scrollToSavedPosition = () => {
    window.scrollTo(0, getScrollPosition);
  };

  function findScrollPosition() {
    return window.pageYOffset;
  }

  function markScrollPosition() {
    if (getScrollPosition === 0) {
      setScrollPosition(findScrollPosition());
    }
  }

  function togglePrivacyPolicy(e) {
    e.preventDefault();
    markScrollPosition();
    if (!isPrivacyPolicyCollapsed) {
      scrollToSavedPosition();
    }
    setPrivacyPolicyCollapsed(!isPrivacyPolicyCollapsed);
  }

  function insertTrackingProbes() {
    const idpProvider = isSocial ? socialProvider : "Email";
    const countryInLocale = locale.country;
    loginProbe("SignUp", idpProvider);
    deviceProbe(countryInLocale, idpProvider);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage("");
    if (allFieldsAreValid()) {
      insertTrackingProbes();
      signUpUser();
    } else {
      scrollToTopOfTheForm();
      highlightErrorFields();
      sendErrorEventsToGTM();
    }
  }

  function skipLogin() {
    GTMMyAccountCheckoutSkipLoginEventsPusher({
      label: window.location.pathname
    });
    doGuestCheckout(email, locale, GTMCheckoutEventsPusher);
  }

  function skipLoginComponent(isUpper = false) {
    const label = isUpper ? locale.labels.CONTINUE_AS_GUEST : locale.labels.SKIP_LOGIN;
    return <button type="button" data-testid="skip-login" className={`${styles.skipLoginText} ${isUpper ? styles.skipLoginUpper : ""}`} onClick={skipLogin}>{label}</button>;
  }

  return (
    <span className={styles.formContainer}>
      <form
        className={styles.form}
        id="form"
        noValidate="novalidate"
        data-testid="sign-up-form"
        onSubmit={handleSubmit}
      >
        <span className={"scroll-top-anchor"} ref={scrollTopAnchor} />

        <span className={styles.requiredFieldMessage} data-testid="required-fields-label">
          {`*${locale.labels.REQUIRED_FIELD}`}
        </span>
        {
          showEmail && <Email
            value={email}
            readOnly
            onEditEmailClick={onEditEmailClick}
            country={locale.country}
            language={locale.language} />
        }

        {datadogLogs.logger.info(`sign-up-form ${email} ${window.upperSkipLogin} ${isUpperSkipLoginEnabled} ${isCheckout} ${isSocial}`)}
        {(window.upperSkipLogin || isUpperSkipLoginEnabled) && isCheckout && !isSocial && skipLoginComponent(true)}

        {showSignUpFields()}

        {errorMessage && (
          <span role="alert" className={styles.formSubmitError} data-testid="error-message">
            {errorMessage}
          </span>
        )}
        <ConfirmButton buttonText={confirmButtonText ? confirmButtonText : locale.labels.CREATE_MY_PROFILE} disabled={
          registerRequestStatus === RequestStatus.TRIGGERED ||
          registerRequestStatus === RequestStatus.SUCCESS ||
          signInRequestStatus === RequestStatus.TRIGGERED ||
          signInRequestStatus === RequestStatus.SUCCESS ||
          socialSignInRequestStatus === RequestStatus.TRIGGERED ||
          socialSignInRequestStatus === RequestStatus.SUCCESS} onClick={() =>
          GTMAccessClickEventsPusher({
            action: "Click Email Sign up",
            label: "Continue"
          })
        } />
        {(window.skipLogin || isSkipLoginEnabled) && isCheckout && !isSocial && skipLoginComponent()}
      </form>
    </span>
  );
}

SignUpForm.propTypes = {
  email: PropTypes.string.isRequired,
  socialProvider: PropTypes.string,
  isCheckout: PropTypes.bool,
  isSocial: PropTypes.bool,
  showEmail: PropTypes.bool,
  onEditEmailClick: PropTypes.func,
  confirmButtonText: PropTypes.string,
  onCountryChange: PropTypes.func,
  existingDosCustomer: PropTypes.bool
};

export default SignUpForm;

import { idpServiceBaseUrl } from "../common/config";
import { generateToken } from "../common/utils";

function requestHeaders(email) {
  return {
    "Content-Type": "application/json",
    "tokio": generateToken(email)
  };
}

export function registerUser(data) {
  const path = "users/register";
  const requestBody = JSON.stringify({
    ...data
  });
  const requestOptions = {
    method: "POST",
    headers: requestHeaders(data.email),
    body: requestBody
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function getUser(email) {
  const path = `users/${encodeURIComponent(email)}`;
  const requestOptions = {
    method: "GET",
    headers: requestHeaders(email)
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function getUserSocialProviders(email) {
  const path = `users/${encodeURIComponent(email)}/social-providers`;
  const requestOptions = {
    method: "GET",
    headers: requestHeaders(email)
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function getUserSocial(email, social) {
  const path = `users/${encodeURIComponent(email)}/social-provider/${social}`;
  const requestOptions = {
    method: "GET",
    headers: requestHeaders(email)
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function getUserSocialFollow(email, socialId) {
  const path = `users/${encodeURIComponent(email)}/social/${socialId}/follow`;
  const requestOptions = {
    method: "GET",
    headers: requestHeaders(email)
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function updateUserSocialProviders(email, socialProvider, accessToken) {
  const path = `users/${encodeURIComponent(email)}/social-provider/${socialProvider}`;
  const requestOptions = {
    method: "POST",
    headers: {
      ...requestHeaders(email),
      "Authorization": accessToken
    }
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function createCustomer(id, email, profileToken, recoveryToken) {
  const path = `users/${encodeURIComponent(email)}/customer`;
  const requestBody = JSON.stringify({
    externalId: id,
    profileToken: profileToken,
    recoveryToken: recoveryToken
  });

  const requestOptions = {
    method: "POST",
    headers: requestHeaders(email),
    body: requestBody
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function recoverPassword(email, country, language, isCheckout = false, returnURI = "/", cart) {
  const path = "users/recovery/password";
  const requestBody = JSON.stringify({
    email,
    country,
    language,
    isCheckout,
    returnURI: encodeURIComponent(returnURI),
    cart
  });

  const requestOptions = {
    method: "POST",
    headers: requestHeaders(email),
    body: requestBody
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function markUserProfileCompleted(data) {
  const path = "users/register/complete";
  const requestBody = JSON.stringify({
    ...data
  });
  const requestOptions = {
    method: "POST",
    headers: requestHeaders(data.email),
    body: requestBody
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function deleteUser(email, accessToken) {
  const path = `users/${encodeURIComponent(email)}`;

  const requestOptions = {
    method: "DELETE",
    headers: {
      ...requestHeaders(email),
      "Authorization": accessToken
    }
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function issueEmailChallenge(email) {
  const path = `users/${encodeURIComponent(email)}/email_challenge`;

  const requestOptions = {
    method: "POST",
    headers: requestHeaders(email)
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function setPasswordWithOTPVerification(email, passcode, password) {
  const path = `users/${encodeURIComponent(email)}/set_password`;
  const requestBody = JSON.stringify({
    passcode: passcode,
    password: password
  });
  const requestOptions = {
    method: "POST",
    headers: requestHeaders(email),
    body: requestBody
  };

  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

export function restoreToProvisionedState(email) {
  const path = `users/${encodeURIComponent(email)}/status/provisioned`;
  const requestOptions = {
    method: "POST",
    headers: requestHeaders(email)
  };
  const url = `${idpServiceBaseUrl}/${path}`;
  return fetch(url, requestOptions);
}

import React, { useEffect } from "react";
import { init, getEncodedPkceConfig } from "@gucciogucci/gucci-auth";
import { ACCESS_PAGE_URL, SIGNUP_EXISTING_CUSTOMER, getSignInSpaAuthConfig, getGcomUrl } from "../../common/config";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "../../common/utils";
import { useCustomRoute } from "../../common/customHooks";
import { getUser } from "../../api/idpUserServiceApi";

function decode(base64String) {
  const base64 = require("base-64");
  const utf8 = require("utf8");
  const decodedString = base64.decode(base64String);
  const utf8DecodedString = utf8.decode(decodedString);

  return utf8DecodedString;
}

function ExternalAccess() {
  const location = useLocation();
  const { redirectToRoute } = useCustomRoute();
  const queryParams = getQueryParams(location);
  const data = JSON.parse(decode(queryParams.data || "e30="));
  const email = data.email;
  const firstName = data.firstName;
  const lastName = data.lastName;
  const channel = queryParams.channel;

  const goToSignUp = async () => {
    await init(getSignInSpaAuthConfig());
    const pkceConfig = await getEncodedPkceConfig();

    if (email && firstName && lastName) {
      redirectToRoute(SIGNUP_EXISTING_CUSTOMER,
        {
          pkceConfig,
          email: encodeURIComponent(email),
          firstName,
          lastName,
          channel
        }
      );
    } else {
      redirectToRoute(`${ACCESS_PAGE_URL}`, {
        pkceConfig,
        channel
      });
    }
  };

  const checkUserExistence = async (email) => {
    const isUserExisting = await getUser(email);

    if (isUserExisting.status === 200) {
      redirectToRoute(ACCESS_PAGE_URL, {
        email: encodeURIComponent(email)
      }, getGcomUrl());
    } else {
      goToSignUp();
    }
  };

  useEffect( () => {
    if (!email) {
      goToSignUp();
    } else {
      checkUserExistence(email);
    }
  }, []);

  return (
    <></>
  );
}

export default ExternalAccess;

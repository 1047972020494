import Email from "../ui/email/Email";
import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { ACCESS_CHECKOUT_URL, ACCESS_PAGE_URL } from "../../common/config";
import { RequestStatus, useAsync, useCustomRoute } from "../../common/customHooks";
import { useLocale } from "../../common/global";
import ConfirmButton from "../ui/confirmButton/ConfirmButton";
import { InformativeText, Title } from "../ui/typography";
import Password from "../ui/password/Password";
import { Input } from "@gucci-private/gucci-react-components";
import {
  decode,
  encodeState,
  getQueryParams,
  isConflictResponseCode,
  isSuccessResponseCode
} from "../../common/utils";
import {
  markUserProfileCompleted,
  setPasswordWithOTPVerification,
  issueEmailChallenge,
  restoreToProvisionedState
} from "../../api/idpUserServiceApi";
import styles from "../forgotPassword/ForgotPassword.module.scss";
import { Genders, RESPONSE_OK } from "../../common/constants";
import { useLocation } from "react-router-dom";
import { signInUser, signInWithoutPrompt } from "../../api/oktaAuth";
import {
  getVPVSignInURL,
  GTMAccessImpressionEventsPusher, GTMMyAccountSignInEventsPusher,
  GTMuaVPVPusher
} from "../../common/analytics";
import { useStandalone } from "../standalone/StandaloneContext";
import { registerCustomerFromUser } from "../../api/customerReferenceApi";
import { datadogLogs } from "@datadog/browser-logs";
import { Consent } from "customer-sdk";

function OTPVerification({ isCheckout = false, email, onEditEmailClick }) {
  const VERIFY_EMAIL_OTP_FAILED = "VERIFY_EMAIL_OTP_FAILED";
  const locale = useLocale();
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { goToRoute } = useCustomRoute();
  const consent = new Consent(locale.country);
  const isKorea = locale.country === "kr";
  const isMarketingConsentImplicit = consent.isMarketingConsentImplicit();
  const isMarketingConsentExplicitlyPreset = consent.isMarketingConsentExplicitlyPreset();
  const marketingConsentDefaultValue = isMarketingConsentImplicit || isMarketingConsentExplicitlyPreset;
  const queryParams = getQueryParams(useLocation());
  const {
    nonce,
    returnURI,
    stateToken
  } = queryParams;
  const pkceConfig = decode(queryParams.pkceConfig);
  const alwaysRedirect = queryParams.alwaysRedirect || false;
  const { isStandaloneLogin, client } = useStandalone();

  const {
    execute: executeSetPasswordWithOTPVerification,
    error: passwordWithOTPVerificationError,
    value: passwordWithOTPVerificationResponse,
    requestStatus: passwordWithOTPVerificationStatus
  } = useAsync(setPasswordWithOTPVerification, false);

  const {
    execute: executeSignInUser,
    error: signInError
  } = useAsync(signInUser, false);

  const getAuthTokens = async () => {
    const resp = await signInWithoutPrompt({
      email,
      password
    });

    return {
      accessToken: resp.tokens.accessToken.accessToken,
      idToken: resp.tokens.idToken.idToken
    };
  };

  const getSignupParameters = (idToken) => {
    const identityOnly = !!pkceConfig;
    if (!identityOnly) {
      datadogLogs.logger.warn(`Legacy flow for WireEdit customer, email ${email}, identity only: ${identityOnly}`);
    }
    const { firstName, lastName } = decode(idToken.split(".")[1]);

    return {
      email,
      password,
      profile: {
        title: "",
        gender: Genders.NOTSAY,
        ...firstName && {
          firstName: firstName.trim()
        },
        ...lastName && {
          lastName: lastName.trim()
        },
        marketingConsent: isKorea ? false : marketingConsentDefaultValue,
        // orderGUID: "" //TODO - add orderGUID during checkout journeys,
        ...isKorea && {
          privacyOptions: {
            "privacy1-kr": false,
            "privacy2-kr": false,
            "privacy3-kr-terms": false,
            "privacy4-kr": false,
            "privacy5-kr": false,
            "privacy6-kr": false
          }
        },
        country: locale.country
      },
      additionalData: {
        social: false,
        returnURI,
        language: locale.language,
        alwaysRedirect
      },
      identityOnly: identityOnly
    };
  };

  const handleCustomerProfileUpdateFailure = async (email) => {
    const response = await restoreToProvisionedState(email);
    if (!isSuccessResponseCode(response.status)) {
      showErrorMessage();
      GTMAccessImpressionEventsPusher({
        action: "Impression Restore to Provisioned User Error",
        label: "Restore to Provisioned User generic error"
      });
    }
  };

  const createCustomerFromUser =  async ({ pkceConfig, email }) => {
    if (pkceConfig && pkceConfig.clientId) {
      const { accessToken, idToken } = await getAuthTokens();
      const signupParameters = getSignupParameters(idToken);
      const country = signupParameters.profile.country || "";
      const customerReferenceData = {
        ...signupParameters.profile,
        email: signupParameters.email,
        password: undefined,
        country: ("uk" === country.toLowerCase() ? "GB" : country).toUpperCase(),
        source: (client && client.source) || undefined,
        ...isKorea && {
          marketingConsent: false
        },
        identityOnly: undefined,
        recruitmentChannel: queryParams.channel
      };

      datadogLogs.logger.info("Customer Reference Data for WireEdit returning customer", {
        accessToken,
        customerReferenceData,
        language: signupParameters.additionalData.language
      });

      const response = await registerCustomerFromUser(accessToken, customerReferenceData, signupParameters.additionalData.language);
      if (!isSuccessResponseCode(response.status) && !isConflictResponseCode(response.status)) {
        await handleCustomerProfileUpdateFailure(email);
        return Promise.reject(response);
      }
      const profileCompletedData = {
        email: signupParameters.email,
        profile: signupParameters.profile,
        additionalData: {
          ...signupParameters.additionalData
        }
      };
      return await markUserProfileCompleted(profileCompletedData);
    } else {
      return {
        status: 200
      };
    }
  };

  const { execute: createCustomerFromUserExecute, error: createCustomerFromUserError, value: createCustomerFromUserResponse } = useAsync(createCustomerFromUser, false);

  const {
    execute: executeEmailChallenge,
    error: emailChallengeError,
    value: emailChallengeResponse,
    requestStatus: emailChallengeRequestStatus
  } = useAsync(issueEmailChallenge, false);

  useEffect(() => {
    if (emailChallengeError || (emailChallengeResponse && emailChallengeResponse.status !== RESPONSE_OK)) {
      showErrorMessage();
      GTMAccessImpressionEventsPusher({
        action: "Impression Send Email Challenge Error",
        label: "Email Challenge generic error"
      });
    }
  }, [emailChallengeError, emailChallengeResponse]);

  const otpChangeValue = (e) => {
    e.preventDefault();
    const inputValue = e.target.value;
    setOtpValue(inputValue);
  };

  const showErrorMessage = (errorMessage) => {
    if (errorMessage == null || errorMessage.length === 0) {
      setErrorMessage(locale.messages.GENERIC_ERROR);
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const returnToAccessPage = () => {
    onEditEmailClick();
    goToRoute(`${isCheckout ? ACCESS_CHECKOUT_URL : ACCESS_PAGE_URL}`, {}, true);
  };

  const setPasswordAndUpdateProfile = (event) => {
    event.preventDefault();
    executeSetPasswordWithOTPVerification(email, otpValue, password);
  };

  const onSendOTP = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setOtpValue("");
    executeEmailChallenge(email);
  };

  const getRegistrationState = () => {
    return encodeState({
      checkout: isCheckout,
      rememberMe: true,
      returnURI: returnURI,
      stateToken: stateToken,
      registration: true,
      locale: locale.country + "/" + locale.language,
      ...(isStandaloneLogin && {
        isSocial: false
      })
    });
  };

  useEffect(() => {
    if (email === "") {
      returnToAccessPage();
    }
    window.scrollTo(0, 0);
    GTMAccessImpressionEventsPusher({
      action: "Impression OTP Verification screen",
      label: returnURI
    });
  }, []);

  useEffect(() => {
    if (passwordWithOTPVerificationError || createCustomerFromUserError || signInError ) {
      showErrorMessage();
      GTMAccessImpressionEventsPusher({
        action: "Impression OTP Verification Error",
        label: "OTP Verification generic error"
      });
    }
  }, [passwordWithOTPVerificationError, createCustomerFromUserError, signInError]);

  useEffect(() => {
    if (createCustomerFromUserResponse) {
      if (createCustomerFromUserResponse.status === RESPONSE_OK) {
        GTMuaVPVPusher("vpv.signinStep", getVPVSignInURL(locale.country, locale.language, "typ", isCheckout, true));
        const successCallback = () => {
          GTMMyAccountSignInEventsPusher({
            action: "sign in",
            label: "email"
          });
        };
        executeSignInUser({
          email,
          password,
          state: getRegistrationState(),
          nonce,
          successCallback,
          pkceConfig
        });
      } else {
        showErrorMessage();
      }
    }
  }, [createCustomerFromUserResponse]);

  useEffect( () => {
    if (passwordWithOTPVerificationResponse) {
      if (passwordWithOTPVerificationResponse.status === RESPONSE_OK) {
        createCustomerFromUserExecute({
          pkceConfig,
          email
        });
      } else {
        passwordWithOTPVerificationResponse.text().then((res => {
          if (res === VERIFY_EMAIL_OTP_FAILED) {
            showErrorMessage(locale.messages.OTP_VALIDATION_ERROR);
          } else {
            showErrorMessage();
          }
        }));
        GTMAccessImpressionEventsPusher({
          action: "Impression OTP Verification Error",
          label: "OTP Verification generic error"
        });
      }
    }
  }, [passwordWithOTPVerificationResponse]);

  function isConfirmButtonDisabled() {
    return !(isPasswordValid && otpValue > 0 && email.length > 0) ||
      (passwordWithOTPVerificationStatus === RequestStatus.TRIGGERED ||
        (passwordWithOTPVerificationStatus === RequestStatus.SUCCESS && errorMessage === "" &&
          emailChallengeRequestStatus !== RequestStatus.SUCCESS));
  }

  return (<div style={{
    textWrap: "balance"
  }}>
    <Title className={"is-title-l"}>{locale.labels.CREATE_PASSWORD}</Title>
    <InformativeText className={"is-text-medium"}>{locale.messages.OTP_VERIFICATION_INFORMATION}</InformativeText>
    <form data-testid="verify-otp-form" onSubmit={setPasswordAndUpdateProfile}>
      <Email readOnly onEditEmailClick={returnToAccessPage} value={email}/>
      <Input
        value={otpValue}
        type="number"
        id="otp"
        label={locale.labels.OTP}
        errorMessage={locale.messages.FIRST_NAME_ERROR}
        onChange={otpChangeValue}
        required
        withBorder={true}
      />
      <Password
        value={password}
        onChange={value => setPassword(value)}
        isPasswordValid={isPasswordValid}
        setIsPasswordValid={setIsPasswordValid}
        showValidationMessages={true}
        label={locale.labels.CREATE_PASSWORD}
        country={locale.country}
        language={locale.language}
      />
      <span className={styles.formSubmitError} data-testid="error-message">
        {errorMessage}
      </span>
      <ConfirmButton
        disabled={isConfirmButtonDisabled()}
        buttonText={locale.labels.CONFIRM} />
    </form>
    <InformativeText className={"is-text-medium"}>{locale.labels.DIDNT_RECEIVE_OTP}
      <button onClick={onSendOTP} className={"is-text-medium"}
        disabled={ emailChallengeRequestStatus === RequestStatus.TRIGGERED }
        data-testid="resend-otp-link"
        style={{
          background: "none",
          textDecoration: "underline",
          border: "none",
          cursor: "pointer"
        }}>{locale.labels.RESEND_OTP}</button> </InformativeText>
  </div>);
}

OTPVerification.propTypes = {
  isCheckout: PropTypes.bool,
  email: PropTypes.string.isRequired,
  onEditEmailClick: PropTypes.func.isRequired
};

export default OTPVerification;
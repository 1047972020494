import React, { useEffect } from "react";
import Loader from "../ui/loader/Loader";
import StandaloneWrapper from "../standalone/StandaloneWrapper";
import StandaloneLogo from "../standalone/standaloneHeader/StandaloneLogo";
import styles from "./SocialAuthorizationCallback.module.scss";
import { performSocialSignInOrReviewProfile } from "../../facade/socialFacade";
import { useStandalone } from "../standalone/StandaloneContext";

function SocialAuthorizationCallback() {
  const { isStandaloneLogin } = useStandalone();
  useEffect(() =>  {
    performSocialSignInOrReviewProfile();
  }, []);

  return (
    <div data-testid={"callback-component"}>
      {
        isStandaloneLogin ?
          <>
            <StandaloneLogo />
            <StandaloneWrapper>
              <Loader additionalClass={styles.loader} />
            </StandaloneWrapper>
          </>
          :
          <Loader />
      }
    </div>);
}

export default SocialAuthorizationCallback;

/* eslint-disable */
import { objectAt } from "./utils";
import { ClientType } from "./constants";
import { getGcomUrl } from "./config";

let urlCountryLanguage = "";

function getTranslations(language, baseUrl) {
  switch (language) {
  case "ar":
    return arabic(baseUrl);
  case "de":
    return german(baseUrl);
  case "en_gb":
    return englishGreatBritain(baseUrl);
  case "es":
    return spanish(baseUrl);
  case "es_mx":
    return mexicanSpanish(baseUrl);
  case "fr":
    return french(baseUrl);
  case "it":
    return italian(baseUrl);
  case "ja":
    return japanese(baseUrl);
  case "ko":
    return korean(baseUrl);
  case "zh":
    return simplifiedChinese(baseUrl);
  case "zh_hk":
    return traditionalChinese(baseUrl);
  default:
    return englishGreatBritain(baseUrl);
  }
}

const countriesLanguages =
    {
        es: {
            defaultLanguage: "es", languages: ["es", "fr", "en_gb", "de", "it", "zh"]
        },
        fr: {
            defaultLanguage: "fr", languages: ["fr", "es", "en_gb", "de", "it", "zh"]
        },
        nl: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "it", "zh"]
        },
        at: {
            defaultLanguage: "de", languages: ["de", "es", "en_gb", "fr", "it", "zh"]
        },
        de: {
            defaultLanguage: "de", languages: ["de", "es", "en_gb", "fr", "zh", "it"]
        },
        be: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        bg: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        cz: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        dk: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        hu: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        ch: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        fi: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        ie: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        se: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        no: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        pt: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        ro: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        si: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        pl: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        tr: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        uk: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        gr: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        lu: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        za: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it"]
        },
        it: {
            defaultLanguage: "it", languages: ["it", "fr", "es", "de", "zh", "en_gb"]
        },
        ca: {
            defaultLanguage: "en", languages: ["en", "fr"]
        },
        us: {defaultLanguage: "en", languages: ["en"]},
        au: {
            defaultLanguage: "en_au", languages: ["en_au", "zh"]
        },
        nz: {
            defaultLanguage: "en_au", languages: ["en_au", "zh"]
        },
        ae: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it", "ar"]
        },
        sa: {
            defaultLanguage: "ar", languages: ["ar", "fr", "es", "de", "zh", "it", "en_gb"]
        },
        kw: {
            defaultLanguage: "ar", languages: ["ar", "fr", "es", "de", "zh", "it", "en_gb"]
        },
        qa: {
            defaultLanguage: "en_gb", languages: ["en_gb", "fr", "es", "de", "zh", "it", "ar"]
        },
        jp: {
            defaultLanguage: "ja", languages: ["ja", "en_gb"]
        },
        kr: {
            defaultLanguage: "ko", languages: ["ko"]
        },
        al: {
            defaultLanguage: "en", languages: ["en", "fr", "es", "de", "ru", "it"]
        },
        hk: {
            defaultLanguage: "en_gb", languages: ["en_gb", "zh_hk", "zh"]
        },
        th: {
            defaultLanguage: "th", languages: ["th", "en_gb"]
        },
        sg: {defaultLanguage: "en_gb", languages: ["en_gb"]},
        mx: {
            defaultLanguage: "es_mx", languages: ["es_mx", "en"]
        },
    };

export function getLanguagesFromCountry(country) {
  return countriesLanguages[country];
}

export function getLanguageBasedOnCountry(country) {
  switch (country) {
  case "it":
    return "it";
  case "jp":
    return "ja";
  case "uk":
  case "gr":
  case "lu":
  case "za":
    return "en_gb";
  case "kw":
  case "sa":
  case "qa":
  case "ae":
    return "ar";
  case "at":
  case "de":
    return "de";
  case "es":
    return "es";
  case "fr":
    return "fr";
  case "kr":
    return "ko";
  case "hk":
    return "zh_hk";
  case "mx":
    return "es_mx";
  default:
    return "en";
  }
}

export function getLanguagesList() {
  return {
    "ar": "Arabic",
    "de": "Deutsch",
    "en_gb": "English UK",
    "es": "Español",
    "es_mx": "Español",
    "fr": "Français",
    "it": "Italiano",
    "ja": "日本語",
    "ko": "한국어",
    "zh": "简体中文",
    "zh_hk": "繁體中文",
    "en": "English"
  };
}

export function getCountryList(language) {
  switch (language) {
  case "it":
    return [{
      name: "Stati Uniti",
      value: "us"
    },
    {
      name: "Canada",
      value: "ca"
    },
    {
      name: "Austria",
      value: "at"
    },
    {
      name: "Belgio",
      value: "be"
    },
    {
      name: "Bulgaria",
      value: "bg"
    },
    {
      name: "Repubblica Ceca",
      value: "cz"
    },
    {
      name: "Danimarca",
      value: "dk"
    },
    {
      name: "Germania",
      value: "de"
    },
    {
      name: "Spagna",
      value: "es"
    },
    {
      name: "Finlandia",
      value: "fi"
    },
    {
      name: "Francia",
      value: "fr"
    },
    {
      name: "Ungheria",
      value: "hu"
    },
    {
      name: "Irlanda",
      value: "ie"
    },
    {
      name: "Italia",
      value: "it"
    },
    {
      name: "Paesi Bassi",
      value: "nl"
    },
    {
      name: "Norvegia",
      value: "no"
    },
    {
      name: "Polonia",
      value: "pl"
    },
    {
      name: "Portogallo",
      value: "pt"
    },
    {
      name: "Romania",
      value: "ro"
    },
    {
      name: "Slovenia",
      value: "si"
    },
    {
      name: "Svezia",
      value: "se"
    },
    {
      name: "Svizzera",
      value: "ch"
    },
    {
      name: "Turchia",
      value: "tr"
    },
    {
      name: "Regno Unito",
      value: "uk"
    },
    {
      name: "日本 / Giappone",
      value: "jp"
    },
    {
      name: " 香港特别行政区 / Hong Kong SAR",
      value: "hk"
    },
    {
      name: "Saudi Arabia/السعودية",
      value: "sa"
    },
    {
      name: "Kuwait/الكويت",
      value: "kw"
    },
    {
      name: "Qatar/قطر",
      value: "qa"
    },
    {
      name: "UAE/الإمارات",
      value: "ae"
    },
    {
      name: "Australia",
      value: "au"
    },
    {
      name: "Nuova Zelanda",
      value: "nz"
    }
    ];
  default:
    return [{
      name: "United States",
      value: "us"
    },
    {
      name: "Mexico",
      value: "mx"
    },
    {
      name: "Canada",
      value: "ca"
    },
    {
      name: "Austria",
      value: "at"
    },
    {
      name: "Belgium",
      value: "be"
    },
    {
      name: "Bulgaria",
      value: "bg"
    },
    {
      name: "Czech Republic",
      value: "cz"
    },
    {
      name: "Denmark",
      value: "dk"
    },
    {
      name: "Germany",
      value: "de"
    },
    {
      name: "Spain",
      value: "es"
    },
    {
      name: "Finland",
      value: "fi"
    },
    {
      name: "France",
      value: "fr"
    },
    {
      name: "Hungary",
      value: "hu"
    },
    {
      name: "Ireland",
      value: "ie"
    },
    {
      name: "Italy",
      value: "it"
    },
    {
      name: "Netherlands",
      value: "nl"
    },
    {
      name: "Norway",
      value: "no"
    },
    {
      name: "Poland",
      value: "pl"
    },
    {
      name: "Portugal",
      value: "pt"
    },
    {
      name: "Romania",
      value: "ro"
    },
    {
      name: "Slovenia",
      value: "si"
    },
    {
      name: "Sweden",
      value: "se"
    },
    {
      name: "Switzerland",
      value: "ch"
    },
    {
      name: "Turkey",
      value: "tr"
    },
    {
      name: "United Kingdom",
      value: "uk"
    },
    {
      name: "Greece",
      value: "gr"
    },
    {
      name: "Luxembourg",
      value: "lu"
    },
    {
      name: "South Africa",
      value: "za"
    },
    {
      name: "日本 / Japan",
      value: "jp"
    },
    {
      name: " 香港特别行政区 / Hong Kong SAR",
      value: "hk"
    },
    {
      name: "Saudi Arabia/السعودية",
      value: "sa"
    },
    {
      name: "Kuwait/الكويت",
      value: "kw"
    },
    {
      name: "Qatar/قطر",
      value: "qa"
    },
    {
      name: "UAE/الإمارات",
      value: "ae"
    },
    {
      name: "Australia",
      value: "au"
    },
    {
      name: "New Zealand",
      value: "nz"
    }
    ];
  }
}

export function i18nReplaceVariables(object, key, replacements = {}) {
  const label = objectAt(object, key);
  const anyWordInPercentages = /%([\w]+)%/g;
  return label ?
    label.replace(anyWordInPercentages, (match, varname) => replacements[varname]) :
    undefined;
}

export function i18nCountryBased(country, object, key, replacements = {}) {
  const keyCountryBased = `${key}__${country.toUpperCase()}`;
  return i18nReplaceVariables(object, keyCountryBased, replacements) || i18nReplaceVariables(object, key, replacements);
}

function getClientTranslations(language, baseUrl, clientSource) {
  switch (clientSource) {
  case undefined:
    return {};
  case ClientType.THE_VAULT:
    return require("./translations/i18n_vault").vaultTranslations(language, baseUrl);
  case ClientType.GUCCI_STORE:
    return require("./translations/i18n_gucciStore").gucciStoreTranslations(language, baseUrl);
  default:
    return {};
  }
}

function unionTranslations(defaultTranslations, clientTranslations) {
  let result = {};
  Object.keys(defaultTranslations).forEach((key) => {
    result[key] = {
      ...defaultTranslations[key],
      ...clientTranslations[key]
    };
  });

  return result;
}

export function i18n(country, language, baseUrl, clientSource) {
  const defaultTranslations = getTranslations(language, baseUrl);
  const clientTranslations = getClientTranslations(language, baseUrl, clientSource);

  const translations = unionTranslations(defaultTranslations, clientTranslations);

  urlCountryLanguage = `/${country}/${language}`;

  return {
    ...translations,
    i18n: (key, replacements) => i18nCountryBased(country, translations, key, replacements) || key
  };
}

function german(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) => `Indem Sie "ERSTELLE MEIN PROFIL" wählen, bestätigen Sie, dass Sie unseren <a href="${LINK2}" target="_blank">Nutzungsbedingungen</a> zustimmen, dass Sie unsere <a href="${LINK1}" target="_blank">Datenschutzrichtlinie</a> gelesen und verstanden haben und dass Sie Ihr GUCCI-Profil erstellen möchten.`;
  const getPrivacyPolicyTextCreateMyAccountToProceed = LINK => `Indem Sie "My GUCCI account erstellen" wählen, bestätigen Sie, dass Sie unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> gelesen und verstanden haben und dass Sie Ihr GUCCI-Profil erstellen möchten.`;
  const getPrivacyPolicyTextCountry = (LINK1, LINK2) => `Indem Sie "ERSTELLE MEIN PROFIL" wählen, bestätigen Sie, dass Sie unseren <a href="${LINK2}" target="_blank">Nutzungsbedingungen</a> und unserer <a href="${LINK1}" target="_blank">Datenschutzrichtlinie</a> zustimmen und dass Sie Ihr GUCCI-Profil erstellen möchten.`;

  const getPrivacyPolicyHTML = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br/>
                              <br/>
                              Sie sind dabei Ihr GUCCI Profil zu erstellen. Dies ermöglicht es GUCCI, Ihnen ein personalisiertes und maßgeschneidertes Erlebnis anzubieten, Ihnen Produkte, Dienstleistungen und Informationen, die Sie von GUCCI anfordern, zur Verfügung zu stellen und mit Ihnen zu kommunizieren. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren. <br/>
                              <br/>
                              Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
 const getPrivacyPolicyHTML_AT = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br/>
      <br/>
      Sie sind dabei Ihr GUCCI Profil zu erstellen. Dies ermöglicht es GUCCI, Ihnen ein personalisiertes und maßgeschneidertes Erlebnis anzubieten, Ihnen Produkte, Dienstleistungen und Informationen, die Sie von GUCCI anfordern, zur Verfügung zu stellen und mit Ihnen zu kommunizieren. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren (einschließlich per Telefon, wenn Sie uns Ihre Telefonnummer mitteilen). <br/>
      <br/>
      Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;
const getPrivacyPolicyHTML_SE = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br/>
                        <br/>
                        Sie sind dabei Ihr GUCCI Profil zu erstellen. Dies ermöglicht es GUCCI, Ihnen ein personalisiertes und maßgeschneidertes Erlebnis anzubieten, Ihnen Produkte, Dienstleistungen und Informationen, die Sie von GUCCI anfordern, zur Verfügung zu stellen und mit Ihnen zu kommunizieren. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wenn Sie ein GUCCI Profil erstellen, speichern wir Ihre persönlichen Informationen im Allgemeinen für 5 Jahre ab der letzten Interaktion, die Sie mit uns hatten.  Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren. <br/>
                        <br/>
                        Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
                        `;
  const getPrivacyPolicyHTML_QA = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br />
                                  <br />
                                  Sie sind dabei Ihr GUCCI Profil zu erstellen. GUCCI wird Ihr Profil verwenden, um Ihnen die Produkte, Dienstleistungen und Informationen zur Verfügung zu stellen, die Sie kaufen oder von GUCCI anfordern, um Ihnen maßgeschneiderte Unterstützung und Kundendienst anzubieten, um Ihnen maßgeschneiderte Werbemitteilungen zu senden, um unsere Geschäfte sicher und in Übereinstimmung mit unseren Verpflichtungen abzuwickeln und um unsere Dienstleistungen zu optimieren und zu verbessern. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren. Durch die Erstellung Ihres GUCCI Profils bestätigen Sie, dass Sie unsere <a href="${baseUrl}/st/privacy-landing" target="_blank">Datenschutzrichtlinie</a> gelesen und verstanden haben, und dass Sie damit einverstanden.<br />
                                  <br />
                                  Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTML_KW = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br />
      <br />
      Sie sind dabei Ihr GUCCI Profil zu erstellen. Dies ermöglicht es GUCCI, Ihnen ein personalisiertes und maßgeschneidertes Erlebnis anzubieten, Ihnen Produkte, Dienstleistungen und Informationen, die Sie von GUCCI anfordern, zur Verfügung zu stellen und mit Ihnen zu kommunizieren. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren. Durch die Erstellung Ihres GUCCI Profils bestätigen Sie, dass Sie unsere <a href="${baseUrl}/st/privacy-landing" target="_blank">Datenschutzrichtlinie</a> gelesen und verstanden haben, und dass Sie damit einverstanden.<br />
      <br />
      Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie haben das Recht, Ihre Einwilligung zur Sammlung und Verarbeitung Ihrer persönlichen Daten zu geben oder die Einwilligung zu verweigern. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;
  const getPrivacyPolicyHTML_Country = LINK => `Sie werden gebeten, unsere <a href="${LINK}" target="_blank">Datenschutzrichtlinie</a> vollständig zu lesen. In jedem Fall sind hier einige wichtige Punkte, die wir mit Ihnen teilen möchten:<br />
                                  <br />
                                  Sie sind dabei Ihr GUCCI Profil zu erstellen. Dies ermöglicht es GUCCI, Ihnen ein personalisiertes und maßgeschneidertes Erlebnis anzubieten, Ihnen Produkte, Dienstleistungen und Informationen, die Sie von GUCCI anfordern, zur Verfügung zu stellen und mit Ihnen zu kommunizieren. Alle Ihre persönlichen Informationen werden aufbewahrt, verwendet und mit Ihrem GUCCI Profil verknüpft. Ihr Profil kann Informationen enthalten, die wir direkt von Ihnen sowie von unseren Einzelhändlern und anderen Handelspartnern erhalten haben. Da wir weltweit tätig sind, können wir Ihre persönlichen Informationen mit Unternehmen unserer Gruppe und unseren anerkannten Partnern auf der ganzen Welt sicher austauschen. Wir werden Schutzmaßnahmen ergreifen, um Ihre persönlichen Informationen zu schützen. Wir können alle Kontaktinformationen, die Sie uns mitteilen, verwenden, um Sie zu kontaktieren. Durch die Erstellung Ihres GUCCI Profils bestätigen Sie, dass Sie unsere <a href="${baseUrl}/st/privacy-landing" target="_blank">Datenschutzrichtlinie</a> gelesen und verstanden haben, und dass Sie damit einverstanden.<br />
                                  <br />
                                  Datenschutzgesetze können Ihnen bestimmte Rechte gewähren, wie zum Beispiel das Recht auf Zugang, Löschung, Änderung und Berichtigung Ihrer Daten oder die Verarbeitung einzuschränken oder ihr zu widersprechen, sowie das Recht auf Datenübertragbarkeit. Sie können auch eine Beschwerde bei Ihrer zuständigen Aufsichtsbehörde einreichen. Sie können jederzeit Ihre Einwilligung widerrufen oder Ihr Profil löschen. Für weitere Informationen zu unseren Datenschutzpraktiken und Ihren Rechten kontaktieren Sie uns bitte unter <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `Wenn Sie unten auf „Weiter“ klicken, erklären Sie sich mit unseren Datenschutzbestimmungen einverstanden. <a href="${LINK}" target="_blank">Datenschutzbesti</a>.`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">Datenschutzbesti</a>`;
  return {
    "labels": {
      "CANCEL": "ABBRECHEN",
      "CHANGE_LANGUAGE": "CHANGE LANGUAGE",
      "CHECKOUT_CONTINUE": "WEITER ZUR KASSE",
      "CHOOSE_PASSWORD": "Wählen Sie ein Passwort",
      "CLOSE": "SCHLIESSEN",
      "COMPLETE_YOUR_PROFILE": "VERVOLLSTÄNDIGEN SIE IHR PROFIL",
      "CONFIRM": "BESTÄTIGEN",
      "CONFIRM_EMAIL_ADDRESS": "Konto verifizieren",
      "CONTINUE": "WEITER",
      "PROCEED_TO_CHECKOUT":"WEITER ZUR KASSE",
      "CONTINUE_WITH_GOOGLE": "WEITER MIT GOOGLE",
      "CONTINUE_WITH_APPLE": "WEITER MIT APPLE",
      "CONTINUE_WITH_KAKAO": "WEITER MIT KAKAO",
      "CREATE_ACCOUNT": "MY GUCCI ACCOUNT ERSTELLEN",
      "CREATE_PASSWORD": "PASSWORT ERSTELLEN",
      "COUNTRY": "WOHNSITZLAND",
      "DATE_OF_BIRTH": "GEBURTSDATUM",
      "DAY": "Tag",
      "EDIT_EMAIL": "E-Mail-Adresse ändern",
      "EMAIL": "E-MAIL",
      "EMAIL_VALIDATION_RESEND": "Keine E-Mail erhalten? Eine neue E-Mail anfordern.",
      "FINISH_REGISTRATION": "REGISTRIERUNG ABSCHLIESSEN",
      "FIRST_NAME": "VORNAME",
      "FOLLOW": "Kakao Channel – Freundschaftsstatus",
      "FOLLOW_ADDED": "Hinzugefügt",
      "FOLLOW_BLOCKED": "Blockiert",
      "FOLLOW_NONE": "Keiner",
      "FORGOT_PASSWORD": "Passwort vergessen?",
      "GUEST_USER_CHECKOUT": "Login überspringen",
      "CONTINUE_AS_GUEST": "Weiter als gast",
      "LAST_NAME": "NACHNAME",
      "MONTH": "Monat",
      "MARKETING_CONSENT_CHECKBOX": "Ich möchte Updates über GUCCI neue Aktivitäten, exklusive Produkte, maßgeschneiderte Dienstleistungen erhalten und eine personalisierte Erfahrung machen, die auf meinen Interessen basiert.",
      "MARKETING_CONSENT_CHECKBOX__SA": "Ich möchte Updates über GUCCI neue Aktivitäten, exklusive Produkte, maßgeschneiderte Dienstleistungen erhalten und eine personalisierte Erfahrung machen, die auf meinen Interessen basiert.",
      "MARKETING_CONSENT_CHECKBOX__DK": "Ich möchte Updates (einschließlich per E-Mail, SMS, MMS, soziale Medien, Telefon, physischer Brief) über GUCCI neue Aktivitäten, exklusive Produkte, maßgeschneiderte Dienstleistungen erhalten und eine personalisierte Erfahrung machen, die auf meinen Interessen basiert.",
      "MARKETING_CONSENT_CHECKBOX__UK": "Ich möchte Updates (einschließlich per E-Mail, SMS, MMS, soziale Medien, Telefon, physischer Brief) über GUCCI neue Aktivitäten, exklusive Produkte, maßgeschneiderte Dienstleistungen erhalten und eine personalisierte Erfahrung machen, die auf meinen Interessen basiert.",
      "MARKETING_CONSENT_CHECKBOX__DE": "Ich möchte Updates über GUCCI neue Aktivitäten, exklusive Produkte, maßgeschneiderte Dienstleistungen erhalten und eine personalisierte Erfahrung machen, die auf meinen Interessen basiert, und ich stimme der Verwendung meiner persönlichen Informationen zu diesem Zweck zu.",
      "MARKETING_CONSENT_CHECKBOX__QA": "Ich möchte Updates über GUCCI neue Aktivitäten, exklusive Produkte, maßgeschneiderte Dienstleistungen erhalten und eine persönliche Kundenerfahrung machen, die auf meinen Interessen basiert.",
      "DATA_OUTSIDE_COUNTRY_CONSENT_CHECKBOX": `Ich bin damit einverstanden, dass meine persönlichen Informationen außerhalb meines Wohnsitzlandes übertragen werden können, wie in der <a href="${baseUrl}/st/privacy-landing" target="_blank">Datenschutzrichtlinie</a> beschrieben.*`,
      "NEW_PASSWORD": "NEUES PASSWORT",
      "PASSWORD": "PASSWORT",
      "PHONE": "TELEFONNUMMER",
      "READ_MORE": "MEHR LESEN",
      "REMEMBER_ME": "Angemeldet bleiben",
      "REQUIRED_FIELD": "Erforderliches Feld",
      "RESET_PASSWORD": "Passwort zurücksetzen",
      "SHOW_PASSWORD": "Passwort anzeigen",
      "SOCIALID": "Social ID für den Service",
      "SUBMIT": "ABSCHICKEN",
      "SKIP_SOCIAL": "ÜBERSPRINGEN UND MIT DER E-MAIL FORTFAHREN",
      "TITLE": "ANREDE AUSWÄHLEN",
      "TITLE_A": "Mx",
      "TITLE_MR": "Herr",
      "TITLE_MRS": "Frau",
      "TITLE_MS": "Fräulein",
      "TITLE_N": "Ich möchte lieber keine Angabe machen",
      "YEAR": "Jahr",
      "CREATE_MY_PROFILE": "ERSTELLE MEIN PROFIL",
      "SKIP_LOGIN": "VORERST ÜBERSPRINGEN",
      "PLACEHOLDER_DD": "TT",
      "PLACEHOLDER_MM": "MM",
      "PLACEHOLDER_YYYY": "JJJJ",
      "DOS_SUBMIT": "EIN KONTO ERSTELLEN"
    },
    "messages": {
      "AUTH_ERROR": "Falsche Kombination aus E-Mail und Passwort. Bitte versuchen Sie es erneut oder klicken Sie auf \"Passwort vergessen?\".",
      "BIRTHDATE_ERROR": "Bitte geben Sie ein gültiges Geburtsdatum ein",
      "BAA_DESCRIPTION": "Melden Sie sich bei MY GUCCI an oder registrieren Sie sich, um einen Termin für ein persönliches Einkaufserlebnis mit einem Kundenberater zu vereinbaren.",
      "BAA_INFORMATION": "Wenn Sie bereits ein MY GUCCI Konto besitzen, werden Sie gebeten, sich anzumelden. Wenn nicht, können Sie ein Profil erstellen, um mit der Buchung Ihres Termins fortzufahren.",
      "CHECKOUT_INFORMATION": "Wenn Sie bereits ein MY GUCCI Konto besitzen, werden Sie gebeten, sich anzumelden. Wenn nicht, können Sie als Gast fortfahren und sich auf Wunsch nach dem Bezahlvorgang registrieren.",
      "CHECKOUT_INFORMATION_CREATE_ACCOUNT": "Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an oder erstellen Sie ein Konto, wenn Sie neu sind.",
      "CHECKOUT_REVIEW_INFORMATION": "Bevor Sie fortfahren, registrieren Sie sich bitte bei MY GUCCI, indem Sie die Informationen für %email% vervollständigen.",
      "COMPLETE_REVIEW_PROFILE": "Bitte vervollständigen Sie Ihr Profil, um Ihr My Gucci Konto zu aktivieren. Optimieren Sie Ihr Einkaufserlebnis mit Kollektionen nur für Mitglieder, exklusiven Inhalten und einfachen Bestellmöglichkeiten.",
      "EMAIL_ERROR": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      "EMAIL_VALIDATION_CONFIRM": "Bitte klicken Sie auf den Link, um Ihre E-Mail-Adresse für Ihr My Gucci Konto zu bestätigen. Optimieren Sie Ihr Einkaufserlebnis mit Kollektionen nur für Mitglieder, exklusiven Inhalten und einfachen Bestellmöglichkeiten.",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "Bitte geben Sie nachfolgend Ihre Daten ein, um Ihr Gucci Konto zu erstellen.",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "Wir haben Ihnen eine E-Mail mit einem neuen Link zur Bestätigung Ihres Kontos an folgende E-Mail-Adresse gesendet: %email%",
      "FIRST_NAME_ERROR": "Bitte geben Sie Ihren Vornamen ein",
      "FORGOT_PASSWORD_INFORMATION": "Der Link zum Zurücksetzen Ihres Passworts wird an folgende E-Mail-Adresse gesendet. Bitte bestätigen Sie diese.",
      "FORGOT_PASSWORD_INSTRUCTION": "Bitte überprüfen Sie Ihren Posteingang und setzen Sie Ihr Passwort über den Link zurück.",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "Wir haben eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts an folgende E-Mail-Adresse gesendet",
      "GENERIC_ERROR": "Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      "SOCIAL_ACCOUNT_EXISTS": "Sie sind mit %email% registriert",
      "LAST_NAME_ERROR": "Bitte geben Sie Ihren Nachnamen ein",
      "LOGOUT": "Möchten Sie sich wirklich abmelden?",
      "OR": "ODER",
      "ORDER_ACCOUNT": "Optimieren Sie Ihr Einkaufserlebnis mit Kollektionen nur für Mitglieder, exklusiven Inhalten und einfachen Bestellmöglichkeiten.",
      "OVERLAY_RESET_LINK_EXPIRED": "Klicken Sie auf \"Passwort vergessen\", um einen neuen Link zum Zurücksetzen Ihres Passworts anzufordern.",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "Um einen neuen Link zu erhalten, klicken Sie bitte auf „Eine neue E-Mail anfordern“",
      "PASSWORD_UPDATED_HTML": "Ihr Passwort wurde erfolgreich geändert.<br />Sie werden angemeldet.",
      "PASSWORD_ERROR": "Bitte geben Sie ein Passwort ein",
      "PASSWORD_CHARACTER_CHECK": "Bitte geben Sie mindestens 8 Zeichen ein",
      "PASSWORD_NUMBER_CHECK": "Bitte geben Sie mindestens eine Zahl ein",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "Bitte geben Sie ein Sonderzeichen (! ,-./:;<=>?@) ein",
      "REGISTRATION_AGE_ERROR": "Sie mindestens %age% Jahre alt sein müssen",
      "REGISTRATION_INFORMATION": "Wenn Sie bereits bei uns registriert sind, müssen Sie Ihr Passwort eingeben.",
      "REVIEW_INFORMATION": "Bitte überprüfen Sie die Informationen für",
      "SAME_PASSWORD_ERROR": "Bitte wählen Sie ein anderes Passwort als das vorherige.",
      "TITLE_ERROR": "Bitte wählen Sie eine Anrede aus",
      "LOADER_TITLE": "Ihr MY GUCCI Konto ist bereit",
      "LOADER_SUBTITLE": "Sie werden jetzt zu Gucci.com weitergeleitet, wo Sie die digitale Welt von Gucci entdecken können."
    },
    "titles": {
      "ACCOUNT_EXISTS": "Wir haben Ihr Konto gefunden",
      "ACCESS_GUCCI": "Ihr Account MY GUCCI",
      "BAA": "EINEN TERMIN VEREINBAREN",
      "CONTINUE_WITH_EMAIL": "Fahren Sie mit Ihrer E-Mail-Adresse fort",
      "USE_YOUR_EMAIL": "Weiter mit E-Mail",
      "CHECKOUT": "Wie möchten Sie bezahlen?",
      "CHECKOUT_REVIEW_PROFILE": "MY GUCCI konto",
      "LOGIN_TO_CHECKOUT": "Anmelden oder weiter als Gast",
      "CHANGING_PASSWORD_FOR": "Passwort ändern für %email%",
      "EMAIL_VALIDATION": "Sie haben eine E-Mail",
      "SETTING_PASSWORD_FOR": "Erstellen Sie ein Passwort, um das Gucci Konto für folgende E-Mail-Adresse zu aktivieren: %email%",
      "FORGOT_PASSWORD_EMAIL_SENT": "Link gesendet!",
      "OVERLAY_RESET_LINK_EXPIRED": "Ihr Link ist abgelaufen",
      "PASSWORD_UPDATED": "Passwort aktualisiert",
      "PRIVACY_POLICY": "DATENSCHUTZBESTIMMUNGEN",
      "DOS_TITLE": "MY GUCCI KONTO ERSTELLEN",
      "DOS_SUBTITLE": "passwort festlegen für "
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "Die Sicherheit Ihrer Daten hat bei Gucci höchste Priorität. Wir haben Ihnen eine E-Mail gesendet, um Ihre E-Mail-Adresse zu überprüfen.<br/><br/>Bitte klicken Sie auf den Link in der E-Mail, um Ihr Konto zu verifizieren und ein Passwort festzulegen.",
      "MARKETING_CONSENT_HTML": `<br />Ich bin damit einverstanden (oder, falls ich in der Gerichtsbarkeit meines Wohnsitzes noch nicht volljährig bin, der Träger der elterlichen Verantwortung), dass meine persönlichen Daten von Gucci und Kering verwendet werden, um mit mir über meine Interessen und Einkaufsgewohnheiten sowie für andere Marketingzwecke über verschiedene Kommunikationswege zu kommunizieren, einschließlich elektronischer Marketingkommunikation wie E-Mail, SMS und über soziale Medien, wie in der <a href="${baseUrl}/st/privacy-landing" target="_blank">Datenschutzrichtlinie</a> beschrieben.`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT_CREATE_MY_GUCCI_ACCOUNT": getPrivacyPolicyTextCreateMyAccountToProceed(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AT": getPrivacyPolicyHTML_AT(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SE": getPrivacyPolicyHTML_SE(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTML_QA(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTML_KW(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SA": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AE": getPrivacyPolicyHTML_Country(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "value_propositions" : {
      "TITLE1": "WERDEN SIE TEIL VON MY GUCCI",
      "TITLE2": "ERLEBEN SIE DIE WELT VON GUCCI",
      "TITLE3": "GANZ NAH DRAN AN GUCCI",
      "VALUE1": {
        "TITLE": "SENDUNGSVERFOLGUNG",
        "CONTENT": "Verfolgen Sie Ihre bestellten Artikel in jeder Phase."
      },
      "VALUE2": {
        "TITLE": "SCHNELLERER BESTELLVORGANG",
        "CONTENT": "Speichern Sie Ihre Adressen und Zahlungsmethoden, um Bestellungen schneller abzuschließen."
      },
      "VALUE3": {
        "TITLE": "EINEN TERMIN VEREINBAREN",
        "CONTENT": "Planen Sie Ihren persönlichen Termin im Store oder virtuell im digitalen Showroom."
      },
      "VALUE4": {
        "TITLE": "INDIVIDUELLE BERATUNG",
        "CONTENT": "Die Kundenberater*innen von Gucci unterstützen Sie jederzeit mit professionellen Tipps."
      },
      "VALUE5": {
        "TITLE": "EXKLUSIVE NEUIGKEITEN",
        "CONTENT": "Erfahren Sie Neues im Gucci Newsletter und profitieren Sie von persönlichen Empfehlungen."
      },
      "VALUE8": {
        "TITLE": "EIN INDIVIDUELLES ERLEBNIS",
        "CONTENT": "Erleben Sie die Welt von Gucci hautnah, ob im Store oder online."
      },
    }
  };
}

function englishGreatBritain(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) => `By choosing "Create my profile", you confirm that you agree to our <a href="${LINK2}" target="_blank">Terms of Use</a>, that you have read and understood our <a href="${LINK1}" target="_blank">privacy policy</a>, and that you want to create your GUCCI profile.`;
  const getPrivacyPolicyTextCreateMyAccountToProceed = LINK => `By choosing "Create my GUCCI Account", you confirm that you have read and understood our <a href="${LINK}" target="_blank">privacy policy</a>, and that you want to create your GUCCI profile.`;
  const getPrivacyPolicyTextSG = (LINK1, LINK2) => `By choosing "Create my profile", you confirm that you agree to our <a href="${LINK2}" target="_blank">Terms of Use</a> and to our <a href="${LINK1}" target="_blank">privacy policy</a>, and that you want to create your GUCCI profile.`;
    const getPrivacyPolicyTextUS = (LINK1, LINK2) => `By choosing "Create my profile", you confirm that you agree to our <a href="${LINK2}" target="_blank">Terms of Use</a>, that you have acknowledged our <a href="${LINK1}" target="_blank">privacy policy</a>, and that you want to create your GUCCI profile.`;
  const getPrivacyPolicyTextCountry = (LINK1, LINK2) => `By choosing "Create my profile", you confirm that you agree to our <a href="${LINK2}" target="_blank">Terms of Use</a> and to our <a href="${LINK1}" target="_blank">privacy policy</a>, and that you want to create your GUCCI profile.`;
  const getPrivacyPolicyHTML = LINK => `You are invited to read our <a href="${LINK}" target="_blank">privacy policy</a> in full. In any event, here are some key points we would like to share with you:<br />
                              <br />
                              You are about to create your GUCCI profile. This will allow GUCCI to offer you a personalized and tailored experience, provide you with products, services and information you request from GUCCI and communicate with you. All your personal information will be kept, used and linked to your GUCCI profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. <br />
                              <br />
                              Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;

  const getPrivacyPolicyHTMLAT = LINK => `You are invited to read our <a href="${LINK}" target="_blank">privacy policy</a> in full. In any event, here are some key points we would like to share with you:<br />
                                <br />
                                You are about to create your GUCCI profile. This will allow GUCCI to offer you a personalized and tailored experience, provide you with products, services and information you request from GUCCI and communicate with you. All your personal information will be kept, used and linked to your GUCCI profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you (including by phone if you share your phone number with us). <br />
                                <br />
                                Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
        `;
  const getPrivacyPolicyHTMLKW = LINK => `You are invited to read our <a href="${LINK}" target="_blank">privacy policy</a> in full. In any event, here are some key points we would like to share with you:<br />
        <br />
        You are about to create your GUCCI profile. This will allow GUCCI to offer you a personalized and tailored experience, provide you with products, services and information you request from GUCCI and communicate with you. All your personal information will be kept, used and linked to your GUCCI profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. By creating your Gucci profile, you confirm that you have read, understood and that you agree to our <a href="${baseUrl}/st/privacy-landing" target="_blank">privacy policy</a>.<br />
        <br />
        Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You have the right to consent or not to consent to the collection and processing of your personal data. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;
  const getPrivacyPolicyHTMLSE = LINK => `You are invited to read our <a href="${LINK}" target="_blank">privacy policy</a> in full. In any event, here are some key points we would like to share with you:<br />
        <br />
        You are about to create your GUCCI profile. This will allow GUCCI to offer you a personalized and tailored experience, provide you with products, services and information you request from GUCCI and communicate with you. All your personal information will be kept, used and linked to your GUCCI profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. When you create a GUCCI profile, we generally retain your personal information for 5 years from the last interaction you initiated with us. We may use any contact information you share with us to contact you. <br />
        <br />
        Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;
  const getPrivacyPolicyHTMLQA = LINK => `You are invited to read our <a href="${LINK}" target="_blank">privacy policy</a> in full. In any event, here are some key points we would like to share with you:<br />
                                  <br />
                                  You are about to create your GUCCI profile. GUCCI will use your profile to provide you with the products, services and information you purchase or request from GUCCI, offer you customized assistance and customer service, send you tailored promotional communications, conduct our business securely and in accordance with our obligations, and optimize and improve our services. All your personal information will be kept, used and linked to your GUCCI profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. <br />
                                  <br />
                                  Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
       `;

  const getPrivacyPolicyHTMLMX = LINK => `You are invited to read our <a href="${LINK}" target="_blank">privacy policy</a> in full. In any event, here are some key points we would like to share with you:<br />
                                <br />
                                You are about to create your GUCCI profile. This will allow GUCCI to offer you a personalized and tailored experience, provide you with products, services and information you request from GUCCI and communicate with you. All your personal information will be kept, used and linked to your GUCCI profile. Your profile could include information we obtained directly from you as well as from our retailers and other commercial partners. As we operate globally, we may securely share your personal information with companies of our group and our approved partners located around the world. We will implement safeguards to protect your personal information. We may use any contact information you share with us to contact you. By creating your GUCCI profile, you confirm that you have read, understood and that you agree to our privacy policy.<br />
                                <br />
                                Privacy laws may grant you certain rights such as the right to access, delete, modify and rectify your data, or to restrict or object to processing, as well as the right to data portability. You can also lodge a complaint with your competent regulator. You can withdraw your consent or delete your profile at any time. For further information regarding our privacy practices and your rights, please contact us at <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `By choosing 'continue' below, you accept the terms of our <a href="${LINK}" target="_blank">privacy policy</a>.`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">privacy policy</a>`;
  return {
    "labels": {
      "CANCEL": "CANCEL",
      "CHANGE_LANGUAGE": "CHANGE LANGUAGE",
      "CHECKOUT_CONTINUE": "CONTINUE TO CHECKOUT",
      "CHOOSE_PASSWORD": "Choose your password",
      "CLOSE": "CLOSE",
      "COMPLETE_YOUR_PROFILE": "COMPLETE YOUR PROFILE",
      "CONFIRM": "CONFIRM",
      "CONFIRM_EMAIL_ADDRESS": "Verify your account",
      "CONTINUE": "CONTINUE",
      "PROCEED_TO_CHECKOUT":"PROCEED TO CHECKOUT",
      "CONTINUE_WITH_GOOGLE": "CONTINUE WITH GOOGLE",
      "CONTINUE_WITH_APPLE": "Continue with Apple",
      "CONTINUE_WITH_KAKAO": "Continue with Kakao",
      "CREATE_ACCOUNT": "CREATE MY GUCCI ACCOUNT",
      "CREATE_PASSWORD": "Create Password",
      "COUNTRY": "COUNTRY OF RESIDENCE",
      "DATE_OF_BIRTH": "Date of Birth",
      "DAY": "DAY",
      "EDIT_EMAIL": "Edit email",
      "EMAIL": "Email",
      "EMAIL_VALIDATION_RESEND": "Didn’t receive it? Request a New Email",
      "FINISH_REGISTRATION": "Finish Registration",
      "FIRST_NAME": "First Name",
      "FOLLOW": "Kakao Channel Friends Status",
      "FOLLOW_ADDED": "ADDED",
      "FOLLOW_BLOCKED": "BLOCKED",
      "FOLLOW_NONE": "NONE",
      "FORGOT_PASSWORD": "Forgot your password?",
      "GENDER": "Gender",
      "GENDER_MALE": "Male",
      "GENDER_FEMALE": "Female",
      "GENDER_A": "Mx",
      "GENDER_N": "Prefer not to answer",
      "GUEST_USER_CHECKOUT": "SKIP LOGIN",
      "CONTINUE_AS_GUEST": "Continue as a guest",
      "LAST_NAME": "Last Name",
      "MONTH": "MONTH",
      "MARKETING_CONSENT_CHECKBOX": "I would like to receive updates about GUCCI new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.",
      "MARKETING_CONSENT_CHECKBOX__SA": "I would like to receive updates about GUCCI new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.",
      "MARKETING_CONSENT_CHECKBOX__DE": "I would like to receive updates about GUCCI new activities, exclusive products, tailored services and to have a personalised client experience based on my interests, and I agree to the use of my personal information for this purpose.",
      "MARKETING_CONSENT_CHECKBOX__DK": "I would like to receive updates (including by email, SMS, MMS, social media, phone, physical letter) about GUCCI new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.",
      "MARKETING_CONSENT_CHECKBOX__UK": "I would like to receive updates (including by email, SMS, MMS, social media, phone, physical letter) about GUCCI new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.",
      "MARKETING_CONSENT_CHECKBOX__AU": "I would like to receive updates (including by email, SMS, MMS, social media, phone, physical letter) about GUCCI new activities, exclusive products, tailored services and to have a personalised client experience based on my interests.",
      "MARKETING_CONSENT_CHECKBOX__SG": "I would like to receive updates about GUCCI new activities, exclusive products and tailored services (including via phone, text messages and instant messaging applications) and to have a personalised client experience based on my interests.",
      "PROFILING_CONSENT_CHECKBOX": "I understand that GUCCI will draw inferences based on my profile information (profiling).*",
      "DATA_OUTSIDE_COUNTRY_CONSENT_CHECKBOX": `I agree that my personal information may be transferred outside my country of residence, as described in the <a href="${baseUrl}/st/privacy-landing" target="_blank">privacy policy</a>.*`,
      "NEW_PASSWORD": "New Password",
      "PASSWORD": "Password",
      "PHONE": "PHONE",
      "READ_MORE": "READ MORE",
      "REMEMBER_ME": "Stay signed in",
      "REQUIRED_FIELD": "Required field",
      "RESET_PASSWORD": "Reset Password",
      "SHOW_PASSWORD": "Show Password",
      "SOCIALID": "Service Social ID",
      "SUBMIT": "SUBMIT",
      "SKIP_SOCIAL": "Skip & Continue with email",
      "TITLE": "SELECT TITLE",
      "TITLE_A": "Mx",
      "TITLE_MR": "Mr.",
      "TITLE_MRS": "Mrs.",
      "TITLE_MS": "Ms.",
      "TITLE_N": "I’d rather not say",
      "YEAR": "YEAR",
      "CREATE_MY_PROFILE": "CREATE MY PROFILE",
      "SKIP_LOGIN": "Skip For Now",
      "PLACEHOLDER_DD": "DD",
      "PLACEHOLDER_MM": "MM",
      "PLACEHOLDER_YYYY": "YYYY",
      "DOS_SUBMIT": "CREATE AN ACCOUNT",
      "OTP": "OTP",
      "DIDNT_RECEIVE_OTP": "Didn't received your OTP? ",
      "RESEND_OTP": "Request again",
    },
    "messages": {
      "AUTH_ERROR": "Wrong email and password combination. Please try again or click on \"Forgot your password?\".",
      "BIRTHDATE_ERROR": "Please enter a valid date of birth",
      "BAA_DESCRIPTION": "Sign in or register with MY GUCCI to schedule a personalized shopping experience with a dedicated Client Advisor.",
      "BAA_INFORMATION": "If you already have a MY GUCCI account, you will be asked to sign in. If not, you can create a profile to continue to book your appointment.",
      "YUGA_DESCRIPTION": "If you held a Koda or Vessel NFT with the KodaPendant trait at the time of the snapshot on June 21 at 10 a.m. EDT, create a Gucci profile or sign in to your MY GUCCI account to claim.",
      "YUGA_INFORMATION": "Continue with your email address and password to sign in to your MY GUCCI ACCOUNT or create a Gucci profile if you are new.",
      "CHECKOUT_INFORMATION": "If you already have a MY GUCCI account, you will be asked to sign in. If not, you can continue as a guest and choose to register after checkout.",
      "CHECKOUT_INFORMATION_CREATE_ACCOUNT": "Log-in with your email and password or create an account if you are new.",
      "CHECKOUT_REVIEW_INFORMATION": "Before you continue, please sign-up with MY GUCCI by completing the information for %email%.",
      "COMPLETE_REVIEW_PROFILE": "Please complete your profile to activate your My Gucci Account. Streamline your shopping experience with member only collections, exclusive content and easy orders.",
      "EMAIL_ERROR": "Please enter a valid email address",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "Please add your details below to create your Gucci account.",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "We have emailed you a new link to verify your account at %email%",
      "EMAIL_VALIDATION_CONFIRM": "Please use the link to confirm your email address to activate your My Gucci account. Streamline your shopping experience with member only collections, exclusive content and easy orders.",
      "FIRST_NAME_ERROR": "Please enter your first name",
      "FORGOT_PASSWORD_INFORMATION": "The link to reset your password will be sent to the following email address. Please confirm.",
      "EMAIL_VERIFICATION_INFORMATION": "You already have a MY GUCCI account, The OTP to create a new password will be sent to the following email address. Please confirm.",
      "OTP_VERIFICATION_INFORMATION": "An OTP (One Time Password) has been sent to your email. Please enter it and choose a new password to access your Gucci account.",
      "FORGOT_PASSWORD_INSTRUCTION": "Please check your email and initiate the reset password process from the link.",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "We have sent an email with instructions to reset your password at",
      "GENDER_ERROR": "Please select a gender",
      "GENERIC_ERROR": "Sorry, something went wrong. Please try again.",
      "SOCIAL_ACCOUNT_EXISTS": "You are registered with %email%",
      "LAST_NAME_ERROR": "Please enter your last name",
      "LOGOUT": "Do you really want to logout?",
      "OR": "OR",
      "ORDER_ACCOUNT": "Streamline your shopping experience with member only collections, exclusive content and easy orders.",
      "PASSWORD_UPDATED_HTML": "Your password has been successfully updated.<br />Signing you in.",
      "OVERLAY_RESET_LINK_EXPIRED": "Please click on \"forgot password\" to request a new reset link.",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "To receive a new link please click “Request a new email”",
      "PASSWORD_CHARACTER_CHECK": "Please enter at least 8 characters",
      "PASSWORD_INFORMATION": "Please note that full/half width and upper/lower case are distinguished in the password.",
      "PASSWORD_NUMBER_CHECK": "Please enter at least one number",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "Please enter one special character (!+,-./:;<=>?@)",
      "PASSWORD_ERROR": "Please enter a password",
      "REGISTRATION_AGE_ERROR": "You must be %age% or older",
      "REGISTRATION_INFORMATION": "If you have already registered with us, your password will be requested.",
      "REVIEW_INFORMATION": "Please review the information for",
      "SAME_PASSWORD_ERROR": "Please choose a different password than the previous one.",
      "TITLE_ERROR": "Please select a title",
      "LOADER_TITLE": "Your MY GUCCI Account is Complete",
      "LOADER_SUBTITLE": "You will now be taken to Gucci.com where you can start exploring the House's digital universe.",
      "LOADER_YUGA_TITLE": "Thank you for registering!",
      "LOADER_YUGA_SUBTITLE": "You will now return to the Gucci KodaPendant site.",
      "OTP_VALIDATION_ERROR": "OTP validation failed. Please enter valid OTP."
    },
    "titles": {
      "ACCOUNT_EXISTS": "We Found Your Account",
      "ACCESS_GUCCI": "MY GUCCI Account",
      "BAA": "Book an appointment",
      "YUGA": "CLAIM YOUR PHYSICAL KODAPENDANT",
      "CHANGING_PASSWORD_FOR": "Changing password for %email%",
      "EMAIL_VALIDATION": "You’ve got mail",
      "SETTING_PASSWORD_FOR": "Create a new password to enter Gucci account for %email%",
      "CHECKOUT": "How Would You Like to Checkout?",
      "CHECKOUT_REVIEW_PROFILE": "MY GUCCI profile",
      "LOGIN_TO_CHECKOUT": "Sign In or Check Out as a Guest",
      "CONTINUE_WITH_EMAIL": "Continue with your email address",
      "USE_YOUR_EMAIL": "Continue with Email",
      "FORGOT_PASSWORD_EMAIL_SENT": "Link Sent!",
      "PASSWORD_UPDATED": "Password updated",
      "OVERLAY_RESET_LINK_EXPIRED": "Your link has expired",
      "PRIVACY_POLICY": "PRIVACY POLICY",
      "DOS_TITLE": "Create a<br />MY GUCCI Account",
      "DOS_SUBTITLE": "set a password for"
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "At Gucci we care about the security of your data. We’ve sent you an email to check it's really you.<br/><br/>Please click the link in the email to verify your account and choose a password.",
      "MARKETING_CONSENT_FULL_HTML": `<br />I agree to (or, if I have not reached the age of consent in my jurisdiction of residence, the holder of parental authority) my Personal Data being used by Gucci and Kering, to communicate with me on my interests and shopping habits and for other marketing purposes, through various communication channels, including via electronic marketing communications, such as e-mail, text message and social media, as detailed in the <a href="${baseUrl}/st/privacy-landing" target="_blank">Privacy Policy</a>.
                                            <br />
                                            <br />
                                            I may withdraw my consent at any time by writing to <a href="mailto:privacy@gucci.com">privacy@gucci.com</a> or using the unsubscribe or opt-out functions included with such communications. I understand that marketing text messages may be sent manually or using an auto dialer and that my consent to receive autodialed text messages is not a condition of purchase. I acknowledge that I may opt-out of marketing text messages at any time and that messaging, and data rates may apply.`,
      "MARKETING_CONSENT_HTML": `<br />I agree to (or, if I have not reached the age of consent in my jurisdiction of residence, the holder of parental authority) my Personal Data being used by Gucci and Kering, to communicate with me on my interests and shopping habits and for other marketing purposes, through various communication channels, including via electronic marketing communications, such as e-mail, text message and social media, as detailed in the <a href="${baseUrl}/st/privacy-landing" target="_blank">Privacy Policy</a>`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT_CREATE_MY_GUCCI_ACCOUNT": getPrivacyPolicyTextCreateMyAccountToProceed(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__US": getPrivacyPolicyTextUS(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__SG": getPrivacyPolicyTextSG(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__MX": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLQA(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AT": getPrivacyPolicyHTMLAT(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTMLKW(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SE": getPrivacyPolicyHTMLSE(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__MX": getPrivacyPolicyHTMLMX(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`),
      "AGE_OF_CONSENT": "<br>By creating your GUCCI profile, you confirm that you have reached the age of consent in your country of residence (or, if you are under the age of consent, that your parent or legal guardian also agrees to such registration).",

    },
    "value_propositions" : {
      "TITLE1": "JOIN MY GUCCI",
      "TITLE2": "JOIN THE GUCCI UNIVERSE",
      "TITLE3": "GET CLOSER TO THE HOUSE",
      "VALUE1": {
        "TITLE": "TRACK YOUR ORDERS",
        "CONTENT": "Follow your orders every step of the way."
      },
      "VALUE2": {
        "TITLE": "STREAMLINE CHECKOUT",
        "CONTENT": "Check out faster with saved addresses and payment methods."
      },
      "VALUE3": {
        "TITLE": "BOOK AN APPOINTMENT",
        "CONTENT": "Enjoy priority access to the boutique of your choice at the time and date that suits you."
      },
      "VALUE4": {
        "TITLE": "ENJOY DEDICATED ADVICE",
        "CONTENT": "A Gucci Client Advisor is always available with expert suggestions."
      },
      "VALUE5": {
        "TITLE": "BE THE FIRST TO KNOW",
        "CONTENT": "Enjoy the Gucci newsletter and personalized recommendations."
      },
      "VALUE8": {
        "TITLE": "A BESPOKE EXPERIENCE",
        "CONTENT": "From in-store to online, move seamlessly across the Gucci universe."
      },
    }
  };
}

function japanese(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) => `「プロフィールを作成する」を選択することにより、お客様は、弊社の<a href="${LINK2}" target="_blank">利用規約</a>に同意したこと、弊社の<a href="${LINK1}" target="_blank">個人情報保護方針</a>を読み、理解したことを確認し、GUCCI のプロフィールの作成を希望されるものとします。`;

  const getPrivacyPolicyHTML = LINK => `弊社<a href="${LINK}" target="_blank">個人情報保護方針</a>をよくお読みください。主要ポイントの詳細を次で説明いたします：<br />
                              <br />
                              GUCCI プロファイルの作成について。GUCCIはあなた個人の好みに合わせたパーソナライズド体験を提案すると同時に、あなたがGUCCIにリクエストした製品・サービス・情報をお届けし、あなたと交流します。あなたの個人情報は全て、GUCCIプロフィールに保存、使用、紐づけされます。プロフィールには、あなたから直接得た情報をはじめ、弊社商品を扱うショップや他の取引先から得た情報も含みます。弊社は世界各地で事業展開しており、あなたの個人情報は、私どものグループ傘下企業及び各国の現地正式取引先と、安全に共有されます。弊社ではあなたの個人情報を保護するために、安全対策を講じています。弊社は、あなたが弊社と共有している連絡先を通じて連絡を差し上げることがあります。<br />
                              <br />
                              個人情報保護法は、あなた個人のデータへのアクセス、削除、変更、修正、ないしは処理の制限や異議などへの権利をはじめ、データポータビリティ権といった権利をあなたに保証します。お住まいの地域の個人情報保護委員会に訴えることも可能です。あなたは何時も同意の取消しやプロフィールの削除ができます。弊社における個人情報保護実施やあなたの権利についての詳細は、<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>へお問い合わせください。
      `;
  const getPrivacyPolicyShortHTML = LINK => `ご選択いただくことで、弊社のプライバシーポリシーに同意いただいたことになります。 <a href="${LINK}" target="_blank">プライバシーポリシーはこちら</a>`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">プライバシーポリシーはこちら</a>`;
  return {
    "labels": {
      "CANCEL": "キャンセルする",
      "CHECKOUT_CONTINUE": "決済を続ける",
      "CHOOSE_PASSWORD": "パスワードを選ぶ",
      "CLOSE": "閉じる",
      "COMPLETE_YOUR_PROFILE": "プロフィールを完成する",
      "CONFIRM": "確認する",
      "CONFIRM_EMAIL_ADDRESS": "アカウントを確認する",
      "CONTINUE": "送信",
      "PROCEED_TO_CHECKOUT":"ご購入手続きに進む",
      "CONTINUE_WITH_GOOGLE": "GOOGLEで続ける",
      "CONTINUE_WITH_APPLE": "Appleで続ける",
      "CONTINUE_WITH_KAKAO": "Kakaoで続ける",
      "CREATE_ACCOUNT": "MY GUCCI アカウントの作成",
      "CREATE_PASSWORD": "パスワードを設定する",
      "COUNTRY": "お住まいの国",
      "DATE_OF_BIRTH": "生年月日",
      "DAY": "日",
      "EDIT_EMAIL": "メールを編集する",
      "EMAIL": "Eメール",
      "EMAIL_VALIDATION_RESEND": "メールが届かない場合は、メールの再送信をリクエストしてください。",
      "FINISH_REGISTRATION": "登録終了",
      "FIRST_NAME": "名",
      "FOLLOW": "カカオトークチャンネル 友達ステータス",
      "FOLLOW_ADDED": "追加されました",
      "FOLLOW_BLOCKED": "ブロック",
      "FOLLOW_NONE": "なし",
      "FORGOT_PASSWORD": "パスワードをお忘れですか？",
      "GENDER": "タイトルを選択する",
      "GENDER_MALE": "男性",
      "GENDER_FEMALE": "女性",
      "GENDER_A": "Mx",
      "GENDER_N": "回答しない",
      "GUEST_USER_CHECKOUT": "ログインをスキップ",
      "CONTINUE_AS_GUEST": "ゲストとして購入",
      "LAST_NAME": "姓",
      "MONTH": "月",
      "MARKETING_CONSENT_CHECKBOX": "GUCCI新事業、限定製品、カスタムメイドサービスに関する最新情報を受け取り、私の関心に基づいたパーソナライズド体験を希望します。",
      "NEW_PASSWORD": "新しいパスワード",
      "PASSWORD": "パスワード",
      "PHONE": "お電話",
      "READ_MORE": "詳しく見る",
      "REMEMBER_ME": "サインインしたままにする",
      "REQUIRED_FIELD": "必須項目",
      "RESET_PASSWORD": "パスワードを再設定",
      "SHOW_PASSWORD": "パスワードを表示する",
      "SOCIALID": "使用者ID",
      "SUBMIT": "送る",
      "SKIP_SOCIAL": "スキップしてメールを続ける",
      "TITLE": "タイトルを選択する",
      "TITLE_A": "Mx",
      "TITLE_MR": "Mr.",
      "TITLE_MRS": "Mrs.",
      "TITLE_MS": "Ms.",
      "TITLE_N": "I’d rather not say",
      "YEAR": "年",
      "CREATE_MY_PROFILE": "プロフィールを作成する",
      "SKIP_LOGIN": "スキップする",
      "PLACEHOLDER_DD": "日(DD)",
      "PLACEHOLDER_MM": "月(MM)",
      "PLACEHOLDER_YYYY": "年(YYYY)",
      "DOS_SUBMIT": "CREATE AN ACCOUNT"
    },
    "messages": {
      "AUTH_ERROR": "メールアドレスとパスワードの組み合わせが間違っています。再試行する、または「パスワードをお忘れですか？」をクリックしてください。",
      "BIRTHDATE_ERROR": "誕生日をご入力ください。",
      "BAA_DESCRIPTION": "MY GUCCIにサインインまたは登録して、専任のクライアントアドバイザーによるパーソナル ショッピングサービスのアポイントメントをお取りください。",
      "BAA_INFORMATION": "MY GUCCI アカウントにご登録済みの方は、サインインが必要です。ご登録のない方も、アカウントを作成していただければ、そのままアポイントメントをお取りいただけます。",
      "CHECKOUT_INFORMATION": "MY GUCCI アカウントにご登録の方は、サインインを求められます。ご登録のない方は、ゲストとして続けて、チェックアウト後に登録を選択することができます。",
      "CHECKOUT_REVIEW_INFORMATION": "続ける前に、%email%の情報を入力してMY GUCCI アカウントを作成してください。",
      "COMPLETE_REVIEW_PROFILE": "プロフィールを完成していただくと、MY GUCCI アカウントをご利用いただけるようになります。メンバー限定コレクションやコンテンツ、スマートなショッピング体験まで、多彩なサービスをお楽しみください。",
      "EMAIL_ERROR": "有効なメールアドレスを入力してください",
      "EMAIL_VALIDATION_CONFIRM": "リンクをクリックしてお客さまのメールアドレス認証を行っていただくと、MY GUCCI アカウントをご利用いただけるようになります。メンバー限定コレクションやコンテンツ、スマートなショッピング体験まで、多彩なサービスをお楽しみください。",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "グッチ アカウントを作成するには、以下のお客さま情報を追加してください。",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "%email%でアカウントを確認するための新しいリンクをメールで送信しました",
      "FIRST_NAME_ERROR": "名前を入力してください",
      "FORGOT_PASSWORD_INFORMATION": "下記メールアドレス宛にパスワードをリセットするためのリンクが送信されますので、確認してください。",
      "FORGOT_PASSWORD_INSTRUCTION": "メールを確認し、リンクからパスワードのリセットプロセスを開始してください。",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "パスワードをリセットする手順を記載したメールを送信しました。",
      "GENDER_ERROR": "性別を選択ください。",
      "GENERIC_ERROR": "申し訳ございませんが、エラーが発生しました。再試行をお願いします。",
      "SOCIAL_ACCOUNT_EXISTS": "%email%のメールアドレスで登録されています",
      "LAST_NAME_ERROR": "姓を入力してください",
      "LOGOUT": "本当にログアウトしますか",
      "OR": "または",
      "ORDER_ACCOUNT": "メンバー限定コレクションやコンテンツ、スマートなショッピング体験まで、多彩なサービスをご用意しています。",
      "PASSWORD_UPDATED_HTML": "お客さまのパスワードは無事に更新されました<br />サインインしています。",
      "OVERLAY_RESET_LINK_EXPIRED": "「パスワードをお忘れですか」をクリックし、再設定用の新しいリンクを依頼してください",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "新しいリンクを受け取るには、「メールの送信をリクエストする」をクリックしてください",
      "PASSWORD_CHARACTER_CHECK": "最低8文字を入力してください",
      "PASSWORD_INFORMATION": "パスワードは全角・半角、大文字・小文字が区別されますので、ご注意ください。",
      "PASSWORD_NUMBER_CHECK": "数字を最低1つ入力してください",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "特殊記号を1つ入力してください（!+,-./:;<=>?@）",
      "PASSWORD_ERROR": "パスワードを入力してください。",
      "REGISTRATION_AGE_ERROR": "アカウント作成は%age%歳以上の方を対象としております。",
      "REGISTRATION_INFORMATION": "すでにグッチに登録されている場合、パスワードが要求されます。",
      "REVIEW_INFORMATION": "の情報を確認してください",
      "SAME_PASSWORD_ERROR": "前回とは異なるパスワードをお選びください",
      "TITLE_ERROR": "敬称を選択してください",
      "LOADER_TITLE": "Your MY GUCCI Account is Complete",
      "LOADER_SUBTITLE": "You will now be taken to Gucci.com where you can start exploring the House's digital universe."
    },
    "titles": {
      "LOGIN_TO_CHECKOUT":"サインイン、またはゲストとしてチェックアウトする",
      "ACCOUNT_EXISTS": "アカウントが見つかりました",
      "ACCESS_GUCCI": "MY GUCCI アカウント",
      "BAA": "ご来店予約",
      "CHANGING_PASSWORD_FOR": "%email%の新しいパスワードを入力ください。",
      "EMAIL_VALIDATION": "メールを受信しました",
      "SETTING_PASSWORD_FOR": "%email%のグッチ アカウントにログインするための新しいパスワードを作成する",
      "CHECKOUT": "どのようなチェックアウト方法をご希望ですか？",
      "CHECKOUT_REVIEW_PROFILE": "MY GUCCI アカウント",
      "CONTINUE_WITH_EMAIL": "メールアドレスを使う",
      "USE_YOUR_EMAIL": "Eメールで続ける",
      "FORGOT_PASSWORD_EMAIL_SENT": "リンクが送信されました。",
      "PASSWORD_UPDATED": "パスワード更新",
      "OVERLAY_RESET_LINK_EXPIRED": "お客さまのリンクは有効期限が切れています",
      "PRIVACY_POLICY": "プライバシーポリシー",
      "DOS_TITLE": "Create a<br />MY GUCCI Account",
      "DOS_SUBTITLE": "set a password for"
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "グッチでは、お客さまのデータのセキュリティに配慮しています。本人確認のためのメールを送信しました。<br/><br/>メールのリンクをクリックしてアカウントを確認し、パスワードを選択してください。",
      "MARKETING_CONSENT_HTML": `<br />私（または私の居住管轄区域で承諾年齢に達していない場合は親権所有者）は、メールやテキストメッセージ、ソーシャルメディアなどの電子マーケティングコミュニケーションを含むさまざまなコミュニケーションチャンネルを通じて、私の個人情報が私の関心事やショッピング習慣、その他のマーケティング目的のためにグッチとケリングによって使用されることに同意します。詳しくは<a href="${baseUrl}/st/privacy-landing" target="_blank">プライバシーポリシー</a>をご覧ください。`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "value_propositions" : {
      "TITLE1": "MY GUCCI の登録をする",
      "TITLE2": "グッチの世界をお楽しみください",
      "TITLE3": "グッチをより身近に",
      "VALUE1": {
        "TITLE": "注文状況の確認",
        "CONTENT": "ご注文製品の配達状況を確認する"
      },
      "VALUE2": {
        "TITLE": "チェックアウト",
        "CONTENT": "登録したご住所と決済方法を使って、スムーズにチェックアウトする。"
      },
      "VALUE3": {
        "TITLE": "アポイントメントはこちらから",
        "CONTENT": "ご都合の良い日時に、ご希望のショップへのプライオリティ アクセスをお楽しみください。"
      },
      "VALUE4": {
        "TITLE": "お客様のためだけの特別なご提案",
        "CONTENT": "グッチのクライアントアドバイザーがお客様のご要望に応じたご提案をさせていただきます。"
      },
      "VALUE5": {
        "TITLE": "最新情報を受け取る",
        "CONTENT": "グッチのニュースレターやパーソナルアドバイスなどをお楽しみいただけます。"
      },
      "VALUE8": {
        "TITLE": "カスタマイズ サービス",
        "CONTENT": "グッチショップとオンラインショッピングで、グッチの世界をより身近にご堪能いただけます。"
      },
    }
  };
}

function spanish(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) => `Al seleccionar "Crear mi perfil", acepta nuestras <a href="${LINK2}" target="_blank">Condiciones de uso</a> y confirma que ha leído y comprendido nuestra <a href="${LINK1}" target="_blank">política de privacidad</a>, así como que desea crear su perfil de GUCCI.`;
  const getPrivacyPolicyTextCreateMyAccountToProceed = LINK => `Eligiendo "Crear cuenta my GUCCI", confirma que ha leído y entendido nuestra <a href="${LINK}" target="_blank">política de privacidad</a>, y que desea crear su perfil GUCCI.`;
  const getPrivacyPolicyTextCountry = (LINK1, LINK2) => `Al seleccionar «Crear mi perfil», acepta nuestras <a href="${LINK2}" target="_blank">Condiciones de uso</a>, nuestra <a href="${LINK1}" target="_blank">política de privacidad</a> y que desea crear su perfil de GUCCI.`;
  const getPrivacyPolicyHTML = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
                              <br />
                              Está a punto de crear su perfil GUCCI. Esto permitirá a GUCCI ofrecerle una experiencia personalizada y a medida, proporcionarle con productos, servicios e información que usted solicite de GUCCI y comunicarse con usted. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Podemos usar cualquier información de contacto que haya compartido con nosotros para contactarle. <br />
                              <br />
                              Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLAT = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
      <br />
      Está a punto de crear su perfil GUCCI. Esto permitirá a GUCCI ofrecerle una experiencia personalizada y a medida, proporcionarle con productos, servicios e información que usted solicite de GUCCI y comunicarse con usted. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Podemos utilizar cualquier información de contacto que comparta con nosotros para comunicarnos con usted (incluso por teléfono, en el caso que comparta su número con nosotros). <br />
      <br />
      Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLCountry = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
                                  <br />
                                  Está a punto de crear su perfil GUCCI. GUCCI usará su perfil para proporcionarle productos, servicios e información que usted compre o solicite por parte de GUCCI, ofrecerle asistencia personalizada y servicio de atención al cliente, enviarle comunicaciones comerciales personalizadas, llevar a cabo nuestro negocio de forma segura y de acuerdo con nuestras obligaciones, y optimizar y mejorar nuestros servicios. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Podemos usar cualquier información de contacto que haya compartido con nosotros para contactarle. Al crear su perfil GUCCI, confirma haber leído, entendido y acepta nuestra <a href="${baseUrl}/st/privacy-landing" target="_blank">política de privacidad</a>.<br />
                                  <br />
                                  Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
 const getPrivacyPolicyHTMLKW = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
      <br />
      Está a punto de crear su perfil GUCCI. GUCCI usará su perfil para proporcionarle productos, servicios e información que usted compre o solicite por parte de GUCCI, ofrecerle asistencia personalizada y servicio de atención al cliente, enviarle comunicaciones comerciales personalizadas, llevar a cabo nuestro negocio de forma segura y de acuerdo con nuestras obligaciones, y optimizar y mejorar nuestros servicios. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Podemos usar cualquier información de contacto que haya compartido con nosotros para contactarle. Al crear su perfil GUCCI, confirma haber leído, entendido y acepta nuestra <a href="${baseUrl}/st/privacy-landing" target="_blank">política de privacidad</a>.<br />
      <br />
      Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Tiene derecho a consentir o no consentir la recopilación y el procesamiento de su información personal. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;
  const getPrivacyPolicyHTMLSE = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
      <br />
      Está a punto de crear su perfil GUCCI. Esto permitirá a GUCCI ofrecerle una experiencia personalizada y a medida, proporcionarle con productos, servicios e información que usted solicite de GUCCI y comunicarse con usted. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Cuando crea un perfil GUCCI, generalmente conservamos su información personal por 5 años desde la última interacción que inició con nosotros. Podemos usar cualquier información de contacto que haya compartido con nosotros para contactarle. <br />
      <br />
      Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;
  const getPrivacyPolicyShortHTML = LINK => `Al seleccionar 'continuar' en la parte inferior, acepta los términos de la <a href="${LINK}" target="_blank">política de privacidad</a>.`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">política de privacidad</a>`;
  return {
    "labels": {
      "CANCEL": "CANCELAR",
      "CHECKOUT_CONTINUE": "CONTINUAR CON EL PAGO",
      "CHOOSE_PASSWORD": "Elija su contraseña",
      "CLOSE": "CERRAR",
      "COMPLETE_YOUR_PROFILE": "COMPLETE SU PERFIL",
      "CONFIRM": "CONFIRMAR",
      "CONFIRM_EMAIL_ADDRESS": "Verifique su cuenta",
      "CONTINUE": "CONTINUAR",
      "PROCEED_TO_CHECKOUT":"CONTINUAR CON EL PAGO",
      "CONTINUE_WITH_GOOGLE": "CONTINUAR CON GOOGLE",
      "CONTINUE_WITH_APPLE": "Continuar con Apple",
      "CONTINUE_WITH_KAKAO": "Continuar con Kakao",
      "CREATE_ACCOUNT": "CREAR CUENTA MY GUCCI",
      "CREATE_PASSWORD": "CREE UNA CONTRASEÑA",
      "COUNTRY": "País de residencia",
      "DATE_OF_BIRTH": "FECHA DE NACIMIENTO",
      "DAY": "Día",
      "EDIT_EMAIL": "Editar correo electrónico",
      "EMAIL": "CORREO ELECTRÓNICO",
      "EMAIL_VALIDATION_RESEND": "¿No lo ha recibido? Solicite el reenvío del correo electrónico.",
      "FOLLOW": "Estado de amistades de Kakao Channel",
      "FOLLOW_ADDED": "Añadido",
      "FOLLOW_BLOCKED": "Bloqueado",
      "FOLLOW_NONE": "Nada",
      "FORGOT_PASSWORD": "¿Ha olvidado su contraseña?",
      "FINISH_REGISTRATION": "FINALICE EL REGISTRO",
      "FIRST_NAME": "NOMBRE",
      "GUEST_USER_CHECKOUT": "Omitir el inicio de sesión",
      "CONTINUE_AS_GUEST": "Continúa como invitado/a",
      "LAST_NAME": "APELLIDOS",
      "MONTH": "Mes",
      "MARKETING_CONSENT_CHECKBOX": "Me gustaría recibir novedades acerca de GUCCI nuevas actividades, productos exclusivos, servicios a medida y tener una experiencia personalizada basada en mis intereses.",
      "MARKETING_CONSENT_CHECKBOX__SA": "Me gustaría recibir novedades acerca de GUCCI nuevas actividades, productos exclusivos, servicios a medida y tener una experiencia personalizada basada en mis intereses.",
      "MARKETING_CONSENT_CHECKBOX__DK": "Me gustaría recibir novedades (incluyendo correo electrónico, mensaje de texto, mensaje multimedia, redes sociales, teléfono, correo postal) acerca de GUCCI nuevas actividades, productos exclusivos, servicios a medida y tener una experiencia personalizada basada en mis intereses.",
      "MARKETING_CONSENT_CHECKBOX__UK": "Me gustaría recibir novedades (incluyendo correo electrónico, mensaje de texto, mensaje multimedia, redes sociales, teléfono, correo postal) acerca de GUCCI nuevas actividades, productos exclusivos, servicios a medida y tener una experiencia personalizada basada en mis intereses.",
      "MARKETING_CONSENT_CHECKBOX__DE": "Me gustaría recibir novedades acerca de GUCCI nuevas actividades, productos exclusivos y servicios a medida y tener una experiencia personalizada basada en mis intereses, y acepto el uso de mi información personal para dicho fin.",
      "DATA_OUTSIDE_COUNTRY_CONSENT_CHECKBOX": `Acepto que mi información personal pueda ser transferida fuera de mi país de residencia, de acuerdo a lo prescripto en la <a href="${baseUrl}/st/privacy-landing" target="_blank">política de privacidad</a>.*`,
      "NEW_PASSWORD": "NUEVA CONTRASEÑA",
      "PASSWORD": "CONTRASEÑA",
      "PHONE": "TELÉFONO",
      "REMEMBER_ME": "Mantener la sesión iniciada",
      "REQUIRED_FIELD": "Campo obligatorio",
      "READ_MORE": "MÁS INFORMACIÓN",
      "RESET_PASSWORD": "Restablecer la contraseña",
      "SHOW_PASSWORD": "Mostrar contraseña",
      "SOCIALID": "ID social",
      "SUBMIT": "ENVIAR",
      "SKIP_SOCIAL": "OMITIR Y CONTINUAR CON EL CORREO ELECTRÓNICO",
      "TITLE": "SELECCIONAR TRATAMIENTO",
      "TITLE_A": "S@",
      "TITLE_MR": "Sr.",
      "TITLE_MRS": "Sra.",
      "TITLE_MS": "Srta.",
      "TITLE_N": "Prefiero no especificarlo",
      "YEAR": "Año",
      "CREATE_MY_PROFILE": "CREAR MI PERFIL",
      "SKIP_LOGIN": "OMITIR POR AHORA",
      "PLACEHOLDER_DD": "DD",
      "PLACEHOLDER_MM": "MM",
      "PLACEHOLDER_YYYY": "AAAA",
      "DOS_SUBMIT": "CREAR UNA CUENTA"
    },
    "messages": {
      "AUTH_ERROR": "La combinación de correo electrónico y contraseña no es válida. Vuelva a intentarlo o haga clic en \"¿Ha olvidado su contraseña?\".",
      "BIRTHDATE_ERROR": "Introduzca una fecha de nacimiento válida",
      "BAA_DESCRIPTION": "Inicie sesión o regístrese en MY GUCCI para agendar una experiencia de compra personalizada con un asesor o una asesora de clientes.",
      "BAA_INFORMATION": "Si ya tiene una cuenta MY GUCCI, el sistema el pedirá que inicie sesión. Si no, puede crear un perfil para proceder con la reserva de la cita.",
      "CHECKOUT_INFORMATION": "Si ya tiene una cuenta MY GUCCI, el sistema el pedirá que inicie sesión. De lo contrario, puede continuar como invitado y, si lo desea, registrarse cuando complete la compra.",
      "CHECKOUT_INFORMATION_CREATE_ACCOUNT": "Inicie sesión con su correo electrónico y contraseña o cree una cuenta si no la tiene.",
      "CHECKOUT_REVIEW_INFORMATION": "Antes de continuar, regístrese en MY GUCCI completando la información para %email%.",
      "COMPLETE_REVIEW_PROFILE": "Complete su perfil para activar su cuenta My Gucci. Optimice su experiencia de compra con colecciones solo para miembros, contenido exclusivo y facilidad a la hora de realizar sus pedidos.",
      "EMAIL_ERROR": "Especifique una dirección de correo electrónico válida",
      "EMAIL_VALIDATION_CONFIRM": "Utilice el enlace para confirmar su dirección de correo electrónico y activar su cuenta My Gucci. Optimice su experiencia de compra con colecciones solo para miembros, contenido exclusivo y facilidad a la hora de realizar sus pedidos.",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "Introduzca sus datos a continuación para crear su cuenta Gucci.",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "Le hemos enviado un nuevo enlace a %email% para verificar su cuenta",
      "FIRST_NAME_ERROR": "Introduzca su nombre",
      "FORGOT_PASSWORD_INFORMATION": "El enlace para restablecer su contraseña se enviará a la siguiente dirección de correo electrónico. Confírmela.",
      "FORGOT_PASSWORD_INSTRUCTION": "Compruebe su correo electrónico e inicie el proceso de restablecimiento de contraseña desde el enlace.",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "Hemos enviado un correo electrónico con instrucciones para restablecer su contraseña en",
      "GENERIC_ERROR": "Lo sentimos, se ha producido un error. Inténtelo de nuevo.",
      "SOCIAL_ACCOUNT_EXISTS": "Se ha registrado con %email%",
      "LAST_NAME_ERROR": "Introduzca su(s) apellido(s)",
      "LOGOUT": "¿Desea realmente cerrar la sesión?",
      "OR": "O",
      "ORDER_ACCOUNT": "Optimice su experiencia de compra con las colecciones disponibles solo para miembros, contenido exclusivo y pedidos fáciles de realizar.",
      "PASSWORD_UPDATED_HTML": "Su contraseña se ha actualizado correctamente.<br />Iniciando sesión.",
      "OVERLAY_RESET_LINK_EXPIRED": "Haga clic en \"¿Ha olvidado su contraseña?\" para solicitar un nuevo enlace de restablecimiento de contraseña.",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "Para recibir un nuevo enlace, haga clic en \"Solicitar un nuevo correo electrónico\"",
      "PASSWORD_CHARACTER_CHECK": "Introduzca al menos 8 caracteres",
      "PASSWORD_NUMBER_CHECK": "Introduzca al menos un número",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "Introduzca un carácter especial (!+,-./:;<=>?@)",
      "PASSWORD_ERROR": "Introduzca una contraseña.",
      "REGISTRATION_AGE_ERROR": "Debe tener al menos %age% años",
      "REGISTRATION_INFORMATION": "Si ya dispone de una cuenta de Gucci, deberá introducir la contraseña.",
      "REVIEW_INFORMATION": "Revise la información de",
      "SAME_PASSWORD_ERROR": "Elija una contraseña diferente a la anterior.",
      "TITLE_ERROR": "Seleccione un Título",
      "LOADER_TITLE": "Su cuenta MY GUCCI se ha completado",
      "LOADER_SUBTITLE": "Ahora se le redirigirá a Gucci.com, donde podrá empezar a explorar el universo digital de la Firma."
    },
    "titles": {
      "ACCOUNT_EXISTS": "Hemos encontrado su cuenta",
      "ACCESS_GUCCI": "Su cuenta MY GUCCI",
      "BAA": "RESERVAR CITA",
      "CHANGING_PASSWORD_FOR": "Cambio de contraseña a %email%",
      "EMAIL_VALIDATION": "Tiene un correo electrónico",
      "SETTING_PASSWORD_FOR": "Cree una nueva contraseña para entrar en su cuenta Gucci %email%",
      "CHECKOUT": "¿Cómo desea completar la compra?",
      "CHECKOUT_REVIEW_PROFILE": "Perfil MY GUCCI",
      "LOGIN_TO_CHECKOUT": "Inicie sesión o complete su compra como invitado",
      "CONTINUE_WITH_EMAIL": "Continúe con su dirección de correo electrónico",
      "USE_YOUR_EMAIL": "Continúe con su correo electrónico",
      "FORGOT_PASSWORD_EMAIL_SENT": "¡Enlace enviado!",
      "PASSWORD_UPDATED": "Contraseña actualizada",
      "OVERLAY_RESET_LINK_EXPIRED": "Su enlace ha caducado",
      "PRIVACY_POLICY": "POLÍTICA DE PRIVACIDAD",
      "DOS_TITLE": "Crear una<br />cuenta MY GUCCI",
      "DOS_SUBTITLE": "establezca una contraseña para "
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "En Gucci nos preocupamos por la seguridad de sus datos. Le hemos enviado un correo electrónico para verificar su identidad.<br/><br/>Haga clic en el correo electrónico para verificar su cuenta y elegir una contraseña.",
      "MARKETING_CONSENT_HTML": `<br />Acepto (o, si no he llegado a la edad legal en mi jurisdicción de residencia, mi tutor legal acepta) que Gucci y Kering utilicen mis datos personales para comunicarse conmigo en relación con mis intereses y hábitos de compra, así como para otros propósitos de marketing, mediante varios canales de comunicación, inclusive a través de comunicaciones electrónicas de marketing, como correos electrónicos, mensajes de texto y redes sociales, tal y como se detalla en la <a href="${baseUrl}/st/privacy-landing" target="_blank">Política de privacidad</a>.`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT_CREATE_MY_GUCCI_ACCOUNT": getPrivacyPolicyTextCreateMyAccountToProceed(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SE": getPrivacyPolicyHTMLSE(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTMLKW(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AT": getPrivacyPolicyHTMLAT(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "value_propositions" : {
      "TITLE1": "UNIRSE A MY GUCCI",
      "TITLE2": "ÚNASE AL UNIVERSO GUCCI",
      "TITLE3": "CONOZCA MEJOR LA FIRMA",
      "VALUE1": {
        "TITLE": "SIGA SUS PEDIDOS",
        "CONTENT": "Siga sus pedidos en cada paso del camino."
      },
      "VALUE2": {
        "TITLE": "AGILICE EL PROCESO DE PAGO",
        "CONTENT": "Realice el pago más rápidamente con las direcciones y los métodos de pago guardados."
      },
      "VALUE3": {
        "TITLE": "RESERVAR CITA",
        "CONTENT": "Disfrute de acceso prioritario a la tienda que prefiera el día y la hora que más le convengan."
      },
      "VALUE4": {
        "TITLE": "DISFRUTE DE ASESORAMIENTO PERSONALIZADO",
        "CONTENT": "Siempre hay un asesor de clientes de Gucci disponible para ofrecer consejos de experto."
      },
      "VALUE5": {
        "TITLE": "ENTÉRESE ANTES QUE NADIE",
        "CONTENT": "Disfrute de la newsletter de Gucci y de las recomendaciones personalizadas."
      },
      "VALUE8": {
        "TITLE": "EXPERIENCIA PERSONALIZADA",
        "CONTENT": "Desplácese fácilmente por el universo Gucci alternando entre la tienda y la experiencia online."
      },
    }
  };
}

function mexicanSpanish(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) => `Al seleccionar «Crear mi perfil», acepta nuestras <a href="${LINK2}" target="_blank">Condiciones de uso</a>, nuestra <a href="${LINK1}" target="_blank">política de privacidad</a> y que desea crear su perfil de GUCCI.`;
  const getPrivacyPolicyTextCreateMyAccountToProceed = LINK => `Eligiendo "Crear cuenta my GUCCI", confirma que ha leído y entendido nuestra <a href="${LINK}" target="_blank">política de privacidad</a>, y que desea crear su perfil GUCCI.`;
  const getPrivacyPolicyTextCountry = LINK => `Eligiendo "Crear mi perfil", confirma que acepta nuestra <a href="${LINK}" target="_blank">política de privacidad</a>, y que desea crear su perfil GUCCI.`;
  const getPrivacyPolicyHTML = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
                                <br />
                                Está a punto de crear su perfil GUCCI. Esto permitirá a GUCCI ofrecerle una experiencia personalizada y a medida, proporcionarle con productos, servicios e información que usted solicite de GUCCI y comunicarse con usted. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Podemos usar cualquier información de contacto que haya compartido con nosotros para contactarle. <br />
                                <br />
                                Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
        `;
    const getPrivacyPolicyHTMLCountry = LINK => `Le invitamos a leer la <a href="${LINK}" target="_blank">política de privacidad</a> en su totalidad. En cualquier caso, aquí siguen varios puntos clave que nos gustaría compartir con usted:<br />
                                    <br />
                                    Está a punto de crear su perfil GUCCI. GUCCI usará su perfil para proporcionarle productos, servicios e información que usted compre o solicite por parte de GUCCI, ofrecerle asistencia personalizada y servicio de atención al cliente, enviarle comunicaciones comerciales personalizadas, llevar a cabo nuestro negocio de forma segura y de acuerdo con nuestras obligaciones, y optimizar y mejorar nuestros servicios. Toda su información personal será guardada, usada y vinculada a su perfil GUCCI. Su perfil puede incluir información que hemos obtenido directamente de usted así como de nuestros minoristas y otros socios comerciales. Como operamos a nivel global, podemos compartir su información personal de forma segura con compañías de nuestro grupo y nuestros socios autorizados, localizados en todo el mundo. Implementaremos medidas de seguridad para proteger su información personal. Podemos usar cualquier información de contacto que haya compartido con nosotros para contactarle. Al crear su perfil GUCCI, confirma haber leído, entendido y acepta nuestra <a href="${baseUrl}/st/privacy-landing" target="_blank">política de privacidad</a>.<br />
                                    <br />
                                    Las leyes en materia de privacidad y protección de datos pueden otorgarle ciertos derechos como derecho a acceder, borrar, modificar y rectificar su información, o restringir u oponerse al procesamiento, así como al derecho a portabilidad de datos. También puede presentar una reclamación con su regulador competente. Puede retirar su consentimiento o eliminar su perfil en cualquier momento. Para mayor información acerca de nuestras prácticas en privacidad y sus derechos, por favor contáctenos en <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `Al seleccionar 'continuar' en la parte inferior, acepta los términos de la <a href="${LINK}" target="_blank">política de privacidad</a>.`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">política de privacidad</a>`;
  return {
    "labels": {
      "CANCEL": "CANCELAR",
      "CHECKOUT_CONTINUE": "CONTINUAR CON EL PAGO",
      "CHOOSE_PASSWORD": "Elija su contraseña",
      "CLOSE": "CERRAR",
      "COMPLETE_YOUR_PROFILE": "COMPLETE SU PERFIL",
      "CONFIRM": "CONFIRMAR",
      "CONFIRM_EMAIL_ADDRESS": "Verifique su cuenta",
      "CONTINUE": "CONTINUAR",
      "PROCEED_TO_CHECKOUT":"CONTINUAR CON EL PAGO",
      "CONTINUE_WITH_GOOGLE": "CONTINUAR CON GOOGLE",
      "CONTINUE_WITH_APPLE": "CONTINUAR CON APPLE",
      "CONTINUE_WITH_KAKAO": "CONTINUAR CON KAKAO",
      "CREATE_ACCOUNT": "CREAR CUENTA MY GUCCI",
      "CREATE_PASSWORD": "CREAR CONTRASEÑA",
      "COUNTRY": "País de residencia",
      "DATE_OF_BIRTH": "FECHA DE NACIMIENTO",
      "DAY": "Día",
      "EDIT_EMAIL": "Editar correo electrónico",
      "EMAIL": "CORREO ELECTRÓNICO",
      "EMAIL_VALIDATION_RESEND": "¿No lo ha recibido? Solicite el reenvío del correo electrónico.",
      "FOLLOW": "Estado de amistad de Kakao Channel",
      "FOLLOW_ADDED": "Agregada",
      "FOLLOW_BLOCKED": "Bloqueado",
      "FOLLOW_NONE": "Ninguno",
      "FORGOT_PASSWORD": "¿Olvidó su contraseña?",
      "FINISH_REGISTRATION": "FINALICE EL REGISTRO",
      "FIRST_NAME": "NOMBRE",
      "GUEST_USER_CHECKOUT": "Omitir el inicio de sesión",
      "CONTINUE_AS_GUEST": "Continue as guest",
      "LAST_NAME": "APELLIDO",
      "MONTH": "Mes",
      "MARKETING_CONSENT_CHECKBOX": "Me gustaría recibir actualizaciones sobre las nuevas actividades de GUCCI, los productos exclusivos, los servicios a medida y tener una experiencia de cliente personalizada basada en mis intereses.",
      "MARKETING_CONSENT_CHECKBOX__DE": "Me gustaría recibir novedades acerca de GUCCI nuevas actividades, productos exclusivos y servicios a medida y tener una experiencia personalizada basada en mis intereses, y acepto el uso de mi información personal para dicho fin.",
      "NEW_PASSWORD": "NUEVA CONTRASEÑA",
      "PASSWORD": "CONTRASEÑA",
      "PHONE": "TELÉFONO",
      "REMEMBER_ME": "Permanezca conectado",
      "REQUIRED_FIELD": "Campo obligatorio",
      "READ_MORE": "LEER MÁS",
      "RESET_PASSWORD": "Restablecer la contraseña",
      "SHOW_PASSWORD": "Mostrar contraseña",
      "SOCIALID": "Identificación social del servicio",
      "SUBMIT": "ENVIAR",
      "SKIP_SOCIAL": "OMITIR Y CONTINUAR CON EL CORREO ELECTRÓNICO",
      "TITLE": "SELECCIONAR TRATAMIENTO",
      "TITLE_A": "Sx.",
      "TITLE_MR": "Sr.",
      "TITLE_MRS": "Sra.",
      "TITLE_MS": "Srta.",
      "TITLE_N": "Prefiero no decirlo",
      "YEAR": "Año",
      "CREATE_MY_PROFILE": "CREAR MI PERFIL",
      "SKIP_LOGIN": "OMITIR POR AHORA",
      "PLACEHOLDER_DD": "DD",
      "PLACEHOLDER_MM": "MM",
      "PLACEHOLDER_YYYY": "AAAA",
      "DOS_SUBMIT": "CREAR UNA CUENTA"
    },
    "messages": {
      "AUTH_ERROR": "La combinación de correo electrónico y contraseña no es válida. Vuelva a intentarlo o haga clic en \"¿Ha olvidado su contraseña?\".",
      "BIRTHDATE_ERROR": "Ingrese una fecha de nacimiento válida",
      "BAA_DESCRIPTION": "Inicie sesión o regístrese para agendar una experiencia de compra personalizada con uno de nuestros Client Advisors.",
      "BAA_INFORMATION": "Continúe con su correo electrónico y su contraseña o cree un perfil si no lo tiene.",
      "CHECKOUT_INFORMATION": "Si ya tiene una cuenta MY GUCCI, se le pedirá que inicie sesión. Si no es así, puede continuar como invitado y elegir registrarse después de pagar.",
      "CHECKOUT_INFORMATION_CREATE_ACCOUNT": "Inicie sesión con su correo electrónico y contraseña o cree una cuenta si no la tiene.",
      "CHECKOUT_REVIEW_INFORMATION": "Antes de continuar, regístrese en MY GUCCI completando la información para %email%.",
      "COMPLETE_REVIEW_PROFILE": "Complete su perfil para activar su cuenta My Gucci. Optimice su experiencia de compra con colecciones solo para miembros, contenido exclusivo y facilidad a la hora de realizar sus pedidos.",
      "EMAIL_ERROR": "Ingrese una dirección de correo electrónico válida",
      "EMAIL_VALIDATION_CONFIRM": "Utilice el enlace para confirmar su dirección de correo electrónico y activar su cuenta My Gucci. Optimice su experiencia de compra con colecciones solo para miembros, contenido exclusivo y facilidad a la hora de realizar sus pedidos.",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "Introduzca sus datos a continuación para crear su cuenta Gucci.",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "Le hemos enviado un nuevo enlace a %email% para verificar su cuenta",
      "FIRST_NAME_ERROR": "Ingrese su nombre",
      "FORGOT_PASSWORD_INFORMATION": "El enlace para restablecer su contraseña se enviará a la siguiente dirección de correo electrónico. Confírmela.",
      "FORGOT_PASSWORD_INSTRUCTION": "Compruebe su correo electrónico e inicie el proceso de restablecimiento de contraseña desde el enlace.",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "Hemos enviado un correo electrónico con instrucciones para restablecer su contraseña en",
      "GENERIC_ERROR": "Lo sentimos, algo salió mal. Inténtelo nuevamente.",
      "SOCIAL_ACCOUNT_EXISTS": "Se ha registrado con %email%",
      "LAST_NAME_ERROR": "Ingrese su apellido",
      "LOGOUT": "¿Desea realmente cerrar la sesión?",
      "OR": "O",
      "ORDER_ACCOUNT": "Optimice su experiencia de compra con las colecciones disponibles solo para miembros, contenido exclusivo y pedidos fáciles de realizar.",
      "PASSWORD_UPDATED_HTML": "Su contraseña se ha actualizado correctamente.<br />Iniciando sesión.",
      "OVERLAY_RESET_LINK_EXPIRED": "Haga clic en \"¿Ha olvidado su contraseña?\" para solicitar un nuevo enlace de restablecimiento de contraseña.",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "Para recibir un nuevo enlace, haga clic en \"Solicitar un nuevo correo electrónico\"",
      "PASSWORD_CHARACTER_CHECK": "Ingrese al menos 8 caracteres",
      "PASSWORD_NUMBER_CHECK": "Ingrese al menos un número",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "Ingrese un carácter especial (!+,-./:;<=>?@)",
      "PASSWORD_ERROR": "Ingrese una contraseña",
      "REGISTRATION_AGE_ERROR": "Debe tener %age% años o más",
      "REGISTRATION_INFORMATION": "Si ya se ha registrado con nosotros, se le solicitará su contraseña.",
      "REVIEW_INFORMATION": "Revise la información de",
      "SAME_PASSWORD_ERROR": "Elija una contraseña diferente a la anterior.",
      "TITLE_ERROR": "Seleccione un Título",
      "LOADER_TITLE": "Su cuenta de MY GUCCI está completa",
      "LOADER_SUBTITLE": "Ahora, se le dirigirá a Gucci.com, donde podrá comenzar a explorar el universo digital de la Firma."
    },
    "titles": {
      "ACCOUNT_EXISTS": "Hemos encontrado su cuenta",
      "ACCESS_GUCCI": "Cuenta MY GUCCI",
      "BAA": "RESERVAR CITA",
      "CHANGING_PASSWORD_FOR": "Cambio de contraseña a %email%",
      "EMAIL_VALIDATION": "Tiene un correo electrónico",
      "SETTING_PASSWORD_FOR": "Cree una nueva contraseña para entrar en su cuenta Gucci %email%",
      "CHECKOUT": "¿Cómo desea completar la compra?",
      "CHECKOUT_REVIEW_PROFILE": "Perfil MY GUCCI",
      "LOGIN_TO_CHECKOUT": "Inicie sesión o realice el pago como invitado",
      "CONTINUE_WITH_EMAIL": "Continúe con su dirección de correo electrónico",
      "USE_YOUR_EMAIL": "Continúe con su correo electrónico",
      "FORGOT_PASSWORD_EMAIL_SENT": "¡Enlace enviado!",
      "PASSWORD_UPDATED": "Contraseña actualizada",
      "OVERLAY_RESET_LINK_EXPIRED": "Su enlace ha caducado",
      "PRIVACY_POLICY": "POLÍTICA DE PRIVACIDAD",
      "DOS_TITLE": "Cree una<br />cuenta de MY GUCCI",
      "DOS_SUBTITLE": "establezca una contraseña para "
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "En Gucci nos preocupamos por la seguridad de sus datos. Le hemos enviado un correo electrónico para verificar su identidad.<br/><br/>Haga clic en el correo electrónico para verificar su cuenta y elegir una contraseña.",
      "MARKETING_CONSENT_HTML": `<br />Acepto (o, si no he llegado a la edad legal en mi jurisdicción de residencia, mi tutor legal acepta) que Gucci y Kering utilicen mis datos personales para comunicarse conmigo en relación con mis intereses y hábitos de compra, así como para otros propósitos de marketing, mediante varios canales de comunicación, inclusive a través de comunicaciones electrónicas de marketing, como correos electrónicos, mensajes de texto y redes sociales, tal y como se detalla en la <a href="${baseUrl}/st/privacy-landing" target="_blank">Política de privacidad</a>.`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT_CREATE_MY_GUCCI_ACCOUNT": getPrivacyPolicyTextCreateMyAccountToProceed(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "carousel": {
      "VALUE_PROPOSITION_TITLE": "Acérquese a la Firma con MY GUCCI",
      "VALUE_PROPOSITION_1": "Acceso prioritario a colecciones seleccionadas.",
      "VALUE_PROPOSITION_2": "Consejos personalizados de un Asesor de clientes que conoce sus intereses.",
      "VALUE_PROPOSITION_3": "Una selección exclusiva de recomendaciones solo para usted."
    },
    "value_propositions" : {
      "TITLE1": "ÚNASE A MY GUCCI",
      "TITLE2": "ÚNASE AL UNIVERSO GUCCI",
      "TITLE3": "ACÉRQUESE A LA FIRMA",
      "VALUE1": {
        "TITLE": "REALICE SEGUIMIENTO DE LOS PEDIDOS",
        "CONTENT": "Siga sus pedidos en cada paso del camino."
      },
      "VALUE2": {
        "TITLE": "AGILICE EL PROCESO DE COMPRA",
        "CONTENT": "Realice compras más rápido con direcciones y métodos de pago guardados."
      },
      "VALUE3": {
        "TITLE": "AGENDAR UNA CITA",
        "CONTENT": "Disfrute de acceso prioritario a su boutique preferida en la fecha y hora que más le convenga."
      },
      "VALUE4": {
        "TITLE": "DISFRUTE DE CONSEJOS PERSONALIZADOS",
        "CONTENT": "Un asesor de clientes de Gucci siempre está disponible con sugerencias de expertos."
      },
      "VALUE5": {
        "TITLE": "SEA EL PRIMERO EN ENTERARSE",
        "CONTENT": "Disfrute del boletín informativo Gucci y recomendaciones personalizadas."
      },
      "VALUE8": {
        "TITLE": "UNA EXPERIENCIA PERSONALIZADA",
        "CONTENT": "Ya sea en las tiendas físicas o en línea, muévase sin problemas por el universo Gucci."
      },
    }
  };
}

function french(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) => `En choisissant “Créer mon profil”, vous confirmez que vous acceptez nos <a href=\"${LINK2}\" target="_blank">Conditions d’utilisation</a>, que vous avez lu et compris notre <a href=\"${LINK1}\" target="_blank">Politique de confidentialité</a>, et que vous souhaitez créer votre profil GUCCI.`;
  const getPrivacyPolicyTextCreateMyAccountToProceed = LINK => `En choisissant “Créer un compte my GUCCI”, vous confirmez avoir lu et compris notre <a href=\"${LINK}\" target="_blank">politique de confidentialité</a>, et vouloir créer votre profil GUCCI.`;
  const getPrivacyPolicyTextCountry = (LINK1, LINK2) => `En choisissant “Créer mon profil”, vous confirmez que vous acceptez nos <a href=\"${LINK2}\" target="_blank">Conditions d’utilisation</a> et notre <a href=\"${LINK1}\" target="_blank">Politique de confidentialité</a>, et que vous souhaitez créer votre profil GUCCI.`;
  const getPrivacyPolicyHTML = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels :<br />
                              <br />
                              Vous êtes sur le point de créer votre profil GUCCI. Ce profil permettra à GUCCI de vous proposer une expérience personnalisée et sur mesure, de vous fournir les produits, services et renseignements que vous demandez à GUCCI, et de communiquer avec vous. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter. <br />
                              <br />
                              Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLAT = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels :<br />
      <br />
      Vous êtes sur le point de créer votre profil GUCCI. Ce profil permettra à GUCCI de vous proposer une expérience personnalisée et sur mesure, de vous fournir les produits, services et renseignements que vous demandez à GUCCI, et de communiquer avec vous. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter (y compris par téléphone, si vous nous communiquez votre numéro).   <br />
      <br />
      Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;

  const getPrivacyPolicyHTMLSE = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels :<br />
                            <br />
                            Vous êtes sur le point de créer votre profil GUCCI. Ce profil permettra à GUCCI de vous proposer une expérience personnalisée et sur mesure, de vous fournir les produits, services et renseignements que vous demandez à GUCCI, et de communiquer avec vous. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Lorsque vous créez un profil GUCCI nous conservons généralement vos données personnelles pendant une durée de 5 ans à compter de la dernière interaction avec nous dont vous avez été à l’initiative. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter. <br />
                            <br />
                            Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
                            `;
  const getPrivacyPolicyHTMLQA = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels:<br />
                                  <br />
                                  Vous êtes sur le point de créer votre profil GUCCI. GUCCI utilisera votre profil pour vous fournir les produits, les services et les informations que vous achetez ou demandez à GUCCI, pour vous proposer un service client et une assistance personnalisés, pour vous envoyer des communications promotionnelles personnalisées, pour exercer nos activités en toute sécurité et conformément à nos obligations, et pour optimiser et améliorer nos services. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter. En créant votre profil GUCCI, vous confirmez que vous avez lu et compris et que vous acceptez notre <a href="${baseUrl}/st/privacy-landing" target="_blank">politique de confidentialité</a>. <br />
                                  <br />
                                  Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;

  const getPrivacyPolicyHTMLKW = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels:<br />
      <br />
      Vous êtes sur le point de créer votre profil GUCCI. Ce profil permettra à GUCCI de vous proposer une expérience personnalisée et sur mesure, de vous fournir les produits, services et renseignements que vous demandez à GUCCI, et de communiquer avec vous. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter. En créant votre profil GUCCI, vous confirmez que vous avez lu et compris et que vous acceptez notre <a href="${baseUrl}/st/privacy-landing" target="_blank">politique de confidentialité</a>. <br />
      <br />
      Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous avez le droit de consentir ou de ne pas consentir à la collecte et au traitement de vos données personnelles. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;
  const getPrivacyPolicyHTMLCountry = LINK => `Vous êtes invité à lire notre <a href="${LINK}" target="_blank">politique de confidentialité</a> dans son intégralité. Quoi qu’il en soit, nous souhaitons vous rappeler ci-dessous quelques points essentiels:<br />
                                  <br />
                                  Vous êtes sur le point de créer votre profil GUCCI. Ce profil permettra à GUCCI de vous proposer une expérience personnalisée et sur mesure, de vous fournir les produits, services et renseignements que vous demandez à GUCCI, et de communiquer avec vous. Toutes vos données personnelles seront conservées, utilisées, et rattachées à votre profil GUCCI. Votre profil pourra comprendre des informations que nous avons obtenues directement auprès de vous, ainsi que des informations obtenues auprès de nos revendeurs et autres partenaires commerciaux. En tant qu’entreprise exerçant ses activités à l’international, nous pourrons être amenés à partager vos données personnelles, de manière sécurisée, avec des sociétés de notre groupe et avec nos partenaires agréés, situés dans le monde entier. Nous mettrons en œuvre des garanties afin de protéger vos données personnelles. Nous pourrons utiliser toute information de contact que vous nous fournissez pour vous contacter. En créant votre profil GUCCI, vous confirmez que vous avez lu et compris et que vous acceptez notre <a href="${baseUrl}/st/privacy-landing" target="_blank">politique de confidentialité</a>. <br />
                                  <br />
                                  Les réglementations et législations relatives à la protection des données personnelles peuvent vous conférer certains droits, tels que les droits d’accès, de suppression, de modification et de rectification de vos données, le droit à la limitation du traitement, le droit d’opposition au traitement, ou encore le droit à la portabilité de vos données. Vous avez également la possibilité d’introduire une réclamation auprès de l’autorité de contrôle compétente. Vous pouvez, à tout moment, retirer votre consentement ou supprimer votre profil. Pour plus de renseignements concernant nos pratiques en matière de respect de la vie privée ou concernant vos droits, vous pouvez nous contacter par e-mail à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `En choisissant de «continuer» ci-dessous, vous acceptez les conditions de notre <a href="${LINK}" target="_blank">politique de confidentialité</a>.`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">politique de confidentialité</a>`;

  return {
    "labels": {
      "CANCEL": "ANNULER",
      "CHANGE_LANGUAGE": "CHANGE LANGUAGE",
      "CHECKOUT_CONTINUE": "PROCÉDER AU PAIEMENT",
      "CHOOSE_PASSWORD": "Choisissez votre mot de passe",
      "CLOSE": "FERMER",
      "COMPLETE_YOUR_PROFILE": "FINALISER VOTRE PROFIL",
      "CONFIRM": "CONFIRMER",
      "CONFIRM_EMAIL_ADDRESS": "Vérifiez votre compte",
      "CONTINUE": "CONTINUER",
      "PROCEED_TO_CHECKOUT": "PROCÉDER AU PAIEMENT",
      "CONTINUE_WITH_GOOGLE": "CONTINUER AVEC GOOGLE",
      "CONTINUE_WITH_APPLE": "Continuer avec Apple",
      "CONTINUE_WITH_KAKAO": "Continuer avec Kakao",
      "CREATE_ACCOUNT": "CRÉER UN COMPTE MY GUCCI",
      "CREATE_PASSWORD": "CRÉER UN MOT DE PASSE",
      "COUNTRY": "Pays de résidence",
      "DATE_OF_BIRTH": "DATE DE NAISSANCE",
      "DAY": "Jour",
      "EDIT_EMAIL": "Modifier l’adresse e-mail",
      "EMAIL": "E-MAIL",
      "EMAIL_VALIDATION_RESEND": "Vous ne l’avez pas reçu? Demandez un nouvel e-mail.",
      "FINISH_REGISTRATION": "TERMINER L’INSCRIPTION",
      "FIRST_NAME": "PRÉNOM",
      "FOLLOW": "Statut des amis de la chaîne Kakao",
      "FOLLOW_ADDED": "Ajouté",
      "FOLLOW_BLOCKED": "Bloqué",
      "FOLLOW_NONE": "Aucune",
      "FORGOT_PASSWORD": "Mot de passe oublié ?",
      "GUEST_USER_CHECKOUT": "Passer l’étape de connexion",
      "CONTINUE_AS_GUEST": "Continuer en tant que visiteur",
      "LAST_NAME": "NOM",
      "MONTH": "Mois",
      "MARKETING_CONSENT_CHECKBOX": "Je souhaite recevoir des informations sur les nouvelles activités, les produits exclusifs, et les services personnalisés de GUCCI, et souhaite que mon expérience soit personnalisée en fonction de mes centres d’intérêt.",
      "MARKETING_CONSENT_CHECKBOX__SA": "Je souhaite recevoir des informations sur les nouvelles activités, les produits exclusifs, et les services personnalisés de GUCCI, et souhaite que mon expérience soit personnalisée en fonction de mes centres d’intérêt.",
      "MARKETING_CONSENT_CHECKBOX__DK": "Je souhaite recevoir des informations (y compris par email, par SMS ou MMS, par les réseaux sociaux, par téléphone, par courrier) sur les nouvelles activités, les produits exclusifs, et les services personnalisés de GUCCI, et souhaite que mon expérience soit personnalisée en fonction de mes centres d’intérêt.",
      "MARKETING_CONSENT_CHECKBOX__UK": "Je souhaite recevoir des informations (y compris par email, par SMS ou MMS, par les réseaux sociaux, par téléphone, par courrier) sur les nouvelles activités, les produits exclusifs, et les services personnalisés de GUCCI, et souhaite que mon expérience soit personnalisée en fonction de mes centres d’intérêt.",
      "MARKETING_CONSENT_CHECKBOX__DE": "Je souhaite recevoir des informations sur les nouvelles activités, les produits exclusifs, et les services personnalisés de GUCCI et que mon expérience soit personnalisée en fonction de mes centres d’intérêt, et accepte que mes données personnelles soient utilisées pour cette finalité.",
      "DATA_OUTSIDE_COUNTRY_CONSENT_CHECKBOX": `J’accepte que mes données personnelles soient transférées hors de mon pays de résidence, tel que décrit dans la <a href="${baseUrl}/st/privacy-landing" target="_blank">politique de confidentialité</a>.*`,
      "NEW_PASSWORD": "NOUVEAU MOT DE PASSE",
      "PASSWORD": "MOT DE PASSE",
      "PHONE": "TÉLÉPHONE",
      "REMEMBER_ME": "Rester connecté(e)",
      "READ_MORE": "EN SAVOIR PLUS",
      "REQUIRED_FIELD": "Champ obligatoire",
      "RESET_PASSWORD": "Réinitialiser Le Mot De Passe",
      "SHOW_PASSWORD": "Afficher le mot de passe",
      "SOCIALID": "ID de service social",
      "SUBMIT": "VALIDER",
      "SKIP_SOCIAL": "PASSER ET CONTINUER PAR E-MAIL",
      "TITLE": "SÉLECTIONNEZ LE TITRE",
      "TITLE_A": "Mx",
      "TITLE_MR": "M.",
      "TITLE_MRS": "Mme",
      "TITLE_MS": "Mlle",
      "TITLE_N": "Je préfère ne pas répondre",
      "YEAR": "Année",
      "CREATE_MY_PROFILE": "Créer mon profil",
      "SKIP_LOGIN": "IGNORER POUR L’INSTANT",
      "PLACEHOLDER_DD": "JJ",
      "PLACEHOLDER_MM": "MM",
      "PLACEHOLDER_YYYY": "AAAA",
      "DOS_SUBMIT": "CRÉER UN COMPTE"
    },
    "messages": {
      "AUTH_ERROR": "La combinaison adresse e-mail et mot de passe est incorrecte. Veuillez réessayer ou cliquez sur « Mot de passe oublié ? ».",
      "BIRTHDATE_ERROR": "Merci de saisir une date de naissance valide",
      "BAA_DESCRIPTION": "Créez un compte MY GUCCI ou connectez-vous à votre compte existant pour planifier une expérience d’achat personnalisée avec un conseiller clientèle dédié.",
      "BAA_INFORMATION": "Si vous disposez déjà d’un compte MY GUCCI, vous serez invité à vous connecter. Si ce n’est pas le cas, vous pouvez créer un profil pour continuer la prise de rendez-vous.",
      "CHECKOUT_INFORMATION": "Si vous disposez déjà d’un compte MY GUCCI, vous serez invité à vous connecter. Si ce n’est pas le cas, vous pouvez continuer en tant qu’invité et choisir de vous inscrire après le paiement.",
      "CHECKOUT_INFORMATION_CREATE_ACCOUNT": "Connectez-vous à l’aide de votre adresse e-mail et votre mot de passe ou créez un compte si vous êtes nouveau venu.",
      "CHECKOUT_REVIEW_INFORMATION": "Avant de continuer, veuillez vous inscrire sur MY GUCCI en fournissant les informations pour l’adresse %email%.",
      "COMPLETE_REVIEW_PROFILE": "Veuillez finaliser votre profil pour activer votre compte My Gucci. Optimisez votre expérience d’achat avec des collections réservées aux membres, du contenu exclusif et des commandes facilitées.",
      "EMAIL_ERROR": "Veuillez saisir une adresse électronique valide",
      "EMAIL_VALIDATION_CONFIRM": "Veuillez utiliser le lien pour confirmer votre adresse e-mail afin d’activer votre compte My Gucci. Optimisez votre expérience d’achat avec des collections réservées aux membres, du contenu exclusif et des commandes facilitées.",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "Veuillez ajouter vos coordonnées ci-dessous pour créer votre compte Gucci.",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "Nous vous avons envoyé un nouveau lien pour vérifier votre compte à l’adresse %email%",
      "FIRST_NAME_ERROR": "Veuillez indiquer votre prénom",
      "FORGOT_PASSWORD_INFORMATION": "Le lien permettant de réinitialiser votre mot de passe sera envoyé à l’adresse e-mail suivante. Veuillez confirmer.",
      "FORGOT_PASSWORD_INSTRUCTION": "Veuillez ouvrir l’e-mail et lancer le processus de réinitialisation du mot de passe à partir du lien.",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "Nous avons envoyé un e-mail contenant des instructions pour réinitialiser votre mot de passe à l’adresse",
      "GENERIC_ERROR": "Nous sommes désolés, un problème est survenu. Veuillez réessayer.",
      "SOCIAL_ACCOUNT_EXISTS": "Votre identifiant est %email%",
      "LAST_NAME_ERROR": "Veuillez indiquer votre nom de famille",
      "LOGOUT": "Souhaitez-vous vraiment vous déconnecter?",
      "OR": "OU",
      "ORDER_ACCOUNT": "Optimisez votre expérience d’achat en profitant de collections réservées aux membres, de contenu exclusif et de commandes faciles.",
      "PASSWORD_UPDATED_HTML": "Votre mot de passe a bien été mis à jour.<br />Connexion en cours.",
      "OVERLAY_RESET_LINK_EXPIRED": "Veuillez cliquer sur « Mot de passe oublié ? » pour demander un nouveau lien de réinitialisation.",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "Pour recevoir un nouveau lien, veuillez cliquer sur « Demandez un nouvel e-mail ».",
      "PASSWORD_CHARACTER_CHECK": "Veuillez saisir au moins 8 caractères",
      "PASSWORD_NUMBER_CHECK": "Veuillez saisir au moins un chiffre",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "Veuillez saisir au moins un caractère spécial (!+,-./:;<=>?@)",
      "PASSWORD_ERROR": "Veuillez saisir un mot de passe",
      "REGISTRATION_AGE_ERROR": "Vous devez être âgé(e) de %age% ans ou plus",
      "REGISTRATION_INFORMATION": "Si vous avez déjà un compte Gucci, votre mot de passe vous sera demandé.",
      "REVIEW_INFORMATION": "Veuillez consulter les informations concernant",
      "SAME_PASSWORD_ERROR": "Veuillez choisir un mot de passe différent du précédent.",
      "TITLE_ERROR": "Veuillez sélectionner une civilité",
      "LOADER_TITLE": "Votre compte MY GUCCI est prêt",
      "LOADER_SUBTITLE": "Nous allons maintenant vous rediriger vers Gucci.com, où vous pourrez commencer à explorer l’univers numérique de la Maison."
    },
    "titles": {
      "ACCOUNT_EXISTS": "Nous avons identifié votre compte",
      "ACCESS_GUCCI": "Votre compte MY GUCCI",
      "BAA": "PRENDRE RENDEZ-VOUS",
      "CHANGING_PASSWORD_FOR": "Mot de passe en cours de modification pour %email%",
      "EMAIL_VALIDATION": "Vous avez du courrier",
      "SETTING_PASSWORD_FOR": "Créez un nouveau mot de passe pour accéder au compte Gucci lié à l’adresse %email%",
      "CHECKOUT": "Comment souhaitez-vous payer ?",
      "CHECKOUT_REVIEW_PROFILE": "MON PROFIL GUCCI",
      "LOGIN_TO_CHECKOUT": "Se connecter ou passer au paiement en tant qu’invité",
      "CONTINUE_WITH_EMAIL": "Continuer avec votre adresse e-mail",
      "USE_YOUR_EMAIL": "Continuer avec votre adresse e-mail",
      "FORGOT_PASSWORD_EMAIL_SENT": "Lien envoyé !",
      "PASSWORD_UPDATED": "Mot de passe mis à jour",
      "OVERLAY_RESET_LINK_EXPIRED": "Votre lien a expiré",
      "PRIVACY_POLICY": "POLITIQUE DE CONFIDENTIALITÉ",
      "DOS_TITLE": "Créer un<br />compte MY GUCCI",
      "DOS_SUBTITLE": "définir un mot de passe pour "
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "Chez Gucci, nous tenons à préserver la sécurité de vos données. Nous avons vous envoyé un e-mail pour vérifier votre identité.<br/><br/>Veuillez cliquer sur le lien contenu dans l’e-mail pour vérifier votre compte et choisir un mot de passe.",
      "MARKETING_CONSENT_FULL_HTML": `<br />J’accepte (ou, si je n’ai pas atteint l’âge du consentement dans mon pays de résidence, le détenteur de l’autorité parentale accepte) que mes données personnelles soient utilisées par Gucci et Kering, à des fins de communication relative à mes intérêts et habitudes d’achat et à d’autres fins de marketing, par le biais de divers canaux de communication, y compris par le biais de communications marketing électroniques, conformément à la <a href="${baseUrl}/st/privacy-landing" target="_blank">Politique de confidentialité</a>.
                                            <br />
                                            <br />
                                            Je peux retirer mon consentement à tout moment en écrivant à l’adresse <a href="mailto:privacy@gucci.com">privacy@gucci.com</a> ou en utilisant les fonctions de désabonnement ou de désinscription incluses dans ces communications. Je comprends que les SMS à visée marketing peuvent être envoyés manuellement ou à l’aide d’un automate d’appel et que le fait d’accepter de recevoir des SMS automatiques ne constitue pas une condition d’achat. Je reconnais savoir que je peux me désinscrire de la liste de diffusion des SMS à visée marketing à tout moment et que des frais relatifs aux SMS et à l’utilisation de données peuvent s’appliquer.`,
      "MARKETING_CONSENT_HTML": `<br />J’accepte (ou, si je n’ai pas atteint l’âge du consentement dans mon pays de résidence, le détenteur de l’autorité parentale accepte) que mes données personnelles soient utilisées par Gucci et Kering, à des fins de communication relative à mes intérêts et habitudes d’achat et à d’autres fins de marketing, par le biais de divers canaux de communication, y compris par le biais de communications marketing électroniques, conformément à la <a href="${baseUrl}/st/privacy-landing" target="_blank">Politique de confidentialité</a>.`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT_CREATE_MY_GUCCI_ACCOUNT": getPrivacyPolicyTextCreateMyAccountToProceed(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AT": getPrivacyPolicyHTMLAT(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SE": getPrivacyPolicyHTMLSE(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLQA(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTMLKW(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SA": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AE": getPrivacyPolicyHTMLCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "carousel": {
      "VALUE_PROPOSITION_TITLE": "Rapprochez-vous de la maison avec MY GUCCI",
      "VALUE_PROPOSITION_1": "Un accès prioritaire à certaines collections.",
      "VALUE_PROPOSITION_2": "Les conseils personnalisés d’un conseiller clientèle qui connaît vos goûts.",
      "VALUE_PROPOSITION_3": "Une sélection d’articles pensée pour vous."
    },
    "value_propositions" : {
      "TITLE1": "REJOIGNEZ MY GUCCI",
      "TITLE2": "REJOIGNEZ LA COMMUNAUTÉ GUCCI",
      "TITLE3": "LA MAISON S’OUVRE À VOUS",
      "VALUE1": {
        "TITLE": "SUIVEZ VOS COMMANDES",
        "CONTENT": "Suivez le parcours de vos commandes à chaque étape."
      },
      "VALUE2": {
        "TITLE": "UN PROCESSUS DE PAIEMENT PLUS SIMPLE",
        "CONTENT": "Finalisez vos achats plus rapidement en enregistrant vos adresses et vos modes de paiement."
      },
      "VALUE3": {
        "TITLE": "PRENEZ RENDEZ-VOUS",
        "CONTENT": "Profitez d’un accès prioritaire à la boutique de votre choix à l’heure et à la date qui vous conviennent."
      },
      "VALUE4": {
        "TITLE": "PROFITEZ DE CONSEILS AVISÉS",
        "CONTENT": "Un conseiller clientèle Gucci spécialisé se tient à votre disposition pour vous conseiller."
      },
      "VALUE5": {
        "TITLE": "FAITES PARTIE DES PRIVILÉGIÉS",
        "CONTENT": "Recevez la newsletter Gucci et profitez de recommandations personnalisées."
      },
      "VALUE8": {
        "TITLE": "UNE EXPÉRIENCE SUR MESURE",
        "CONTENT": "Des boutiques physiques à notre boutique en ligne, accédez à tout l’univers Gucci."
      },
    }
  };
}

function italian(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) =>        `Selezionando "Creazione profilo", conferma di accettare i nostri <a href=\"${LINK2}\" target="_blank">Termini di utilizzo</a>, di avere letto e compreso la nostra <a href=\"${LINK1}\" target=\"_blank\">informativa sulla privacy</a>, e di voler creare il suo profilo GUCCI.`;
  const getPrivacyPolicyTextCreateMyAccountToProceed = LINK => `Optando per "Crea un account my GUCCI", Lei conferma di avere letto e compreso la nostra <a href=\"${LINK}\" target=\"_blank\">informativa sulla privacy</a>, e di voler creare il suo profilo GUCCI.`;
  const getPrivacyPolicyTextCountry = (LINK1, LINK2) => `Selezionando "Creazione profilo", conferma di accettare i nostri <a href=\"${LINK2}\" target="_blank">Termini di utilizzo</a> e la nostra <a href=\"${LINK1}\" target="_blank">informativa sulla privacy</a>, e di voler creare il suo profilo GUCCI.`;
  const getPrivacyPolicyHTML = LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
                              <br />
                              Lei sta per creare il Suo profilo GUCCI. Ciò consentirà a GUCCI di offrirLe un’esperienza personalizzata e su misura, fornirLe prodotti, servizi ed informazioni che Lei richiederà a GUCCI e comunicare con Lei. Tutte le Sue informazioni personali saranno conservate, utilizzate e collegate al Suo profilo GUCCI. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa. <br />
                              <br />
                              Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLAT = LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
      <br />
      Lei sta per creare il Suo profilo GUCCI. Ciò consentirà a GUCCI di offrirLe un’esperienza personalizzata e su misura, fornirLe prodotti, servizi ed informazioni che Lei richiederà a GUCCI e comunicare con Lei. Tutte le Sue informazioni personali saranno conservate, utilizzate e collegate al Suo profilo GUCCI. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa (anche a mezzo telefono se condivide con noi il Suo numero di telefono). <br />
      <br />
      Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
`;
  const getPrivacyPolicyHTMLKW= LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
                                  <br />
                                  Lei sta per creare il Suo profilo GUCCI. Ciò consentirà a GUCCI di offrirLe un’esperienza personalizzata e su misura, fornirLe prodotti, servizi ed informazioni che Lei richiederà a GUCCI e comunicare con Lei. Tutte le Sue informazioni personali saranno conservate, utilizzate e collegate al Suo profilo GUCCI. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa. Con la creazione del Suo profilo GUCCI, Lei conferma di avere letto e compreso e di accettare la nostra <a href="${baseUrl}/st/privacy-landing" target="_blank">informativa sulla privacy</a>.<br />
                                  <br />
                                  Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei ha il diritto di acconsentire o di non acconsentire alla raccolta ed al trattamento dei Suoi dati personali. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
                                  `;
  const getPrivacyPolicyHTMLSE = LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
                            <br />
                            Lei sta per creare il Suo profilo GUCCI. Ciò consentirà a GUCCI di offrirLe un’esperienza personalizzata e su misura, fornirLe prodotti, servizi ed informazioni che Lei richiederà a GUCCI e comunicare con Lei. Tutte le Sue informazioni personali saranno conservate, utilizzate e collegate al Suo profilo GUCCI. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Quando Lei crea un profilo GUCCI, generalmente conserviamo le Sue informazioni personali per 5 anni dall’ultima interazione che Lei abbia iniziato con noi. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa. <br />
                            <br />
                            Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
                            `;
  const getPrivacyPolicyHTMLQA = LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
                                  <br />
                                  Lei sta per creare il Suo profilo GUCCI. GUCCI utilizzerà il Suo profilo per fornirLe i prodotti, i servizi e le informazioni da Lei acquistati o richiesti da GUCCI, per offrirLe assistenza e servizio clienti personalizzati, per inviarLe comunicazioni promozionali su misura, per condurre la nostra attività in modo sicuro ed in conformità con i nostri obblighi e per ottimizzare e migliorare i nostri servizi. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa. Con la creazione del Suo profilo GUCCI, Lei conferma di avere letto e compreso e di accettare la nostra <a href="${baseUrl}/st/privacy-landing" target="_blank">informativa sulla privacy</a>.<br />
                                  <br />
                                  Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyHTMLCountry = LINK => `La invitiamo a leggere per intero la nostra <a href="${LINK}" target="_blank">informativa sulla privacy</a>. In ogni caso, riportiamo di seguito alcuni punti fondamentali che vorremmo condividere con Lei:<br />
                                  <br />
                                  Lei sta per creare il Suo profilo GUCCI. Ciò consentirà a GUCCI di offrirLe un’esperienza personalizzata e su misura, fornirLe prodotti, servizi ed informazioni che Lei richiederà a GUCCI e comunicare con Lei. Tutte le Sue informazioni personali saranno conservate, utilizzate e collegate al Suo profilo GUCCI. Il Suo profilo potrebbe includere informazioni ottenute direttamente da Lei e dai nostri rivenditori e partner commerciali. Poichè operiamo su scala globale, potremmo condividere, in totale sicurezza, le Sue informazioni personali con società del nostro gruppo e con i nostri partner approvati presenti in varie località nel mondo. Adotteremo delle misure per proteggere le Sue informazioni personali. Potremmo utilizzare ogni informazione di contatto che Lei condivide con noi al fine di contattarLa. Con la creazione del Suo profilo GUCCI, Lei conferma di avere letto e compreso e di accettare la nostra <a href="${baseUrl}/st/privacy-landing" target="_blank">informativa sulla privacy</a>.<br />
                                  <br />
                                  Le leggi in materia di privacy possono conferirLe alcuni diritti, tra i quali il diritto di accesso, cancellazione, modificazione e rettifica dei Suoi dati, di limitazione del trattamento e di opposizione allo stesso, così come il diritto alla portabilità dei dati. Lei può anche presentare reclamo presso un’autorità di controllo. Lei può revocare il Suo consenso o cancellare il Suo profilo in ogni momento. Per ulteriori informazioni circa le nostre prassi in materia di privacy ed i Suoi diritti, la preghiamo di contattarci all’indirizzo <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>.
      `;
  const getPrivacyPolicyShortHTML = LINK => `Scegliendo "continua" accetti i termini della nostra politica sulla privacy. <a href="${LINK}" target="_blank">Informativa sulla privacy</a>.`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">Informativa sulla privacy</a>`;

  return {
    "labels": {
      "CANCEL": "ANNULLA",
      "CHANGE_LANGUAGE": "CHANGE LANGUAGE",
      "CHECKOUT_CONTINUE": "VAI AL CHECK-OUT",
      "CHOOSE_PASSWORD": "Scegli la password",
      "CLOSE": "CHIUDI",
      "COMPLETE_YOUR_PROFILE": "COMPLETA IL TUO PROFILO",
      "CONFIRM": "CONFERMA",
      "CONFIRM_EMAIL_ADDRESS": "Verifica il tuo account",
      "CONTINUE": "CONTINUA",
      "PROCEED_TO_CHECKOUT":"PROCEDI AL CHECK-OUT",
      "CONTINUE_WITH_GOOGLE": "CONTINUA CON GOOGLE",
      "CONTINUE_WITH_APPLE": "Continua con Apple",
      "CONTINUE_WITH_KAKAO": "Continua con Kakao",
      "CREATE_ACCOUNT": "CREA UN ACCOUNT MY GUCCI",
      "CREATE_PASSWORD": "CREA UNA PASSWORD",
      "COUNTRY": "PAESE DI RESIDENZA",
      "DATE_OF_BIRTH": "DATA DI NASCITA",
      "DAY": "Giorno",
      "EDIT_EMAIL": "Modifica indirizzo e-mail",
      "EMAIL": "EMAIL",
      "EMAIL_VALIDATION_RESEND": "Non l’hai ricevuta? Richiedi una nuova e-mail.",
      "FINISH_REGISTRATION": "COMPLETA LA REGISTRAZIONE",
      "FIRST_NAME": "NOME",
      "FOLLOW": "Status amici del canale Kakao",
      "FOLLOW_ADDED": "Aggiunto al canale",
      "FOLLOW_BLOCKED": "Bloccato",
      "FOLLOW_NONE": "Nessun collegamento",
      "FORGOT_PASSWORD": "Hai dimenticato la password?",
      "GUEST_USER_CHECKOUT": "Salta il Login",
      "CONTINUE_AS_GUEST": "Continua come ospite",
      "LAST_NAME": "COGNOME",
      "MONTH": "Mese",
      "MARKETING_CONSENT_CHECKBOX": "Desidero ricevere aggiornamenti sulle nuove attività, prodotti esclusivi, servizi personalizzati di GUCCI ed avere un’esperienza personalizzata sulla base dei miei interessi.",
      "MARKETING_CONSENT_CHECKBOX__SA": "Desidero ricevere aggiornamenti sulle nuove attività, prodotti esclusivi, servizi personalizzati di GUCCI ed avere un’esperienza personalizzata sulla base dei miei interessi.",
      "MARKETING_CONSENT_CHECKBOX__DK": "Desidero ricevere aggiornamenti (anche a mezzo e-mail, SMS, MMS, social media, telefono e comunicazioni cartacee) sulle nuove attività, prodotti esclusivi, servizi personalizzati di GUCCI ed avere un’esperienza personalizzata sulla base dei miei interessi.",
      "MARKETING_CONSENT_CHECKBOX__UK": "Desidero ricevere aggiornamenti (anche a mezzo e-mail, SMS, MMS, social media, telefono e comunicazioni cartacee) sulle nuove attività, prodotti esclusivi, servizi personalizzati di GUCCI ed avere un’esperienza personalizzata sulla base dei miei interessi.",
      "MARKETING_CONSENT_CHECKBOX__DE": "Desidero ricevere aggiornamenti sulle nuove attività, prodotti esclusivi, servizi personalizzati di GUCCI ed avere un’esperienza personalizzata sulla base dei miei interessi, e acconsento all'utilizzo delle mie informazioni personali per questa finalità.",
      "DATA_OUTSIDE_COUNTRY_CONSENT_CHECKBOX": `Accetto che le mie informazioni personali possano essere trasferite al di fuori del mio paese di residenza, come descritto nell'<a href="${baseUrl}/st/privacy-landing" target="_blank">informativa sulla privacy</a>*`,
      "NEW_PASSWORD": "NUOVA PASSWORD",
      "PASSWORD": "PASSWORD",
      "PHONE": "TELEFONO",
      "READ_MORE": "LEGGI DI PIÙ",
      "REMEMBER_ME": "Resta connesso",
      "RESET_PASSWORD": "Cambia Password",
      "REQUIRED_FIELD": "Campo obbligatorio",
      "SHOW_PASSWORD": "Mostra password",
      "SOCIALID": "Social ID per il servizio",
      "SUBMIT": "INVIA",
      "SKIP_SOCIAL": "SALTA E CONTINUA CON IL TUO INDRIZZO EMAIL",
      "TITLE": "SELEZIONA TITOLO",
      "TITLE_A": "Mx",
      "TITLE_MR": "Sig.",
      "TITLE_MRS": "Sig.ra",
      "TITLE_MS": "Sig.ina",
      "TITLE_N": "Preferirei non specificarlo",
      "YEAR": "Anno",
      "CREATE_MY_PROFILE": "CREAZIONE PROFILO",
      "PRIVACY_3_6_2": "Gucci가 본인의 개인 정보를 수집 및 이용하는 것에 동의합니다.*",
      "PRIVACY_4_OTHER": "Gucci가 본인의 개인 정보를 제3자 제공 및 국외 이전 하는 것에 동의합니다.*",
      "PRIVACY_1_5_MARKETING": "GUCCI의 새로운 활동, 전용 상품, 맞춤형 서비스에 대한 맞춤화된 소식과 최신 정보를 받는 것과, 본인의 관심사에 기반한 맞춤화된 서비스를 제공받는 것에 동의합니다.",
      "SKIP_LOGIN": "SALTA PER ADESSO",
      "PLACEHOLDER_DD": "GG",
      "PLACEHOLDER_MM": "MM",
      "PLACEHOLDER_YYYY": "AAAA",
      "DOS_SUBMIT": "CREA UN ACCOUNT"
    },
    "messages": {
      "AUTH_ERROR": "La combinazione di email e password è errata. Prova di nuovo o clicca su \"Hai dimenticato la password?\".",
      "BIRTHDATE_ERROR": "Inserisci una data di nascita valida",
      "BAA_DESCRIPTION": "Accedi o registrati con MY GUCCI per programmare un'esperienza di shopping personalizzata con un Client Advisor dedicato.",
      "BAA_INFORMATION": "Se hai già un account MY GUCCI, ti verrà richiesto di effettuare l'accesso. Altrimenti, puoi creare un profilo per continuare con la richiesta del tuo appuntamento.",
      "CHECKOUT_INFORMATION": "Se possiedi già un account MY GUCCI, ti verrà richiesto di effettuare il login. Se non hai un account, continua come ospite e scegli di registrarti dopo il check-out.",
      "CHECKOUT_INFORMATION_CREATE_ACCOUNT": "Accedi con il tuo indirizzo e-mail e la password oppure crea un account se non sei già iscritto.",
      "CHECKOUT_REVIEW_INFORMATION": "Prima di procedere, registrati con MY GUCCI inserendo le informazioni per %email%.",
      "COMPLETE_REVIEW_PROFILE": "Completa il tuo profilo per attivare il tuo account My Gucci. Ottimizza la tua esperienza di shopping grazie a collezioni in esclusiva per i membri, contenuti esclusivi e ordini facili.",
      "EMAIL_ERROR": "Inserisci un indirizzo email valido",
      "EMAIL_VALIDATION_CONFIRM": "Ti preghiamo di utilizzare il link per confermare il tuo indirizzo e-mail e attivare così l'account My Gucci. Semplifica la tua esperienza di acquisto con collezioni riservate ai membri, contenuti esclusivi e ordini facilitati.",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "Aggiungi i tuoi dati qui sotto per creare il tuo account Gucci.",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "Ti abbiamo inviato un nuovo link per verificare il tuo account all'indirizzo %email%",
      "FIRST_NAME_ERROR": "Inserisci il tuo nome",
      "FORGOT_PASSWORD_INFORMATION": "Invieremo un link per ripristinare la password al seguente indirizzo e-mail. Confermare indirizzo e-mail.",
      "FORGOT_PASSWORD_INSTRUCTION": "Controlla la tua posta elettronica e inizia la procedura di ripristino della password utilizzando il link.",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "Abbiamo inviato un'e-mail con le istruzioni per il ripristino della password all'indirizzo",
      "GENERIC_ERROR": "Ci dispiace, qualcosa è andato storto. Prova di nuovo.",
      "SOCIAL_ACCOUNT_EXISTS": "Sei registrato con %email%",
      "LAST_NAME_ERROR": "Inserisci il tuo cognome",
      "LOGOUT": "Sei sicuro di volere uscire?",
      "OR": "OPPURE",
      "ORDER_ACCOUNT": "Ottimizza la tua esperienza di acquisto con collezioni riservate ai membri, contenuti esclusivi e ordini facili.",
      "PASSWORD_UPDATED_HTML": "La tua password è stata aggiornata con successo.<br />Accesso in corso.",
      "OVERLAY_RESET_LINK_EXPIRED": "Clicca su \"Hai dimenticato la tua password?\" per richiedere un nuovo link.",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "Per ricevere un nuovo link, fai clic su \"Richiedi una nuova e-mail\".",
      "PASSWORD_CHARACTER_CHECK": "La password deve contare almeno 8 caratteri",
      "PASSWORD_NUMBER_CHECK": "La password deve contenere almeno un numero",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "La password deve contenere almeno un carattere speciale (!+,-./:;<=>?@)",
      "PASSWORD_ERROR": "Inserisci una password.",
      "REGISTRATION_AGE_ERROR": "Devi avere almeno %age% anni",
      "REGISTRATION_INFORMATION": "Se sei già registrato, ti sarà richiesto di inserire la password.",
      "REVIEW_INFORMATION": "Ti preghiamo di rividere i dettagli per",
      "SAME_PASSWORD_ERROR": "Scegli una password diversa dalla precedente.",
      "TITLE_ERROR": "Seleziona un titolo",
      "LOADER_TITLE": "Il tuo account MY GUCCI è completo",
      "LOADER_SUBTITLE": "A questo punto, sarai indiririzzato su Gucci.com dove potrai iniziare a esplorare l'universo digitale della Maison."
    },
    "titles": {
      "ACCOUNT_EXISTS": "Abbiamo trovato il tuo account",
      "ACCESS_GUCCI": "Il tuo account MY GUCCI",
      "BAA": "Prenota un appuntamento",
      "CHANGING_PASSWORD_FOR": "Cambio password per %email%",
      "EMAIL_VALIDATION": "Controlla la tua e-mail",
      "SETTING_PASSWORD_FOR": "Crea una nuova password per accedere all’account Gucci per %email%",
      "CHECKOUT": "Come vuoi eseguire il checkout?",
      "CHECKOUT_REVIEW_PROFILE": "Profilo MY GUCCI",
      "LOGIN_TO_CHECKOUT": "Accedi o effettua il pagamento come ospite",
      "CONTINUE_WITH_EMAIL": "Continua con il tuo indirizzo email",
      "USE_YOUR_EMAIL": "Continua con il tuo indirizzo email",
      "FORGOT_PASSWORD_EMAIL_SENT": "Link inviato!",
      "PASSWORD_UPDATED": "Password Aggiornata",
      "OVERLAY_RESET_LINK_EXPIRED": "Il tuo link è scaduto",
      "PRIVACY_POLICY": "POLICY SULLA PRIVACY",
      "DOS_TITLE": "Crea un<br />account MY GUCCI ",
      "DOS_SUBTITLE": "imposta una password per "
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "Noi di Gucci abbiamo a cuore la sicurezza dei tuoi dati. Ti abbiamo inviato un'e-mail per verificare se sei davvero tu.<br/><br/>Fai clic sul link nell'e-mail per verificare il tuo account e scegliere una password.",
      "MARKETING_CONSENT_HTML": `<br />Accetto (o, in caso io non abbia ancora raggiunto l'età del consenso prevista dalla mia giurisdizione di residenza, sarà il titolare della mia potestà genitoriale a confermare) che i miei dati personali siano utilizzati da Gucci e Kering, per comunicare con me in base ai miei interessi e abitudini di acquisto tramite diversi canali di comunicazione, al fine di migliorare i servizi di Gucci e Kering, in particolare tramite profiling, e per portare avanti campagne mirate simili selezionando le mie impression pubblicitarie attraverso i siti web dove possibile, come specificato nella <a href="${baseUrl}/st/privacy-landing" target="_blank">Privacy Policy</a>.`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT_CREATE_MY_GUCCI_ACCOUNT": getPrivacyPolicyTextCreateMyAccountToProceed(`${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/leagal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AT": getPrivacyPolicyHTMLAT(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SE": getPrivacyPolicyHTMLSE(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLQA(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTMLKW(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SA": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AE": getPrivacyPolicyHTMLCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "value_propositions" : {
      "TITLE1": "UNISCITI A MY GUCCI",
      "TITLE2": "UNISCITI ALL'UNIVERSO GUCCI",
      "TITLE3": "AVVICINATI ALLA MAISON",
      "VALUE1": {
        "TITLE": "TRACCIA I TUOI ORDINI",
        "CONTENT": "Segui i tuoi ordini in ogni fase."
      },
      "VALUE2": {
        "TITLE": "SEMPLIFICA IL PAGAMENTO",
        "CONTENT": "Effettua il pagamento più velocemente salvando indirizzi e metodi di pagamento."
      },
      "VALUE3": {
        "TITLE": "FISSA UN APPUNTAMENTO",
        "CONTENT": "Approfitta dell'accesso prioritario alla boutique di tua scelta all'orario e alla data che preferisci."
      },
      "VALUE4": {
        "TITLE": "USUFRUISCI DI CONSIGLI DEDICATI",
        "CONTENT": "Un Client Advisor Gucci è sempre disponibile con suggerimenti esperti."
      },
      "VALUE5": {
        "TITLE": "ACCEDI IN ANTEPRIMA A TUTTE LE NOVITÀ",
        "CONTENT": "Scopri la newsletter Gucci e consigli personalizzati."
      },
      "VALUE8": {
        "TITLE": "UN'ESPERIENZA SU MISURA",
        "CONTENT": "In negozio o online, muoviti senza problemi nell'universo Gucci."
      },
    }
  };
}

function simplifiedChinese(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) => `选择“建立我的个人帐户”即表示您同意我们的<a href="${LINK2}" target="_blank">使用条款</a>，您已阅读并理解我们的<a href="${LINK1}" target="_blank">隐私政策</a>，并希望创建您的GUCCI个人资料。`;
  const getPrivacyPolicyTextCreateMyAccountToProceed = LINK => `当您选择”创建MY GUCCI账户”，您即确认您已阅读并理解我们的<a href="${LINK}" target="_blank">隐私政策</a>，且您希望建立您的GUCCI个人账户。`;
  const getPrivacyPolicyTextCountry = (LINK1, LINK2) => `选择“建立我的个人帐户”即表示您同意我们的<a href="${LINK2}" target="_blank">使用条款</a>和<a href="${LINK1}" target="_blank">隐私政策</a>，并希望创建您的GUCCI个人资料。`;

  const getPrivacyPolicyHTML = LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                              <br />
                              您即将在GUCCI建立个人帐户,这将允许GUCCI带给您个人化和度身定制的体验，提供您所要求的GUCCI产品、服务及信息并与您联络。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您。<br />
                              <br />
                              隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改或更正您的个人信息，或限制我们处理或传送您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyHTMLAT = LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                              <br />
                              您即将在GUCCI建立个人帐户,这将允许GUCCI带给您个人化和度身定制的体验，提供您所要求的GUCCI产品、服务及信息并与您联络。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您 (若您将电话号码提供给了我们，将包含向您致电的形式)。<br />
                              <br />
                              隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改或更正您的个人信息，或限制我们处理或传送您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyHTMLSE = LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                              <br />
                              您即将在GUCCI建立个人帐户,这将允许GUCCI带给您个人化和度身定制的体验，提供您所要求的GUCCI产品、服务及信息并与您联络。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。当您在创建您的GUCCI账号后，自您发起您最后一次与我们的互动起，我们通常将保留您的个人信息五年。 我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您。<br />
                              <br />
                              隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改或更正您的个人信息，或限制我们处理或传送您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyHTMLQA = LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                              <br />
                              您即将在GUCCI建立个人帐户。GUCCI将会运用您的个人账户，以向您提供于GUCCI所购买或要求的产品或服务，提供您客制化的客户服务和协助，寄送给您量身订制的营销信息，依据我们的职责安全地履行业务，优化和改进我们的服务。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您。当您建立您的GUCCI个人帐户时，您即确认您已阅读、理解并同意我们的<a href="${baseUrl}/st/privacy-landing" target="_blank">隐私政策</a>。<br />
                              <br />
                              隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改或更正您的个人信息，或限制我们处理或传送您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyHTMLKW= LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                              <br />
                              您即将在GUCCI建立个人帐户,这将允许GUCCI带给您个人化和度身定制的体验，提供您所要求的GUCCI产品、服务及信息并与您联络。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您。当您建立您的GUCCI个人帐户时，您即确认您已阅读、理解并同意我们的<a href="${baseUrl}/st/privacy-landing" target="_blank">隐私政策</a>。<br />
                              <br />
                              隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改或更正您的个人信息，或限制我们处理或传送您个人信息的权利。您有权同意或不同意我们收集及处理您的个人信息。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyHTMLCountry = LINK => `敬请阅读我们完整的 <a href="${LINK}" target="_blank">隐私政策</a>。以下是政策的关键信息,请您务必了解：<br />
                              <br />
                              您即将在GUCCI建立个人帐户, 这将允许GUCCI带给您个人化和度身定制的体验，提供您所要求的GUCCI产品、服务及信息并与您联络。所有您的个人信息将存储，使用及共享至您的GUCCI个人帐户。您个人帐户中的信息将包含我们、我们的零售商或其他商业伙伴向您获取的数据。由于我们是跨国企业，我们可能会将您的个人信息安全地与分布在世界各地的本集团公司以及我们认可的合作伙伴进行共享。我们会采用安全措施以保护您的个人信息。我们可能会使用任何您提供的联络信息联络您。<br />
                              <br />
                              隐私权相关法规赋予您多项权利。您可以向我们要求取得、删除、修改、更正您的个人信息，或限制我们处理或传输您个人信息的权利。您亦可向相关监管机构提出申诉，或于任何时候撤回同意或删除个人帐户。关于我们的隐私权实践及您的权利，您可以通过电子邮件与我们取得进一步的信息：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。
      `;
  const getPrivacyPolicyShortHTML = LINK => `若接受我们的条款，请选择下方的“继续” <a href="${LINK}" target="_blank">隐私政策</a>。`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">隐私政策</a>`;
  return {
    "labels": {
      "CANCEL": "取消",
      "CHANGE_LANGUAGE": "CHANGE LANGUAGE",
      "CHECKOUT_CONTINUE": "继续结帐",
      "CHOOSE_PASSWORD": "设置您的密码",
      "CLOSE": "关闭",
      "COMPLETE_YOUR_PROFILE": "填写您的个人资料",
      "CONFIRM": "确认",
      "CONFIRM_EMAIL_ADDRESS": "验证您的账户",
      "CONTINUE": "继续",
      "PROCEED_TO_CHECKOUT": "继续结算",
      "CONTINUE_WITH_GOOGLE": "使用Google账号登录",
      "CONTINUE_WITH_APPLE": "使用Apple账号登录",
      "CONTINUE_WITH_KAKAO": "使用Kakao账号登录",
      "CREATE_ACCOUNT": "创建MY GUCCI账户",
      "CREATE_PASSWORD": "创建密码",
      "COUNTRY": "所在国家/地区",
      "DATE_OF_BIRTH": "出生日期",
      "DAY": "日",
      "EDIT_EMAIL": "编辑电子邮件",
      "EMAIL": "电子邮件",
      "EMAIL_VALIDATION_RESEND": "没有收到电子邮件？请求重新发送电子邮件。",
      "FINISH_REGISTRATION": "完成注册",
      "FIRST_NAME": "名字",
      "FOLLOW": "Kakao频道好友状态",
      "FOLLOW_ADDED": "已添加",
      "FOLLOW_BLOCKED": "已屏蔽",
      "FOLLOW_NONE": "无",
      "FORGOT_PASSWORD": "忘记密码？",
      "GENDER": "性別",
      "GENDER_MALE": "男性",
      "GENDER_FEMALE": "女性",
      "GENDER_A": "中性",
      "GENDER_N": "不回答",
      "GUEST_USER_CHECKOUT": "跳过登录",
      "CONTINUE_AS_GUEST": "继续以访客身份浏览",
      "LAST_NAME": "姓氏",
      "MONTH": "月",
      "MARKETING_CONSENT_CHECKBOX": "我希望收到关于GUCCI的新活动邀请、独家产品、量身订制服务以及根据我的兴趣所个性化的客户体验的更新。",
      "MARKETING_CONSENT_CHECKBOX__DE": "我希望收到关于GUCCI的新活动邀请、独家产品、量身订制服务以及根据我的兴趣所个性化的客户体验的更新，为此我同意使用我的个人信息。",
      "MARKETING_CONSENT_CHECKBOX__DK": "我希望收到(包含透过电子邮件、短信、多媒体短信、社交媒体、电话和信件)关于GUCCI的新活动邀请、独家产品、量身订制服务以及根据我的兴趣所个性化的客户体验的更新。",
      "MARKETING_CONSENT_CHECKBOX__UK": "我希望收到(包含透过电子邮件、短信、多媒体短信、社交媒体、电话和信件)关于GUCCI的新活动邀请、独家产品、量身订制服务以及根据我的兴趣所个性化的客户体验的更新。",
      "MARKETING_CONSENT_CHECKBOX__AU": "我希望收到(包含透过电子邮件、短信、多媒体短信、社交媒体、电话和信件)关于GUCCI的新活动邀请、独家产品、量身订制服务以及根据我的兴趣所个性化的客户体验的更新。",
      "DATA_OUTSIDE_COUNTRY_CONSENT_CHECKBOX": `我同意将我的个人信息依 <a href="${baseUrl}/st/privacy-landing" target="_blank">隐私政策</a>传输至我的居住国之外。*`,
      "NEW_PASSWORD": "新密码",
      "PASSWORD": "密码",
      "PHONE": "电话",
      "READ_MORE": "阅读更多信息",
      "REMEMBER_ME": "保持登录状态",
      "REQUIRED_FIELD": "必填字段",
      "RESET_PASSWORD": "重置密码",
      "SHOW_PASSWORD": "显示密码",
      "SOCIALID": "社交网络标识",
      "SUBMIT": "提交",
      "SKIP_SOCIAL": "跳过并继续发送电子邮件",
      "TITLE": "选择称谓",
      "TITLE_A": "君",
      "TITLE_MR": "先生",
      "TITLE_MRS": "女士",
      "TITLE_MS": "小姐",
      "TITLE_N": "暂不透露",
      "YEAR": "年",
      "CREATE_MY_PROFILE": "建立我的个人帐户",
      "SKIP_LOGIN": "暂时跳过",
      "PLACEHOLDER_DD": "DD",
      "PLACEHOLDER_MM": "MM",
      "PLACEHOLDER_YYYY": "YYYY",
      "DOS_SUBMIT": "CREATE AN ACCOUNT"
    },
    "messages": {
      "AUTH_ERROR": "电子邮件与密码组合错误。请重试或单击“忘记密码？”",
      "BIRTHDATE_ERROR": "请输入有效的出生日期",
      "BAA_DESCRIPTION": "登录或注册MY GUCCI账户后，即可预约专属客户顾问，享受个性化的到店购物体验。",
      "BAA_INFORMATION": "如已有MY GUCCI账户，请登录。如未注册账户，您可以创建个人档案以继续完成预约。",
      "CHECKOUT_INFORMATION": "如已有MY GUCCI账户，请登录。如尚未拥有账户，您可以访客身份继续浏览并在完成结算后选择注册。",
      "CHECKOUT_INFORMATION_CREATE_ACCOUNT": "使用您的电子邮件和密码登录，亦或创建新顾客档案。",
      "CHECKOUT_REVIEW_INFORMATION": "请填写%email%相关信息并完成MY GUCCI注册，再继续下一步骤。",
      "COMPLETE_REVIEW_PROFILE": "请填写您的个人资料，以激活您的My Gucci账户。通过会员专属收藏、专享内容和轻松预订，享受简单便捷的购物体验。",
      "EMAIL_ERROR": "请输入有效的电子邮件地址",
      "EMAIL_VALIDATION_CONFIRM": "请点击链接以确认您的电子邮件地址，激活您的My Gucci账户。通过会员专属收藏、专享内容和轻松预订，享受简单便捷的购物体验。",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "请在下方添加您的详细信息，以创建Gucci账户。",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "我们已通过%email%向您发送一个新链接，用于验证您的账户",
      "FIRST_NAME_ERROR": "请输入名字",
      "FORGOT_PASSWORD_INFORMATION": "密码重置链接将会发送至以下电子邮件地址。请确认。",
      "FORGOT_PASSWORD_INSTRUCTION": "请查看您的电子邮件，点击链接重置密码。",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "我们已将附有密码重置说明的电子邮件发送至",
      "GENDER_ERROR": "请选择性别",
      "GENERIC_ERROR": "抱歉，出现了一些问题。请重试。",
      "SOCIAL_ACCOUNT_EXISTS": "您的登录信息为：%email%",
      "LAST_NAME_ERROR": "请输入姓氏",
      "LOGOUT": "您确定要注销吗？",
      "OR": "或者",
      "ORDER_ACCOUNT": "轻松选购会员专属系列和独家内容，简化您的购物流程。",
      "PASSWORD_UPDATED_HTML": "您的密码已成功更新。<br />正在登录。",
      "OVERLAY_RESET_LINK_EXPIRED": "请点击“忘记密码”以申请新的密码重置链接",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "请点击“重新发送电子邮件”以获取全新链接",
      "PASSWORD_CHARACTER_CHECK": "请输入至少8个字符",
      "PASSWORD_NUMBER_CHECK": "请包含至少一个数字",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "请输入一个特殊字符(!+,-./:;<=>?@)",
      "PASSWORD_ERROR": "请输入密码。",
      "REGISTRATION_AGE_ERROR": "您必须年满%age%周岁才能创建账户。",
      "REGISTRATION_INFORMATION": "如果您先前已经注册，系统将要求您输入密码。",
      "REVIEW_INFORMATION": "请审核信息",
      "SAME_PASSWORD_ERROR": "请选择与上一个密码不同的密码",
      "TITLE_ERROR": "请选择称呼",
      "LOADER_TITLE": "Your MY GUCCI Account is Complete",
      "LOADER_SUBTITLE": "You will now be taken to Gucci.com where you can start exploring the House's digital universe."
    },
    "titles": {
      "ACCOUNT_EXISTS": "已找到您的账户",
      "ACCESS_GUCCI": "MY GUCCI账户",
      "BAA": "预约门店服务",
      "CHANGING_PASSWORD_FOR": "更改密码%email%",
      "EMAIL_VALIDATION": "邮件已发送",
      "SETTING_PASSWORD_FOR": "请为Gucci账户%email%创建一个新的密码",
      "CHECKOUT": "您希望以何种方式结账？",
      "CHECKOUT_REVIEW_PROFILE": "MY GUCCI个人档案",
      "LOGIN_TO_CHECKOUT": "以访客身份登录或结算",
      "CONTINUE_WITH_EMAIL": "使用您的电子邮件地址继续",
      "USE_YOUR_EMAIL": "使用电子邮件地址登录以继续",
      "FORGOT_PASSWORD_EMAIL_SENT": "已发送链接！",
      "PASSWORD_UPDATED": "密码已更新",
      "OVERLAY_RESET_LINK_EXPIRED": "您的链接已过期",
      "PRIVACY_POLICY": "隐私政策",
      "DOS_TITLE": "Create a<br />MY GUCCI Account",
      "DOS_SUBTITLE": "set a password for"
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "Gucci非常注重保护您的数据安全。我们已向您发送了一封电子邮件，以确认是您本人操作。<br/><br/>请单击电子邮件中的链接，验证您的账户并设置密码。",
      "MARKETING_CONSENT_HTML": `<br />我或者我的监护人（如未达到居住所在辖区的合法年龄）同意Gucci和Kering使用我的个人数据，就我的个人兴趣和购物习惯以及其他目的与我进行交流，并通过<a href="${baseUrl}/st/privacy-landing" target="_blank">隐私政策</a>中所述的各种沟通渠道，包括通过电子邮件、短信和社交媒体等电子营销渠道，开展其他营销活动。`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT_CREATE_MY_GUCCI_ACCOUNT": getPrivacyPolicyTextCreateMyAccountToProceed(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_TEXT__QA": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__KW": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__SA": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_TEXT__AE": getPrivacyPolicyTextCountry(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AT": getPrivacyPolicyHTMLAT(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SE": getPrivacyPolicyHTMLSE(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__QA": getPrivacyPolicyHTMLQA(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__KW": getPrivacyPolicyHTMLKW(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__SA": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_HTML__AE": getPrivacyPolicyHTMLCountry(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "value_propositions" : {
      "TITLE1": "加入MY GUCCI",
      "TITLE2": "加入GUCCI世界",
      "TITLE3": "开启品牌近距离体验之旅",
      "VALUE1": {
        "TITLE": "跟踪订单进度",
        "CONTENT": "关注订单物流详情。"
      },
      "VALUE2": {
        "TITLE": "简化结账流程",
        "CONTENT": "保存地址和支付方式以便快速结账。"
      },
      "VALUE3": {
        "TITLE": "预约",
        "CONTENT": "在您觉得合适的时间和日期到您选择的门店优先选购商品。"
      },
      "VALUE4": {
        "TITLE": "尊享专属建议",
        "CONTENT": "Gucci客户顾问随时为您提供专业建议。"
      },
      "VALUE5": {
        "TITLE": "抢鲜探索",
        "CONTENT": "探索Gucci电子资讯和个性化推荐。"
      },
      "VALUE8": {
        "TITLE": "个性化体验",
        "CONTENT": "线上线下畅享Gucci世界。"
      },
    }
  };
}

function traditionalChinese(baseUrl) {
  const getPrivacyPolicyText = (LINK1, LINK2) => `選擇「建立我的個人帳戶」即表示閣下確認同意我們的《<a href="${LINK2}" target="_blank">使用條款</a>》，已閱讀並理解<a href="${LINK1}" target="_blank">私隱政策</a>，並且希望建立GUCCI檔案。`;
  const getPrivacyPolicyHTML = LINK => `敬請閱讀我們完整的<a href="${LINK}" target="_blank">私隱政策</a>。以下是政策關鍵資訊，請您務必瞭解： <br />
                              <br />
                              您即將在GUCCI建立個人帳戶，讓GUCCI帶給您個人化和度身訂制的體驗，提供您所要求的GUCCI產品、服務及資訊並與您聯絡。所有您的個人資料將儲存、運用於及連結至您的GUCCI個人帳戶。您的個人帳戶將包含我們及我們的零售商或其他商業夥伴向您取得的資料。由於我們是跨國企業，我們可能會將您的個人資料安全地與分布在世界各地的本集團公司以及我們認可的合作夥伴進行共享。我們會採用安全措施以保護您的個人資料。我們可能會使用任何您提供的聯絡資料聯絡您。<br />
                              <br />
                              隱私權相關法規賦予您若干權利。您可以向我們要求取得、刪除、修改、更正您的個人資料;或限制我們處理或傳送您個人資料之權利。您亦可向相關監管機構提出申訴，或於任何時候撤回同意或刪除個人帳戶。如您想取得關於我們的實踐私隱的措施及您的權利的資訊，您可以通過電子郵件與我們聯絡：<a href="mailto:privacy@gucci.com">privacy@gucci.com</a>。<br />
      `;
  const getPrivacyPolicyShortHTML = LINK => `當您點選下方的「繼續」後，即表示接受我們的<a href="${LINK}" target="_blank">私隱政策</a>。`;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">私隱政策</a>`;
  return {
    "labels": {
      "CANCEL": "取消",
      "CHANGE_LANGUAGE": "CHANGE LANGUAGE",
      "CHECKOUT_CONTINUE": "繼續結帳",
      "CHOOSE_PASSWORD": "設定密碼",
      "CLOSE": "關閉",
      "COMPLETE_YOUR_PROFILE": "填妥您的個人檔案",
      "CONFIRM": "確認",
      "CONFIRM_EMAIL_ADDRESS": "驗證您的賬戶",
      "CONTINUE": "繼續",
      "PROCEED_TO_CHECKOUT":"繼續結帳",
      "CONTINUE_WITH_GOOGLE": "以Google帳號繼續",
      "CONTINUE_WITH_APPLE": "以Apple帳號繼續",
      "CONTINUE_WITH_KAKAO": "以Kakao帳號繼續",
      "CREATE_ACCOUNT": "建立MY GUCCI帳戶",
      "CREATE_PASSWORD": "設定密碼",
      "COUNTRY": "居住國家",
      "DATE_OF_BIRTH": "出生日期",
      "DAY": "日",
      "EDIT_EMAIL": "編輯電郵",
      "EMAIL": "電郵",
      "EMAIL_VALIDATION_RESEND": "沒有收到電郵？請索取新電郵。",
      "FINISH_REGISTRATION": "完成註冊",
      "FIRST_NAME": "名字",
      "FOLLOW": "Kakao頻道好友狀態",
      "FOLLOW_ADDED": "已新增",
      "FOLLOW_BLOCKED": "已封鎖",
      "FOLLOW_NONE": "無",
      "FORGOT_PASSWORD": "忘記密碼？",
      "GUEST_USER_CHECKOUT": "跳過登入帳戶",
      "CONTINUE_AS_GUEST": "以訪客身份繼續",
      "LAST_NAME": "姓氏",
      "MONTH": "月",
      "MARKETING_CONSENT_CHECKBOX": "我希望收到關於GUCCI的最新活動、限定商品、度身訂制服務以及依據我的喜好所個人化的體驗。",
      "NEW_PASSWORD": "新密碼",
      "PASSWORD": "密碼",
      "PHONE": "電話",
      "READ_MORE": "了解更多詳情",
      "REMEMBER_ME": "保持登入",
      "REQUIRED_FIELD": "必填欄位",
      "RESET_PASSWORD": "重設密碼",
      "SHOW_PASSWORD": "顯示密碼",
      "SOCIALID": "社交網絡標識",
      "SUBMIT": "提交",
      "SKIP_SOCIAL": "略過並以電郵繼續",
      "TITLE": "選擇標題",
      "TITLE_A": "君",
      "TITLE_MR": "先生",
      "TITLE_MRS": "女士",
      "TITLE_MS": "小姐",
      "TITLE_N": "無需稱謂",
      "YEAR": "年",
      "CREATE_MY_PROFILE": "建立我的個人帳戶",
      "SKIP_LOGIN": "暫時略過",
      "PLACEHOLDER_DD": "DD",
      "PLACEHOLDER_MM": "MM",
      "PLACEHOLDER_YYYY": "YYYY",
      "DOS_SUBMIT": "CREATE AN ACCOUNT"
    },
    "messages": {
      "AUTH_ERROR": "電郵地址與密碼不相符。請重試或點擊「忘記密碼？」",
      "BIRTHDATE_ERROR": "請輸入有效的出生日期",
      "BAA_DESCRIPTION": "登錄或註冊MY GUCCI帳戶，與專門店的客戶顧問安排個人化的購物體驗。",
      "BAA_INFORMATION": "如果您已持有MY GUCCI帳戶，系統會要求您登入。如果沒有，您可以建立個人檔案以繼續預約時段。",
      "CHECKOUT_INFORMATION": "如果您已持有MY GUCCI帳戶，系統會要求您登入。如果您並無持有帳戶，您可以以訪客身份繼續並選擇在結帳後註冊。",
      "CHECKOUT_REVIEW_INFORMATION": "繼續之前，請透過完成填寫 %email% 的資料以註冊 MY GUCCI 帳戶。",
      "COMPLETE_REVIEW_PROFILE": "請填妥您的個人檔案，以激活您的My Gucci帳戶。您可獲得會員專屬系列、獨家內容及簡易訂購方法，使您的購物體驗輕鬆流暢。",
      "EMAIL_ERROR": "請輸入有效的電郵地址",
      "EMAIL_VALIDATION_CONFIRM": "請使用此連結確認電郵地址，以激活您的My Gucci帳戶。您可獲得會員專屬系列、獨家內容及簡易訂購方法，使您的購物體驗輕鬆流暢。",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "請在下面添加您的詳細資料以建立您的Gucci帳戶。",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "我們已通過電郵向您發送新連結，請使用%email%驗證您的帳戶",
      "FIRST_NAME_ERROR": "請輸入您的名字",
      "FORGOT_PASSWORD_INFORMATION": "重設密碼的連結將會發送至以下電郵地址。請確認。",
      "FORGOT_PASSWORD_INSTRUCTION": "請檢查您的電郵，並透過連結開始重設密碼程序。",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "我們已向您發送附有重設密碼指示的電郵，電郵地址為",
      "GENERIC_ERROR": "很抱歉！出現錯誤，請重新嘗試。",
      "SOCIAL_ACCOUNT_EXISTS": "您已以%email%註冊",
      "LAST_NAME_ERROR": "請輸入您的姓氏",
      "OR": "或",
      "ORDER_ACCOUNT": "輕鬆選購會員專屬系列及瀏覽獨家內容，使您的購物體驗輕鬆流暢。",
      "PASSWORD_UPDATED_HTML": "您的密碼已成功更新。<br />正在登入。",
      "OVERLAY_RESET_LINK_EXPIRED": "請點擊「忘記密碼」以獲取新的重設連結。",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "要接收新連結，請點擊「請求發送新電郵」",
      "PASSWORD_CHARACTER_CHECK": "請輸入最少8個字元",
      "PASSWORD_NUMBER_CHECK": "請輸入最少一個數字",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "請輸入最少一個特殊字符（!+,-./:;<=>?@）",
      "PASSWORD_ERROR": "請輸入密碼",
      "REGISTRATION_AGE_ERROR": "你必須年滿%age%歲才能建立帳戶。",
      "REGISTRATION_INFORMATION": "如果您已經註冊，您將會需要輸入密碼。",
      "REVIEW_INFORMATION": "請檢閱以下資料：",
      "SAME_PASSWORD_ERROR": "請設定一個有別於之前所用的密碼。",
      "TITLE_ERROR": "請選擇稱謂",
      "LOADER_TITLE": "Your MY GUCCI Account is Complete",
      "LOADER_SUBTITLE": "You will now be taken to Gucci.com where you can start exploring the House's digital universe."
    },
    "titles": {
      "LOGIN_TO_CHECKOUT": "登入或以訪客身份結帳",
      "ACCOUNT_EXISTS": "我們找到您的帳戶了",
      "ACCESS_GUCCI": "您的GUCCI帳戶",
      "BAA": "預約專門店服務",
      "CHANGING_PASSWORD_FOR": "更改密碼%email%",
      "EMAIL_VALIDATION": "您有郵件",
      "SETTING_PASSWORD_FOR": "請為%email%建立新密碼，以便登入Gucci帳戶",
      "CHECKOUT": "您想怎樣結帳？",
      "CHECKOUT_REVIEW_PROFILE": "MY GUCCI個人檔案",
      "CONTINUE_WITH_EMAIL": "以您的電郵地址繼續",
      "USE_YOUR_EMAIL": "以電郵繼續",
      "FORGOT_PASSWORD_EMAIL_SENT": "連結已發送",
      "PASSWORD_UPDATED": "密碼已更新",
      "OVERLAY_RESET_LINK_EXPIRED": "您的連結已失效",
      "PRIVACY_POLICY": "私隱政策",
      "DOS_TITLE": "Create a<br />MY GUCCI Account",
      "DOS_SUBTITLE": "set a password for"
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "Gucci非常注重保護您的數據安全。我們已給您發送了一封電郵，以確認您是本人。<br/><br/>請點擊電郵中的連結以驗證您的帳戶並設定密碼。",
      "MARKETING_CONSENT_HTML": `<br />我同意（或如我尚未年滿我所居住的司法管轄區之法定承諾年齡，則為擁有父母監護權的人士）Gucci和Kering按<a href="${baseUrl}/st/privacy-landing" target="_blank">私隱政策</a>中所述使用我的個人資料，就我的興趣、購物習慣以及其他營銷目的，透過各種溝通渠道（包括電郵、短訊和社交媒體等電子營銷渠道）與我通訊。`,
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/privacy-landing`, `${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_SHORT_HTML": getPrivacyPolicyShortHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "value_propositions" : {
      "TITLE1": "加入MY GUCCI",
      "TITLE2": "加入Gucci世界",
      "TITLE3": "與品牌更貼近",
      "VALUE1": {
        "TITLE": "追蹤你的訂單",
        "CONTENT": "追蹤訂單商品的每一個運送狀態。"
      },
      "VALUE2": {
        "TITLE": "簡化結帳流程",
        "CONTENT": "使用已儲存的地址和付款方式更快速結帳。"
      },
      "VALUE3": {
        "TITLE": "預約時段",
        "CONTENT": "在適合您的時間和日期優先進入您首選的專門店。"
      },
      "VALUE4": {
        "TITLE": "獲取專屬建議",
        "CONTENT": "我們的Gucci客戶顧問隨時為您提供專業建議。"
      },
      "VALUE5": {
        "TITLE": "率先得知最新消息",
        "CONTENT": "收取Gucci通訊，獲取個人化推薦內容。"
      },
      "VALUE8": {
        "TITLE": "個人化體驗",
        "CONTENT": "從店內到網上，暢遊Gucci世界。"
      },
    }
  };
}

function arabic(baseUrl) {
  const LINE_BREAK = "<br />";
  const PRIVACY_POLICY_MAIL_TO_LINK = "<a href=\"mailto:privacy@gucci.com\">privacy@gucci.com</a>";
  const PRIVACY_POLICY_LINK = `<a href="${baseUrl}/st/privacy-landing" target="_blank">سياسة الخصوصية</a>`;
  const TERMS_OF_USE_LINK = `<a href="${baseUrl}/st/legal-landing" target="_blank"> شروط الاستخدام</a>`;
  const PRIVACY_POLICY_DOS_LINK = `<a href="${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing" target="_blank">سياسة الخصوصية</a>`;
  const MARKETING_CONSENT_FULL_HTML_PART_1 = " أوافق أنا (أو حامل السلطة الأبوية، في حال لم أبلغ السن القانونية للموافقة بحسب الولاية القضائية حيث أقيم) على استخدام بياناتي الشخصية من قِبل غوتشي وكيرينغ، للتواصل معي بشأن اهتماماتي وعادات التسوّق التي أنبعها، ولغايات تسويقية أخرى، من خلال قنوات متنوعة للتواصل، بما فيها عبر رسائل التسويق الإلكترونية، مثل البريد الإلكتروني والرسالة النصية ووسائل التواصل الاجتماعي، كما هو مفصّل في " + PRIVACY_POLICY_LINK + ".";
  const MARKETING_CONSENT_FULL_HTML_PART_2 = " أو استخدام وظيفة إلغاء الاشتراك أو وظيفة الانسحاب المتضمنة في هذه الرسائل. وأفهم أنه قد يتم إرسال الرسائل النصية التسويقية يدوياً أو باستخدام مسجّل تلقائي وأن موافقتي على تلقي رسائل نصية مُسجّلة تلقائياً لا تُعتبر شرطاً للشراء. وأقرّ أنني قد أنسحب من خدمة تلقي الرسائل النصية التسويقية في أي وقت، وأنه قد يتم تطبيق أسعار الرسائل والبيانات." + PRIVACY_POLICY_MAIL_TO_LINK + " قد أسحب موافقتي في أي وقت من خلال إرسال رسالة خطية إلى ";
  const PRIVACY_POLICY_HTML_PART_1 = "أنت مدعو لقراءة " + PRIVACY_POLICY_LINK + " الخاصة بنا بالكامل. على أية حال، هاك بعض النقاط الرئيسية التي نود مشاركتها معك:";
  const PRIVACY_POLICY_HTML_PART_2 = "أنت بصدد إنشاء ملف GUCCI الخاص بك. وهذا ما سوف يجيز لـ GUCCI أن تقدم لك تجربة شخصية و مناسبة ، وأن تزودك بالمنتجات والخدمات والمعلومات التي تطلبها من GUCCI وتتواصل معك. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. وقد نستخدم أية معلومات إتصال تشاركها معنا للاتصال بك.";
  const PRIVACY_POLICY_HTML_PART_2__QA = "أنت بصدد إنشاء ملف GUCCI الخاص بك. سوف تقوم GUCCI بإستخدام ملفك لتزويدك بالمنتجات والخدمات والمعلومات التي تشتريها أو تطلبها من GUCCI، وستقدم لك مساعدة وخدمة عملاء مخصصة. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. وقد نستخدم أية معلومات إتصال تشاركها معنا للاتصال بك. ";
  const PRIVACY_POLICY_HTML_PART_2__KW = "أنت بصدد إنشاء ملف GUCCI الخاص بك. وهذا ما سوف يجيز لـ GUCCI أن تقدم لك تجربة شخصية و مناسبة ، وأن تزودك بالمنتجات والخدمات والمعلومات التي تطلبها من GUCCI وتتواصل معك. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. وقد نستخدم أية معلومات إتصال تشاركها معنا للاتصال بك. فعبر إنشاء ملف GUCCI الخاص بك، أنت تؤكد بأنك قد قرأت وفهمت وتوافق على " + PRIVACY_POLICY_LINK + " الخاصة بنا.";
  const PRIVACY_POLICY_HTML_PART_2__AE = "أنت بصدد إنشاء ملف GUCCI الخاص بك. وهذا ما سوف يجيز لـ GUCCI أن تقدم لك تجربة شخصية و مناسبة ، وأن تزودك بالمنتجات والخدمات والمعلومات التي تطلبها من GUCCI وتتواصل معك. سيتم الاحتفاظ بكافة معلوماتك الشخصية واستخدامها وربطها بملف GUCCI الخاص بك. وقد يتضمن ملفك الشخصي المعلومات التي حصلنا عليها منك بشكل مباشر وكذلك من تجار التجزئة لدينا وغيرهم من الشركاء التجاريين. وبما أننا نعمل على صعيد عالمي، فيجوز لنا مشاركة معلوماتك الشخصية بشكل آمن مع شركات من مجموعتنا ومع شركائنا المعتمدين الموجودين حول العالم. سوف نتخذ تدابير وقائية لحماية معلوماتك الشخصية. فعبر إنشاء ملف GUCCI الخاص بك، أنت تؤكد بأنك قد قرأت وفهمت وتوافق على سياسة الخصوصية الخاصة بنا.";
  const PRIVACY_POLICY_HTML_PART_3     = "يجوز لقوانين الخصوصية أن تمنحك حقوقًا معينة مثل الحق في الدخول إلى بياناتك وحذفها وتعديلها وتصحيحها، أو تقييد معالجتها أو الاعتراض عليها، بالإضافة إلى الحق في قابلية نقل البيانات. كما يمكنك رفع شكوى إلى الجهة المختصة لديك. ويمكنك سحب موافقتك أو حذف ملفك الشخصي في أي وقت. لمزيد من المعلومات بشأن ممارسات الخصوصية وبشأن حقوقك، يرجى الاتصال بنا على " + PRIVACY_POLICY_MAIL_TO_LINK + ".";
  const PRIVACY_POLICY_HTML_PART_3__KW = "يجوز لقوانين الخصوصية أن تمنحك حقوقًا معينة مثل الحق في الدخول إلى بياناتك وحذفها وتعديلها وتصحيحها، أو تقييد معالجتها أو الاعتراض عليها، بالإضافة إلى الحق في قابلية نقل البيانات. كما يمكنك رفع شكوى إلى الجهة المختصة لديك. لديك الحق في الموافقة أو عدم الموافقة على جمع ومعالجة بياناتك الشخصية. ويمكنك سحب موافقتك أو حذف ملفك الشخصي في أي وقت. لمزيد من المعلومات بشأن ممارسات الخصوصية وبشأن حقوقك، يرجى الاتصال بنا على" + PRIVACY_POLICY_MAIL_TO_LINK + ".";
  return {
    "labels": {
      "CANCEL": "إلغاء",
      "CHANGE_LANGUAGE": "CHANGE LANGUAGE",
      "CHECKOUT_CONTINUE": "المتابعة للدفع",
      "CHOOSE_PASSWORD": "اختيار كلمة المرور",
      "CLOSE": "إغلاق",
      "COMPLETE_YOUR_PROFILE": "إكمال ملفك الشخصي",
      "CONFIRM": "تأكيد",
      "CONFIRM_EMAIL_ADDRESS": "التحقّق من حسابك",
      "CONTINUE": "تابع",
      "PROCEED_TO_CHECKOUT":"المتابعة لإتمام الشراء",
      "CONTINUE_WITH_GOOGLE": "المتابعة مع جوجل",
      "CONTINUE_WITH_APPLE": "المتابعة مع APPLE",
      "CONTINUE_WITH_KAKAO": "المتابعة مع KAKAO",
      "CREATE_ACCOUNT": "إنشاء حساب My Gucci",
      "CREATE_PASSWORD": "إنشاء كلمة مرور",
      "COUNTRY": "بلد الإقامة",
      "DATE_OF_BIRTH": "تاريخ الولادة",
      "DAY": "اليوم",
      "EDIT_EMAIL": "تحرير البريد الإلكتروني",
      "EMAIL": "البريد الإلكتروني",
      "EMAIL_VALIDATION_RESEND": "ألم تصلك الرسالة؟ اطلب رسالة إلكترونية جديدة.",
      "FINISH_REGISTRATION": "إنهاء التسجيل",
      "FIRST_NAME": "الاسم الأول",
      "FOLLOW": "حالة أصدقاء قناة Kakao",
      "FOLLOW_ADDED": "تمت إضافته",
      "FOLLOW_BLOCKED": "تم حظره",
      "FOLLOW_NONE": "لا شيء",
      "FORGOT_PASSWORD": "هل نسيت كلمة المرور؟",
      "GUEST_USER_CHECKOUT": "تخطي تسجيل الدخول",
      "CONTINUE_AS_GUEST": "Continue as guest",
      "LAST_NAME": "اسم العائلة",
      "MONTH": "الشهر",
      "MARKETING_CONSENT_CHECKBOX": "أود أن أتلقى تحديثات حول الأنشطة الجديدة لـ GUCCI ومنتجاتها الحصرية وخدماتها المخصصة وأن أحظى بتجربة شخصية تعتمد على إهتماماتي.",
      "NEW_PASSWORD": "كلمة المرور الجديدة",
      "PASSWORD": "كلمة المرور",
      "PHONE": "الهاتف",
      "READ_MORE": "اقرأ المزيد",
      "REMEMBER_ME": "الاحتفاظ بتسجيل الاشتراك",
      "REQUIRED_FIELD": "حقل مطلوب",
      "RESET_PASSWORD": "إعادة تعيين كلمة المرور",
      "SHOW_PASSWORD": "إظهار كلمة المرور",
      "SOCIALID": "رقم التعريف الاجتماعي للخدمة",
      "SUBMIT": "إرسال",
      "SKIP_SOCIAL": "التخطي والمتابعة مع البريد الإلكتروني",
      "TITLE": "تحديد عنوان",
      "TITLE_A": "",
      "TITLE_MR": "السيد",
      "TITLE_MRS": "الآنسة",
      "TITLE_MS": "السيدة",
      "TITLE_N": "يفضّل عدم التحديد",
      "YEAR": "السنة",
      "CREATE_MY_PROFILE": "إنشاء ملفي",
      "SKIP_LOGIN": "تخطي هذه المرة",
      "PLACEHOLDER_DD": "(DD)اليوم",
      "PLACEHOLDER_MM": "(MM)الشهر",
      "PLACEHOLDER_YYYY": "(YYYY)السنة",
      "DOS_SUBMIT": "إنشاء حساب"
    },
    "messages": {
      "AUTH_ERROR": "تركيبة غير صحيحة للبريد الإلكتروني وكلمة المرور. يُرجى المحاولة مرة أخرى أو النقر على \"هل نسيت كلمة المرور؟\"",
      "BIRTHDATE_ERROR": "يرجى إدخال تاريخ ولادة صالح",
      "BAA_DESCRIPTION": "سجّل اشتراكك أو دخولك إلى حساب MY GUCCI لتحدّد موعداً من أجل تجربة تسوّق مصممة خصيصاً لك مع أحد مستشاري خدمة الزبائن المتخصصين.",
      "BAA_INFORMATION": "في حال كنت تملك مسبقاً حساب MY GUCCI، فسوف يُطلب منك تسجيل الدخول. وإلا، فيمكنك إنشاء ملف تعريف للاستمرار في حجز موعدك.",
      "CHECKOUT_INFORMATION": "في حال كنت تملك مسبقاً حساب MY GUCCI، فسوف يُطلب منك تسجيل الدخول. وإلا يمكنك المتابعة كضيف واختيار تسجيل الحساب لاحقا.",
      "CHECKOUT_REVIEW_INFORMATION": "قبل أن تكمل، يُرجى تسجيل الاشتراك بواسطة حساب MY GUCCI من خلال استكمال المعلومات من أجل %email%.",
      "COMPLETE_REVIEW_PROFILE": "يُرجى إكمال ملفك الشخصي بهدف تنشيط حسابك لدى غوتشي. سهّل تجربة التسوّق بفضل مجموعات مخصّصة للأعضاء فقط، بالإضافة إلى حصرية المحتويات والسهولة في تقديم الطلبات.",
      "EMAIL_ERROR": "يُرجى إدخال عنوان بريد إلكتروني صالح",
      "EMAIL_VALIDATION_CONFIRM": "يُرجى استخدام الرابط للتأكيد على عنوان بريدك الإلكتروني بهدف تنشيط حسابك لدى غوتشي. سهّل تجربة التسوّق بفضل مجموعات مخصّصة للأعضاء فقط، بالإضافة إلى حصرية المحتويات والسهولة في تقديم الطلبات.",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "يُرجى إضافة التفاصيل المتعلقة بك أدناه لفتح حساب غوتشي الخاص بك.",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "أرسلنا لك رابطاً جديداً بالبريد الإلكتروني للتحقق من حسابك على %email%",
      "FIRST_NAME_ERROR": "يُرجى إدخال اسمك الأول",
      "FORGOT_PASSWORD_INFORMATION": "سيتم إرسال الرابط لإعادة تعيين كلمة مرورك إلى عنوان البريد الإلكتروني التالي. يُرجى التأكيد.",
      "FORGOT_PASSWORD_INSTRUCTION": "يُرجى التحقق من بريدك الإلكتروني وبدء عملية إعادة تعيين كلمة المرور من الرابط.",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "لقد أرسلنا بريداً إلكترونياً يحتوي على تعليمات لإعادة تعيين كلمة مرورك على",
      "GENERIC_ERROR": "نحن آسفون، حدث خطأ ما. يُرجى المحاولة مرة أخرى.",
      "SOCIAL_ACCOUNT_EXISTS": "أنت مسجّل بعنوان البريد الإلكتروني %email%",
      "LAST_NAME_ERROR": "يُرجى إدخال اسم العائلة",
      "OR": "أو",
      "ORDER_ACCOUNT": "قم بتسهيل تجربة التسوّق الخاصة بك مع مجموعات مخصّصة للأعضاء فقط، بالإضافة إلى المحتويات الحصرية وعمليات وضع الطلبيات السهلة.",
      "PASSWORD_UPDATED_HTML": "تم تحديث كلمة المرور بنجاح" + LINE_BREAK + "يتم تسجيل اشتراكك.",
      "OVERLAY_RESET_LINK_EXPIRED": "يُرجى النقر على \"كلمة المرور منسية\" لطلب رابط جديد لإعادة تعيين كلمة المرور",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "لتتمكّن من تلقي رابط جديد، يُرجى النقر على \"طلب رسالة بريد إلكتروني جديدة\"",
      "PASSWORD_CHARACTER_CHECK": "يُرجى إدخال 8 حروف على الأقل",
      "PASSWORD_NUMBER_CHECK": "يُرجى إدخال رقم واحد على الأقل",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "يُرجى إدخال رمز مميّز واحد (!+,-./:;<=>?@)",
      "PASSWORD_ERROR": "يُرجى إدخال كلمة مرور.",
      "REGISTRATION_AGE_ERROR": "إذ يجب أن يكون عمرك %age% أو أكثر من أجل",
      "REGISTRATION_INFORMATION": "إذا كنت مسجّلاً بالفعل معنا، فستتم مطالبتك بكلمة المرور.",
      "REVIEW_INFORMATION": "يُرجى مراجعة المعلومات من أجل",
      "SAME_PASSWORD_ERROR": "يُرجى اختيار كلمة مرور مختلفة عن كلمة المرور السابقة",
      "TITLE_ERROR": "يُرجى اختيار مسمى",
      "LOADER_TITLE": "اكتمل حساب MY GUCCI",
      "LOADER_SUBTITLE": "سوف يتم توجيهك الآن إلى Gucci.com حيث يمكنك البدء باستكشاف عالم الدار الرقمي."
    },
    "titles": {
      "LOGIN_TO_CHECKOUT": "تسجيل الدخول أو المتابعة كضيف",
      "ACCOUNT_EXISTS": "لقد وجدنا حسابك",
      "ACCESS_GUCCI": "حسابك لدى غوتشي",
      "BAA": "حجز موعد",
      "CHANGING_PASSWORD_FOR": "تغيير كلمة المرور إلى %email%",
      "EMAIL_VALIDATION": "لقد تلقيت رسالة إلكترونية",
      "SETTING_PASSWORD_FOR": "اختر كلمة مرور جديدة لإدخال حساب غوتشي الخاص بعنوان البريد الإلكتروني %email%",
      "CHECKOUT": "كيف تودّ أن تسجّل الخروج؟",
      "CHECKOUT_REVIEW_PROFILE": "ملف MY GUCCI",
      "CONTINUE_WITH_EMAIL": "المتابعة مع عنوان البريد الإلكتروني",
      "USE_YOUR_EMAIL": "المتابعة عبر البريد الإلكتروني",
      "FORGOT_PASSWORD_EMAIL_SENT": "تمّ إرسال الرابط!",
      "PASSWORD_UPDATED": "تحديث كلمة المرور",
      "OVERLAY_RESET_LINK_EXPIRED": "انتهت مدة صلاحية الرابط",
      "PRIVACY_POLICY": "سياسة الخصوصية",
      "DOS_TITLE": "إنشاء حساب MY GUCCI",
      "DOS_SUBTITLE": "تحديد كلمة مرور من أجل "
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "نحن في غوتشي نهتم بأمن بياناتك. ولقد أرسلنا لك بريداً إلكترونياً للتحقق من هويتك." + LINE_BREAK + LINE_BREAK + " يُرجى النقر على الرابط في رسالة البريد الإلكتروني للتأكيد على حسابك واختيار كلمة مرور.",
      "MARKETING_CONSENT_FULL_HTML": LINE_BREAK + MARKETING_CONSENT_FULL_HTML_PART_1 + LINE_BREAK + LINE_BREAK + MARKETING_CONSENT_FULL_HTML_PART_2,
      "MARKETING_CONSENT_HTML": LINE_BREAK + MARKETING_CONSENT_FULL_HTML_PART_1,
      "PRIVACY_POLICY_TEXT": `باختيار "إنشاء ملفي"، فإنك تؤكد أنك قرأت وفهمت ${PRIVACY_POLICY_LINK} الخاصة بنا، وأنك تريد إنشاء ملف الخاص بك  GUCCI`,
      "PRIVACY_POLICY_TEXT": `باختيار "إنشاء ملفي"، فإنك تؤكد على موافقتك على ${TERMS_OF_USE_LINK} الخاصة بنا، وأنك قد قرأت وفهمت ${PRIVACY_POLICY_LINK} الخاصة بنا، وأنك تريد إنشاء ملف التعريف الخاص بك GUCCI.`,
      "PRIVACY_POLICY_TEXT__QA": `باختيار «إنشاء ملفي»، فإنك تؤكد على موافقتك على ${TERMS_OF_USE_LINK} الخاصة بنا، وعلى ${PRIVACY_POLICY_LINK} الخاصة بنا، وأنك تريد إنشاء ملف التعريف الخاص بك GUCCI.`,
      "PRIVACY_POLICY_TEXT__KW": `باختيار «إنشاء ملفي»، فإنك تؤكد على موافقتك على ${TERMS_OF_USE_LINK} الخاصة بنا، وعلى ${PRIVACY_POLICY_LINK} الخاصة بنا، وأنك تريد إنشاء ملف التعريف الخاص بك GUCCI.`,
      "PRIVACY_POLICY_TEXT__AE": `باختيار «إنشاء ملفي»، فإنك تؤكد على موافقتك على ${TERMS_OF_USE_LINK} الخاصة بنا، وعلى ${PRIVACY_POLICY_LINK} الخاصة بنا، وأنك تريد إنشاء ملف التعريف الخاص بك GUCCI.`,
      "PRIVACY_POLICY_HTML": PRIVACY_POLICY_HTML_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3,
      "PRIVACY_POLICY_HTML__QA": PRIVACY_POLICY_HTML_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2__QA + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3,
      "PRIVACY_POLICY_HTML__KW": PRIVACY_POLICY_HTML_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2__KW + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3__KW,
      "PRIVACY_POLICY_HTML__AE": PRIVACY_POLICY_HTML_PART_1 + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_2__AE + LINE_BREAK + LINE_BREAK + PRIVACY_POLICY_HTML_PART_3,
      "PRIVACY_POLICY_SHORT_HTML": PRIVACY_POLICY_LINK + "باختيارك \"متابعة\" أدناه فإنك توافق على ",
      "PRIVACY_POLICY_ONLY_LINK": PRIVACY_POLICY_DOS_LINK
    },
    "carousel": {
      "VALUE_PROPOSITION_TITLE": "اقترب أكثر من الدار مع حساب MY GUCCI",
      "VALUE_PROPOSITION_1": "أولوية الولوج لتحديد المجموعات.",
      "VALUE_PROPOSITION_2": "نصيحة حسب الطلب من أحد مستشاري خدمة الزبائن الملمّ باهتماماتك.",
      "VALUE_PROPOSITION_3": "تشكيلة منتقاة بعناية من التوصيات حصرياً لك."
    },
    "value_propositions" : {
      "TITLE1": "انضم إلى حساب MY GUCCI",
      "TITLE2": "الانضمام إلى عالم غوتشي",
      "TITLE3": "اقترب أكثر من الدار",
      "VALUE1": {
        "TITLE": "تتبّع الطلبات",
        "CONTENT": "تتبّع طلباتك في كل المراحل."
      },
      "VALUE2": {
        "TITLE": "تسجيل خروج انسيابي",
        "CONTENT": "يمكنك تسجيل الخروج بسرعة بفضل العناوين المحفوظة وطرق الدفع."
      },
      "VALUE3": {
        "TITLE": "احجز موعداً",
        "CONTENT": "بأولوية الوصول إلى المتجر الذي تختاره في الوقت والتاريخ المناسبَين لك. "
      },
      "VALUE4": {
        "TITLE": "الاستمتاع بنصائح مكرّسة خصيصاً لك",
        "CONTENT": "أحد مستشاري خدمة الزبائن مستعد دائماً لتقديم اقتراحات بقدر كبير من الخبرة."
      },
      "VALUE5": {
        "TITLE": "كُن من أوائل الذين يتلقون المستجدات",
        "CONTENT": "استمتع بنشرة غوتشي والتوصيات الموضوعة خصيصاً لتلبية احتياجاتك."
      },
      "VALUE8": {
        "TITLE": "تجربة مخصصة لتلبية احتياجاتك",
        "CONTENT": "من البيع في المتجر إلى البيع عبر الإنترنت، تحرّك بانسيابية في كل أرجاء عالم غوتشي."
      },
    }
  };
}

function korean(baseUrl) {
  const getPrivacyPolicyText = LINK1 => `«프로필 만들기»를 선택함으로써, 귀하는 당사의 <a href="${LINK1}" target="_blank">이용약관</a>에 동의하고, 당사의 개인정보 보호정책을 확인했으며, 자신의 GUCCI 프로필을 만들고자 함을 확인합니다. 상품과 서비스 제공에 필요한 개인 정보의 수집, 사용 및 제3자 제공에 동의하지 않는 경우, GUCCI의 상품과 서비스를 제공 받지 못할 수 있습니다.`;
  const getPrivacyPolicyHTML = LINK => `해당 문서는 본사의 <a href="${LINK}" target="_blank">개인 정보 보호 정책</a> 전문입니다. 본사가 귀하에게 안내하고자 하는 요점은 다음과 같습니다:<br />
                              <br />
                              귀하는 현재 GUCCI 프로필을 생성하고자 하고 있습니다. 이는 GUCCI으로 하여금 귀하에게 개인화되고 맞춤화된 서비스를 제공하고, 귀하가 GUCCI에 요청하는 상품, 서비스 및 정보를 제공하고 귀하와 소통하는 것을 도울 것입니다.<br />
                              <br />
                              귀하가 신청서에 기입한 모든 개인 정보와 귀하가 판매원 또는 다른 방식(예를 들어 소셜 미디어, 쿠키 등)을 통해 자발적으로 공유한 다른 개인 정보(이하 “개인 정보”)는 보관 및 사용되고, 귀하의 GUCCI 프로필에 연결될 것입니다. 귀하의 프로필은 귀하로부터 직접 받은 정보와 함께 본사의 소매점 및 타 상업적 파트너로부터 받은 정보를 포함할 수 있습니다. 현지 법률에 따라, 본사는 귀하의 개인 정보를 <b>귀하가 GUCCI을 이용하여 소통한 마지막 일시로부터 5년간</b> 보유할 것입니다. 마지막 소통은 고객에 의해 시작된 마지막 소통으로 정의되며 본사의 시스템이나 판매원에 의해 추적할 수 있습니다. 그 예로 귀하에게 전화, 판매 이메일, 또는 대면이 마지막으로 제의되고, 그에 대해 귀하가 이메일에 포함된 본사 웹사이트를 클릭하거나 전용 계정에 대한 제의에 따라 본사 매장에 가는 등 호의적으로 응답했을 때 등이 있습니다. GUCCI으로부터 온 이메일을 열어보는 것은 마지막 소통에 해당되지 않습니다. 이메일에 포함된 링크를 클릭하는 것과 본사의 매장에 방문하는 것이 마지막 소통에 해당됩니다.<br />
                              <br />
                              본사가 국제적으로 운영되기 때문에, <b>본사는 귀하의 개인 정보를 GUCCI과 동일한 목적으로, 위에 명기된 기간 동안 우리 그룹의 기업들과 세계 곳곳에 위치한 승인된 파트너들과 안전하게 공유할 수 있습니다(기업들과 승인된 파트너들의 리스트는 다음 링크를 참고하시기 바랍니다: https://www.gucci.com/kr/ko/st/privacy-landing). 본사는 귀하의 개인 정보를 보호하기 위해 보안 장치를 시행할 것입니다</b>.<br />
                              <br />
                              본사는 귀하가 본사와 공유한 연락처를 사용할 수 있습니다. <b>이는 귀하의 관심사와 쇼핑 습관에 대해 문자 메시지, 멀티미디어 메시지, 이메일, 우편, 인터넷, 소셜 미디어 및 휴대폰 등의 다양한 경로를 통해 귀하와 소통하기 위함입니다. 또, 이는 주로 자료 수집을 통해 본사의 서비스를 향상시키고, 위에 명기된 기간 동안 본사 웹사이트를 통해 귀하에게 노출된 광고를 선별하여 그와 유사한 타겟 마케팅을 하기 위함입니다</b>.<br />
                              <br />
                              <br />
                              개인 정보 보호법은 귀하에게 개인 정보 이동권과 더불어 귀하의 데이터에 대한 접근, 삭제, 수정 및 정정, 처리에 대한 제한 및 반대의 권리와 같은 특정 권리를 부여할 수 있습니다. 귀하는 또한 유능한 규제 기관과 함께 항의를 제기할 수 있습니다. 귀하는 언제든지 동의를 철회하거나 프로필을 삭제할 수 있습니다. 본사의 개인 정보 관리와 귀하의 권리에 대한 더 많은 정보를 원하시면, <a href="mailto:privacy@gucci.com">privacy@gucci.com</a>으로 연락 주시기 바랍니다.
      `;
  const getPrivacyPolicyOnlyLink = LINK => `<a href="${LINK}" target="_blank">개인 정보 보호 정책</a>`;
  return {
    "labels": {
      "CANCEL": "CANCEL",
      "CHANGE_LANGUAGE": "CHANGE LANGUAGE",
      "CHECKOUT_CONTINUE": "결제 진행",
      "CHOOSE_PASSWORD": "비밀번호를 설정하세요",
      "CLOSE": "닫기",
      "COMPLETE_YOUR_PROFILE": "프로필 작성",
      "CONFIRM": "확인",
      "CONFIRM_EMAIL_ADDRESS": "계정 확인하기",
      "CONTINUE": "다음",
      "PROCEED_TO_CHECKOUT":"다음",
      "CONTINUE_WITH_GOOGLE": "구글 계정으로 로그인",
      "CONTINUE_WITH_APPLE": "애플 계정으로 로그인",
      "CONTINUE_WITH_KAKAO": "카카오 계정으로 로그인",
      "CREATE_ACCOUNT": "MY GUCCI 신규 회원 가입",
      "CREATE_PASSWORD": "비밀번호 만들기",
      "COUNTRY": "거주 국가",
      "DATE_OF_BIRTH": "생년월일",
      "DAY": "일",
      "EDIT_EMAIL": "이메일 수정하기",
      "EMAIL": "이메일",
      "EMAIL_VALIDATION_RESEND": "이메일을 받지 못하셨나요? 다시 한번 요청해주세요.",
      "FINISH_REGISTRATION": "등록 완료",
      "FIRST_NAME": "이름",
      "FOLLOW": "카카오톡 채널 추가 상태",
      "FOLLOW_ADDED": "채널 추가",
      "FOLLOW_BLOCKED": "채널 차단",
      "FOLLOW_NONE": "해당 없음",
      "FORGOT_PASSWORD": "비밀번호 찾기",
      "GENDER": "성별",
      "GENDER_MALE": "남성",
      "GENDER_FEMALE": "여성",
      "GENDER_A": "논바이너리",
      "GENDER_N": "선택하지 않음",
      "GUEST_USER_CHECKOUT": "로그인 생략",
      "CONTINUE_AS_GUEST": "Continue as guest",
      "LAST_NAME": "성",
      "MONTH": "월",
      "MARKETING_CONSENT_CHECKBOX": "신상품 출시, 단독 판매 제품, 관심에 따른 맞춤 경험 및 서비스에 대한 안내를 받겠습니다.",
      "NEW_PASSWORD": "새로운 비밀번호",
      "PASSWORD": "비밀번호",
      "PHONE": "연락처",
      "PRIVACY_3_6_2": "Gucci가 본인의 개인 정보를 수집 및 이용하는 것에 동의합니다.*",
      "PRIVACY_4_OTHER": "Gucci가 본인의 개인 정보를 제3자 제공 및 국외 이전 하는 것에 동의합니다.*",
      "PRIVACY_1_5_MARKETING": "GUCCI의 새로운 활동, 전용 상품, 맞춤형 서비스에 대한 맞춤화된 소식과 최신 정보를 받는 것과, 본인의 관심사에 기반한 맞춤화된 서비스를 제공받는 것에 동의합니다.",
      "READ_MORE": "자세히 보기",
      "REMEMBER_ME": "로그인 상태 유지",
      "REQUIRED_FIELD": "필수 입력",
      "RESET_PASSWORD": "비밀번호 재설정",
      "SHOW_PASSWORD": "비밀번호 보이기",
      "SOCIALID": "카카오톡 회원번호",
      "SUBMIT": "제출",
      "SKIP_SOCIAL": "건너뛰고 이메일로 계속",
      "TITLE": "타이틀 선택",
      "TITLE_A": "X",
      "TITLE_MR": "남성",
      "TITLE_MRS": "여성",
      "TITLE_N": "선택하지 않음",
      "YEAR": "년",
      "CREATE_MY_PROFILE": "프로필 만들기",
      "SKIP_LOGIN": "Skip For Now",
      "PLACEHOLDER_DD": "일(DD)",
      "PLACEHOLDER_MM": "월(MM)",
      "PLACEHOLDER_YYYY": "연도(YYYY)",
      "DOS_SUBMIT": "CREATE AN ACCOUNT"
    },
    "messages": {
      "AUTH_ERROR": "이메일과 비밀번호가 맞지 않습니다. 다시 시도해 주시거나 '비밀번호 찾기'를 클릭해 주세요.",
      "BIRTHDATE_ERROR": "유효한 생년월일을 입력해 주세요.",
      "BAA_DESCRIPTION": "MY GUCCI에 등록 또는 가입하셔서 전담 클라이언트 어드바이저와의 맞춤화된 쇼핑 경험을 예약하세요.",
      "BAA_INFORMATION": "MY GUCCI 계정이 있으신 경우 로그인하라는 메시지가 나타납니다. 계정이 없으신 경우 프로필을 생성하여 예약을 계속 진행하세요.",
      "CHECKOUT_INFORMATION": "이메일 주소와 비밀번호로 로그인 또는 회원 가입하시거나 주문 내역 확인서 수신처를 남겨주시기 바랍니다.",
      "CHECKOUT_REVIEW_INFORMATION": "계속 진행하기 전에 %email%에 대한 정보를 추가해 MY GUCCI에 회원 가입하세요.",
      "COMPLETE_REVIEW_PROFILE": "프로필을 작성해 My Gucci 계정을 활성화하세요. 구찌 회원 전용 컬렉션, 익스클루시브 컨텐츠 및 편리한 주문으로 특별한 쇼핑 경험을 누려보세요.",
      "EMAIL_ERROR": "유효한 이메일 주소를 입력해 주세요.",
      "EMAIL_VALIDATION_CONFIRM": "아래 링크에서 이메일 주소를 확인하고 My Gucci 계정을 활성화하세요. 구찌 회원 전용 컬렉션, 익스클루시브 컨텐츠 및 편리한 주문으로 특별한 쇼핑 경험을 누려보세요.",
      "EMAIL_VALIDATION_REGISTRATION_INFORMATION": "구찌 공식 온라인 스토어에 가입하려면 아래 사항을 기입해주세요.",
      "EMAIL_VALIDATION_RESEND_SUCCESS": "고객님의 계정을 확인하기 위한 새로운 링크를 %email% 주소로 전송하였습니다.",
      "FIRST_NAME_ERROR": "이름을 입력해 주세요.",
      "FORGOT_PASSWORD_INFORMATION": "회원님의 비밀번호 재설정에 필요한 링크가 다음 이메일로 전송될 예정입니다. 이메일을 확인해주세요.",
      "FORGOT_PASSWORD_INSTRUCTION": "이메일을 확인하시고 링크를 통해 비밀번호 재설정을 진행해주세요.",
      "FORGOT_PASSWORD_SUCCESS_INFORMATION": "다음 주소로 비밀번호 재설정에 필요한 안내를 다음 이메일로 전송했습니다:",
      "GENDER_ERROR": "성별을 선택하세요",
      "GENERIC_ERROR": "죄송합니다. 문제가 발생하였으니 다시 시도해 주세요.",
      "SOCIAL_ACCOUNT_EXISTS": "고객님 계정은 %email%입니다",
      "LAST_NAME_ERROR": "성을 입력해 주세요.",
      "LOGOUT": "정말로 로그아웃 하시겠습니까?",
      "REGISTRATION_AGE_ERROR": "만%age%세 이상 회원가입 가능",
      "OR": "또는",
      "ORDER_ACCOUNT": "My Gucci 계정으로 구찌 회원 전용 컬렉션, 익스클루시브 컨텐츠를 만나볼 수 있으며, 배송 현황 조회 및 배송지 확인 등 더욱 편리하게 주문하실 수 있습니다.",
      "PASSWORD_UPDATED_HTML": "비밀번호가 업데이트되었습니다.<br />사용자로 로그인 중입니다.",
      "OVERLAY_RESET_LINK_EXPIRED": "“비밀번호 찾기”를 클릭하여 재설정 링크를 새로 요청해 주세요.",
      "OVERLAY_ACTIVATION_LINK_EXPIRED": "새로운 링크를 받으시려면 이메일 재발송을 요청해주세요.",
      "PASSWORD_CHARACTER_CHECK": "8개 이상의 문자를 입력해 주세요.",
      "PASSWORD_NUMBER_CHECK": "1개 이상의 숫자를 입력해 주세요.",
      "PASSWORD_SPECIAL_CHARACTER_CHECK": "1개 이상의 특수 기호(!+,-./:;<=>?@)를 입력해 주세요.",
      "PASSWORD_ERROR": "비밀번호를 입력해 주세요.",
      "REGISTRATION_INFORMATION": "이미 가입된 이메일 계정인 경우, 다음 단계에서 비밀번호를 입력하게 됩니다.",
      "REVIEW_INFORMATION": "다음 정보를 검토해 주십시오.",
      "SAME_PASSWORD_ERROR": "이전 비밀번호와 다른 비밀번호를 선택해 주세요.",
      "TITLE_ERROR": "타이틀을 선택해 주세요.",
      "LOADER_TITLE": "Your MY GUCCI Account is Complete",
      "LOADER_SUBTITLE": "You will now be taken to Gucci.com where you can start exploring the House's digital universe."
    },
    "titles": {
      "ACCOUNT_EXISTS": "고객님의 계정을 찾았습니다",
      "ACCESS_GUCCI": "나의 구찌 계정",
      "BAA": "매장 방문 예약",
      "CHANGING_PASSWORD_FOR": "비밀번호 변경 %email%",
      "EMAIL_VALIDATION": "이메일을 전송했습니다.",
      "CHECKOUT": "어떤 결제 방식으로 진행하실 예정인가요?",
      "CHECKOUT_REVIEW_PROFILE": "MY GUCCI 프로필",
      "CONTINUE_WITH_EMAIL": "이메일 주소로 로그인",
      "USE_YOUR_EMAIL": "이메일 주소 사용하기",
      "FORGOT_PASSWORD_EMAIL_SENT": "링크를 전송했습니다!",
      "PASSWORD_UPDATED": "업데이트된 비밀번호",
      "OVERLAY_RESET_LINK_EXPIRED": "링크가 만료되었습니다.",
      "PRIVACY_OPTIONS": "필수 동의",
      "PRIVACY_POLICY": "개인정보 처리방침",
      "SETTING_PASSWORD_FOR": "새로운 비밀번호를 설정 후 %email% 계정으로 로그인 하세요.",
      "DOS_TITLE": "Create a<br />MY GUCCI Account",
      "DOS_SUBTITLE": "set a password for"
    },
    "texts": {
      "EMAIL_VALIDATION_HTML": "구찌는 고객님의 개인 정보를 소중하게 생각합니다. 고객님의 본인 확인을 위한 이메일을 보내 드렸습니다.<br/><br/>이메일에 있는 링크를 클릭하여 계정을 확인하고 비밀번호를 새롭게 설정해주세요.",
      "PRIVACY_POLICY_TEXT": getPrivacyPolicyText(`${baseUrl}/st/legal-landing`),
      "PRIVACY_POLICY_HTML": getPrivacyPolicyHTML(`${baseUrl}/st/privacy-landing`),
      "PRIVACY_POLICY_ONLY_LINK": getPrivacyPolicyOnlyLink(`${getGcomUrl()}${urlCountryLanguage}/st/privacy-landing`)
    },
    "value_propositions" : {
      "TITLE1": "MY GUCCI 가입하기",
      "TITLE2": "구찌 유니버스 가입하기",
      "TITLE3": "구찌에 대해 자세히 알아보기",
      "VALUE1": {
        "TITLE": "주문 현황 확인하기",
        "CONTENT": "주문 현황을 자세히 확인해 보세요."
      },
      "VALUE2": {
        "TITLE": "간편한 결제",
        "CONTENT": "저장된 주소와 결제 수단으로 빠르게 결제하세요."
      },
      "VALUE3": {
        "TITLE": "방문 예약하기",
        "CONTENT": "편리한 시간과 날짜에 원하는 부티크를 예약해 대기 없이 입장하세요."
      },
      "VALUE4": {
        "TITLE": "1:1 상담 받아보기",
        "CONTENT": "고객님만을 위한 특별한 서비스를 위해 구찌 클라이언트 어드바이저가 항상 기다리고 있습니다."
      },
      "VALUE5": {
        "TITLE": "가장 먼저 새로운 소식 만나보기",
        "CONTENT": "구찌 뉴스레터와 맞춤형 추천 서비스를 받아보세요."
      },
      "VALUE8": {
        "TITLE": "나만을 위한 특별한 경험",
        "CONTENT": "오프라인과 온라인을 자유롭게 넘나드는 까지 구찌 유니버스를 경험해 보세요."
      },
    }
  };
}
